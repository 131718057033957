import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import { Input, Button, Modal, Form, Tree, Alert, Popconfirm, Spin } from 'antd'
import { CarryOutOutlined, FormOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

export class CategoriesEditor extends Component {
    displayName = "Управление категориями";
    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);

        this.state = {
            isAuthenticated: false,
            token: "",
            treeData: null,
            loading: true,
            selectedCategory: null
        };

    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated
        });

        if (isAuthenticated) {

            var token = await authService.getAccessToken();
            this.setState({
                token: token
            }, function () {

                this.getCategories();
            });
        }
        else {
            this.props.history.push("/authentication/login");
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    onSelect = (selectedKeys, info) => {
        //console.log('selected', selectedKeys, info);

        if (selectedKeys.length > 0) {
            this.setState({ selectedCategory: selectedKeys[0] });
        }
        else {
            this.setState({ selectedCategory: null });
        }
    };

    async getCategories() {

        const token = await authService.getAccessToken();

        fetch('orders/get_categories',
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).
            then(response => response.text())
            .then(data => {
                try {
                    var categories = JSON.parse(data);

                    this.setState({ treeData: categories, loading: false });
                }
                catch (exception) {
                    this.setState({ treeData: null, loading: false });
                }
            });

    }

    async addCategory(parentCategory) {

        var that = this;
        const token = await authService.getAccessToken();

        var name = prompt("Ведите название");

        if (name != null && name.length > 0) {
            this.setState({ loading: true });

            var pid = 0;

            if (parentCategory != undefined && parentCategory != null) {
                pid = parentCategory;
            }

            var category_data = { parent_id: pid, name: name, error: "" };

            await fetch('orders/add_category',
                {
                    method: "POST",
                    body: JSON.stringify(category_data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {
                    var category = JSON.parse(data);
                    if (category != null) {
                        if (category.error.length > 0) {
                            that.setState({ loading: false }, function () {
                                alert(category.error);
                            });
                        }
                        else {
                            this.getCategories();
                        }
                    }
                    else {
                        that.setState({ loading: false }, function () {
                            alert("Произошла неизвестная ошибка");
                        });
                    }
                });
        }
    }

    async nameCategory(selectedCategory) {

        var that = this;
        const token = await authService.getAccessToken();

        if (selectedCategory != undefined && selectedCategory != null) {

            var name = prompt("Ведите название");

            if (name != null && name.length > 0) {
                this.setState({ loading: true });

                var pid = 0;

                var category_data = { category_id: selectedCategory, name: name, error: "" };

                await fetch('orders/set_category_name',
                    {
                        method: "POST",
                        body: JSON.stringify(category_data),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {
                        var category = JSON.parse(data);
                        if (category != null) {
                            if (category.error.length > 0) {
                                that.setState({ loading: false }, function () {
                                    alert(category.error);
                                });
                            }
                            else {
                                this.getCategories();
                            }
                        }
                        else {
                            that.setState({ loading: false }, function () {
                                alert("Произошла неизвестная ошибка");
                            });
                        }
                    });
            }
        }
    }

    async deleteCategory(selectedCategory) {

        if (selectedCategory != undefined && selectedCategory != null) {

            if (window.confirm("Удалить категорию?")) {
                var that = this;
                const token = await authService.getAccessToken();

                this.setState({ loading: true });

                var category_data = { category_id: selectedCategory, error: "" };

                await fetch('orders/delete_category',
                    {
                        method: "POST",
                        body: JSON.stringify(category_data),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {
                        var category = JSON.parse(data);
                        if (category != null) {
                            if (category.error.length > 0) {
                                that.setState({ loading: false }, function () {
                                    alert(category.error);
                                });
                            }
                            else {
                                that.setState({ selectedCategory: null }, function () {
                                    that.getCategories();
                                });
                            }
                        }
                        else {
                            that.setState({ loading: false }, function () {
                                alert("Произошла неизвестная ошибка");
                            });
                        }
                    });
            }
        }
    }

    render() {

        const { treeData, selectedCategory } = this.state;

        return (
            <div>
                <h2>{this.displayName}</h2>
                <div>
                    <Spin spinning={this.state.loading}>
                        <div
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            <Tree
                                showLine={true}
                                showIcon={true}
                                //defaultExpandedKeys={['0-0-0']}
                                onSelect={this.onSelect}
                                treeData={treeData}
                            />
                            <br />
                            <Button onClick={() => { this.addCategory(); }} type="primary">+Базовая</Button>
                            <Button disabled={selectedCategory == null} onClick={() => { this.addCategory(selectedCategory); }} style={{ marginLeft: "5px" }} type="primary">+Дочерняя</Button>
                            <Button disabled={selectedCategory == null} onClick={() => { this.nameCategory(selectedCategory); }} style={{ marginLeft: "5px" }} type="default">Переименовать</Button>
                            <Button disabled={selectedCategory == null} onClick={() => { this.deleteCategory(selectedCategory); }} style={{ marginLeft: "5px" }} type="danger">Удалить</Button>
                        </div>
                    </Spin>
                </div>
            </div>
        );
    }
}

export default CategoriesEditor;