import React, { Component, PropTypes, useState } from 'react';
import { dataContext, contextData } from './DataContext';
import authService from './api-authorization/AuthorizeService'
import { Table, Input, Button, Modal, Form, Select, Alert, Popconfirm, Badge, Checkbox, Radio, Collapse, AutoComplete, Row, Col } from 'antd'
import reqwest from 'reqwest'
import Bootbox from 'bootbox-react';
import { DeleteOutlined, RightCircleFilled } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { FileAddOutlined } from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Redirect } from 'react-router-dom';
import { InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
//import { FetchData } from './FetchData';
const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const getClientsParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        search: params.search,
        ...params,
    };
};

const deliveryTypes = ["ПВЗ-ПВЗ", "ПВЗ-Курьер", "Курьер-ПВЗ", "Курьер-Курьер", "Межгород"];

export class ManageClients extends Component {
    static displayName = "Управление клиентами";

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            clients: [],
            orders: [],
            accept_points: [],
            giveout_points: [],
            interstorage_rates: [],
            intercity_rates: [],
            cities: [],
            all_cities:[],
            loading: false,
            showNick: false,
            settingsShow: false,
            clientOrdersShow: false,
            addClientShow: false,
            addClientHeader: "Новый клиент",
            addOrderShow: false,
            addOrderheader: "Новый заказ",
            addOrderNotesShow: false,
            addOrderNotes: "",
            courierDeliveryCityWarning: false,
            courierOrderDeliveryCityWarning: false,
            itemsDescriptionDisabled: false,
            courierOrderDeliveryCityWarningText: "",
            weightLimitReached: false,
            pagination: {
                current: 1,
                pageSize: 10,
                position: ['topRight', 'bottomRight']
            },
            record: null,
            showConfirmDeleteClient: false,
            selectedClientCityObject: null,
            selectedAddOrderClientCityObject: null,
            selectedAddOrderClientCity: "",
            selectedAddOrderClientCityRegion: "",
            selectedAddOrderClientCityIndex: "",
            addOrderPricesBlockShow: true,
            selectedClientID: 0,
            selectedClientPhone: "",
            selectedClientName: "",
            selectedClientNameHdr: "",
            selectedClientNick: "",
            selectedClientAddress: "",
            selectedClientNotes:"",
            selectedClientCity: "",
            selectedClientCityID: 0,
            profileClientCity: "",
            profileClientCityID: 0,
            selectedShopCityID: 0,
            selectedReturnStorageID: 0,
            selectedShopCity: "",
            search: "",
            selectedAcceptPoint: 0,
            selectedAcceptPointCity: "",
            selectedAcceptPointCityID: 0,
            selectedGiveoutPoint: -1,
            addOrderClientAddressShow: false,
            selectedDeliveryType: 0,
            selectedDeliveryTypeName: "ПВЗ-ПВЗ",
            selectedPayer: "DELIVERY_OPT_PAY_SENDER",
            addOrderAcceptPointShow: true,
            addOrderGiveoutPointShow: true,
            deliveryAmount: 0,
            orgDeliveryAmount: 0,
            itemsAmount: 0,
            totalAmount: 0,
            recalcButtonDisabled: true,
            itemsDescription: "",
            saveButtonDisabled: true,
            showDeliveryAmountWarning: false,
            deliveryAmountWarning: "",
            showAlert: false,
            alertText: "",
            refreshData: false,
            client_record: null,
            intercityDeliveryPrice: 0,
            intercityParcelWeight: 0,
            intercityParcelDim: "0x0x0 см",
            intercityParcelWidth: 0,
            intercityParcelHeight: 0,
            intercityParcelLength: 0,
            showParcelDimAndWeight: false,
            weightOptions: [],
            intercityAllowed: true,
            showPriceRequest: false,
            priceRequestProcessing: false,
            selectedDeliveryServiceID: "11594"//Почта России
        };

        this.orders_columns = [
            {
                title: '№ заказа',
                dataIndex:'orderID',
            },
            {
                title: 'Сумма',
                dataIndex: 'totalAmount',
                align: 'right',
            },
            {
                title: 'Статус',
                dataIndex: 'status',
            },
        ];

        this.handleDeliveryServiceChange = this.handleDeliveryServiceChange.bind(this);

        this.setSettingsShowToggle = this.setSettingsShowToggle.bind(this);
        this.clickSelectOrders = this.clickSelectOrders.bind(this);

        this.setAddClientToggle = this.setAddClientToggle.bind(this);
        this.setAddClientShow = this.setAddClientShow.bind(this);
        this.saveClient = this.saveClient.bind(this);

        this.setAddOrderToggle = this.setAddOrderToggle.bind(this);
        this.setClientOrdersShowToggle = this.setClientOrdersShowToggle.bind(this);
        this.setAddOrderShow = this.setAddOrderShow.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.calcTotalAmount = this.calcTotalAmount.bind(this);
        this.handleParcelDimAndWeight = this.handleParcelDimAndWeight.bind(this);
        this.setIntercityDim = this.setIntercityDim.bind(this);
        this.handleRequestDeliveryPrice = this.handleRequestDeliveryPrice.bind(this);
    }

    parseWeight(prc) {

        var price = 0;
        var str = "";

        for (var i = 0; i < prc.length; i++) {
            var el = prc[i];
            if ((el >= '0' && el <= '9') || el == '.' || el == ',') {
                str += el;
            }
        }

        price = parseFloat(str.replace(",", "."));

        if (isNaN(price)) {
            price = 0;
        }

        return price;
    }

    async calcDeliveryAmount(callback) {

        var amt = 0;
        var weightLimitReached = false;

        if (this.state.selectedDeliveryServiceID == "11623") {

            const token = await authService.getAccessToken();
            if (token.length > 0) {
                var weight = this.parseWeight(this.state.intercityParcelWeight.toString());

                if (this.state.intercityParcelWeight.toString().indexOf(" г") > 0) {
                    weight = weight / 1000;
                }

                this.setState({ loading: true });
                fetch('clients/calc_delivery_price',
                    {
                        method: "POST",
                        body: JSON.stringify({
                            "cityID": this.state.selectedAddOrderClientCityObject[0].id,
                            "serviceID": this.state.selectedDeliveryServiceID,
                            "weight": weight,
                            "width": this.state.intercityParcelWidth,
                            "height": this.state.intercityParcelHeight,
                            "length": this.state.intercityParcelLength,
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {

                        var msg = "";
                        var d = JSON.parse(data);
                        if (d.error != "OK") {
                            msg = d.error;
                        }

                        var err = false;

                        if (msg.length > 0) {
                            err = true;
                        }

                        this.setState({ loading: false, showAlert: err, alertText: msg });

                        callback(amt);
                    });
            }

            return;
        }

        if (this.state.selectedDeliveryServiceID == "11594") {

            if (this.state.selectedAddOrderClientCityObject != null) {

                var ct = this.state.selectedAddOrderClientCityObject[0];
                if (ct != null && ct.pricePerKg != null) {
                    amt = ct.pricePerKg.nBasePricePerKg;

                    if (this.state.intercityParcelWeight.toString().length > 0) {
                        if (this.state.intercityParcelWeight.toString().indexOf("г") > 0) {
                            var weight = this.parseWeight(this.state.intercityParcelWeight.toString());

                            if (this.state.intercityParcelWeight.toString().indexOf(" г") > 0) {
                                weight = weight / 1000;
                            }

                            if (weight > ct.pricePerKg.nPricePerKg1_Limit && weight <= ct.pricePerKg.nPricePerKg2_Limit) {
                                var wg = Math.ceil(weight - ct.pricePerKg.nPricePerKg1_Limit);
                                amt += ct.pricePerKg.nPricePerKg1 * wg;
                            }
                            else {
                                if (weight > ct.pricePerKg.nPricePerKg2_Limit && weight <= ct.pricePerKg.nPricePerKg_Limit) {
                                    var wg = Math.ceil(weight - ct.pricePerKg.nPricePerKg1_Limit);
                                    amt += ct.pricePerKg.nPricePerKg2 * wg;
                                }
                                else {
                                    if (weight > ct.pricePerKg.nPricePerKg_Limit) {
                                        amt = 0;
                                        weightLimitReached = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            this.setState({ weightLimitReached: weightLimitReached });
            callback(amt);
        }

        callback(amt);
    }

    setIntercityDim() {

        var saveButtonDisabled = true;
        var that = this;

        this.calcDeliveryAmount(function (amt) {

            if (that.state.selectedDeliveryType == 4) {
                if (that.state.selectedPayer == "DELIVERY_OPT_PAY_SENDER") {

                    var weight = that.parseWeight(that.state.intercityParcelWeight.toString());

                    if (that.state.intercityParcelWeight.toString().indexOf(" г") > 0) {
                        weight = weight / 1000;
                    }

                    if (amt > 0 && that.state.selectedClientAddress.length > 0 &&
                        weight > 0 && weight <= 20 &&
                        that.state.intercityParcelHeight > 0 &&
                        that.state.intercityParcelLength > 0 &&
                        that.state.intercityParcelWidth > 0) {
                        saveButtonDisabled = false;
                    }
                }
                else {
                    if (that.state.selectedClientAddress.length > 0) {
                        saveButtonDisabled = false;
                    }
                }
            }

            that.setState({ saveButtonDisabled: saveButtonDisabled, intercityDeliveryPrice: amt, intercityParcelDim: that.state.intercityParcelWidth + "x" + that.state.intercityParcelHeight + "x" + that.state.intercityParcelLength + " см" });
        });
    }

    handleDeliveryServiceChange(e) {
        this.setState({
            selectedDeliveryServiceID: e
        }, function () {

        });
    }

    async handleRequestDeliveryPrice() {

        const token = await authService.getAccessToken();
        var that = this;
        if (token.length > 0) {

            if (this.state.selectedAddOrderClientCityObject != null) {
                this.setState({ loading: true });
                await fetch('clients/request_delivery_price',
                    {
                        method: "POST",
                        body: JSON.stringify({ "cityID": this.state.selectedAddOrderClientCityObject[0].id }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {

                        var msg = "Запрос отправлен";
                        var d = JSON.parse(data);
                        if (d.error != "OK") {
                            msg = d.error;
                        }

                        this.setState({ loading: false, showAlert: true, alertText: msg });
                    });
            }
        }
    }

    handleParcelDimAndWeight() {
        this.setState({ showParcelDimAndWeight: true });
    }

    calcTotalAmount() {
        const { deliveryAmount, itemsAmount, orgDeliveryAmount } = this.state;

        if (orgDeliveryAmount > deliveryAmount) {

            this.setState({ showDeliveryAmountWarning: true, deliveryAmountWarning: "Сумма за доставку, в размере: " + (orgDeliveryAmount - deliveryAmount) + " руб. будет списана с вашего Л/С" });
        }
        else {
            this.setState({ showDeliveryAmountWarning: false });
        }

        var _deliveryAmount = parseFloat(deliveryAmount);
        var _itemsAmount =  parseFloat(itemsAmount);

        if (isNaN(_deliveryAmount)) {
            _deliveryAmount = 0;
        }

        if (isNaN(_itemsAmount)) {
            _itemsAmount = 0;
        }

        this.setState({
            totalAmount: _deliveryAmount + _itemsAmount,
            recalcButtonDisabled: true,
            saveButtonDisabled: false
        });

        return _deliveryAmount + _itemsAmount;
    }

    setAddClientShow() {

        this.setState({ addClientShow : true});
    }

    setAddClientToggle() {
        this.setState({ addClientShow: !this.state.addClientShow });
    }

    setSettingsShowToggle() {
        this.setState({ settingsShow: !this.state.settingsShow });
    }

    setAddOrderShow() {

        var addOrderPricesBlockShow = true;
        var addOrderNotesShow = false;
        if (this.state.selectedDeliveryType == 4) {
            addOrderPricesBlockShow = false;
            if (this.state.selectedPayer == "DELIVERY_OPT_PAY_RECEIVER") {
                addOrderNotesShow = true;
            }
        }

        this.setState({ addOrderShow: true, addOrderPricesBlockShow: addOrderPricesBlockShow, addOrderNotesShow: addOrderNotesShow });
    }

    setAddOrderToggle() {

        if (this.state.addOrderShow == true) {
            contextData.selectedClientID = 0;
            contextData.selectedClientRecord = null;
            contextData.selectedGoods = [];
        }

        this.setState({ addOrderShow: !this.state.addOrderShow });
    }

    setClientOrdersShowToggle() {
        this.setState({ clientOrdersShow: !this.state.clientOrdersShow });
    }

    async saveOrder() {
        const { selectedPayer, addOrderNotes, deliveryAmount, itemsAmount, selectedDeliveryType, selectedClientCityID, selectedAcceptPoint, selectedGiveoutPoint, selectedClientAddress, selectedClientID, itemsDescription } = this.state;

        const token = await authService.getAccessToken();
        var that = this;
        if (token.length > 0) {

            var items = new Array();

            if (contextData.selectedGoods != null && contextData.selectedGoods.length > 0) {
                contextData.selectedGoods.forEach(function (el) {
                    items.push(el.orderID);
                });
            }

            var order_data = {
                ClientID: selectedClientID,
                DeliveryAmount: deliveryAmount,
                ItemsAmount: itemsAmount,
                AcceptStorageID: selectedAcceptPoint,
                GiveoutStorageID: selectedGiveoutPoint,
                DeliveryCityID: selectedClientCityID,
                DeliveryAddress: selectedClientAddress,
                Items: itemsDescription,
                DeliveryType: selectedDeliveryType,
                Notes: addOrderNotes,
                ItemsList: items,
                PaySender: selectedPayer == "DELIVERY_OPT_PAY_SENDER"
            };

            if (this.state.selectedDeliveryType == 4) {

                if (this.state.selectedPayer == "DELIVERY_OPT_PAY_SENDER") {

                    var weight = this.parseWeight(this.state.intercityParcelWeight.toString());

                    if (this.state.intercityParcelWeight.toString().indexOf(" г") > 0) {
                        weight = weight / 1000;
                    }

                    if (weight == 0) {
                        alert("Введите вес!");
                        return;
                    }

                    if (this.state.intercityParcelHeight == 0 || this.state.intercityParcelWidth == 0 || this.state.intercityParcelLength == 0) {
                        alert("Введите размер!");
                        return;
                    }

                    if (this.state.intercityDeliveryPrice == 0) {
                        alert("Стоимость не расчитана!");
                        return;
                    }

                    order_data.itemsAmount = 0;
                    order_data.deliveryAmount = this.state.intercityDeliveryPrice;
                    order_data.weight = weight;
                    order_data.width = this.state.intercityParcelWidth;
                    order_data.length = this.state.intercityParcelLength;
                    order_data.height = this.state.intercityParcelHeight;
                }
            }

            this.setState({ loading: true, addOrderShow: false });

            await fetch('clients/save_order',
                {
                    method: "POST",
                    body: JSON.stringify(order_data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {
                    var order = JSON.parse(data);
                    if (order != null) {
                        that.setState({ showAlert: true, alertText: order.error, loading: false, addOrderShow: false, refreshData: true }, function () {
                            contextData.selectedClientID = 0;
                            contextData.selectedClientRecord = null;
                            contextData.selectedGoods = [];
                        });
                    }
                    else {

                        that.setState({ showAlert: true, alertText: "Произошла неизвестная ошибка", loading: false, addOrderShow: false });
                    }
                });
        }
    }

    async saveClient() {

        const { pagination, client_record } = this.state;

        if (this.state.form != undefined) {
            const token = await authService.getAccessToken();

            if (token.length > 0) {

                this.setState({ loading: true, addClientShow: !this.state.addClientShow });

                var client_data = {
                    ClientID: this.state.selectedClientID,
                    Phone: this.state.form["clientPhone"],
                    Name: this.state.form["clientName"],
                    Address: this.state.form["clientAddress"],
                    Nick: this.state.form["clientNick"],
                    Notes: this.state.form["clientNotes"],
                    City: this.state.form["clientCity"],
                };

                var that = this;

                await fetch('clients/save',
                    {
                        method: "POST",
                        body: JSON.stringify(client_data),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {

                        var cl = JSON.parse(data);
                        if (cl.result == false) {
                            if (cl.error.length > 0) {
                                that.setState({ showAlert: true, alertText: cl.error, loading: false, refreshData: cl.undeleted, search: "" }, function () {

                                });
                            } else {
                                that.setState({ loading: false });
                            }
                        } else {

                            if (client_record != null) {
                                client_record.phone = cl.phone;
                                client_record.address = cl.address;
                                client_record.name = cl.name;
                                client_record.nick = cl.nick;
                                client_record.notes = cl.notes;
                                client_record.city = cl.city;
                                client_record.cityID = cl.cityID;
                            }

                            if (cl.error.length > 0) {
                                that.setState({ showAlert: true, alertText: cl.error, loading: false, refreshData: true }, function () {
                                });
                            }
                            else {
                                that.setState({ loading: false });
                            }

                        }
                    });
            }
        }
    }

    handleDeleteClient = () => {
        this.deleteClient();
    };

    async deleteClient() {

        this.setState({ showConfirmDeleteClient: false, loading: true });
        const { pagination } = this.state;

        const token = await authService.getAccessToken();

        if (token.length > 0) {
            var id = this.state.selectedClientID;
            await fetch('clients/delete',
                {
                    method: "DELETE",
                    body: JSON.stringify(id),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {

                    if (data.length > 0 && data != id) {
                        this.setState({ showAlert: true, alertText: data, loading: false });
                    }
                    else {
                        this.setState({ loading: false });
                        this.fetchClients({ pagination });
                    }
                });
        }
    }

    clickSelectOrders(e) {
        e.preventDefault();

        contextData.selectedClientID = this.state.selectedClientID;
        contextData.selectedClientName = this.state.selectedClientName;
        contextData.selectedClientRecord = this.state.record;

        this.props.history.push("/manage-orders/storage/select");
    }

    componentDidMount() {

        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated
        });

        if (isAuthenticated) {

            this.fetchAcceptPoints();
            this.fetchCities();
        }
        else {
            this.redirectToLogin();
        }
    }

    redirectToLogin() {
        this.props.history.push("/authentication/login");
    }

    async fetchCities() {
        const token = await authService.getAccessToken();
        var that = this;
        if (token.length > 0) {
            reqwest({
                url: 'clients/cities',
                method: 'get',
                type: 'json',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(data => {
                this.setState({
                    all_cities: data,
                    //selectedClientCityObject: [data[0]],
                }, function () {
                });
            });
        }
    }

    async fetchAcceptPoints() {

        const { pagination } = this.state;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'clients/acceptpoints',
                method: 'get',
                type: 'json',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(data => {

                var pts = data;

                pts.splice(0, 0, { "storageID": -1, "city": "", "name": "Выберите ПВЗ", "address": "" });

                this.setState({
                    accept_points: data,
                    giveout_points: pts
                });

                if (data.length > 0) {

                    var cities = new Array();

                    var selectedShopCityID = 0;
                    var selectedAcceptPoint = data[0].storageID; 
                    var selectedAcceptPointCity = data[0].city;
                    var selectedAcceptPointCityID = data[0].cityID;
                    var selectedReturnPointID = data[0].storageID;

                    for (var i = 0; i < data.length; i++) {
                        cities[data[i].cityID] = data[i].city;

                        if (data[i].shopSelectedCity == true) {
                            selectedShopCityID = data[i].cityID;
                            selectedAcceptPoint = data[i].storageID;
                            selectedAcceptPointCity = data[i].city;
                            selectedAcceptPointCityID = data[i].cityID;
                        }

                        if (data[i].shopSelectedReturnPoint == true) {
                            selectedReturnPointID = data[i].storageID;
                        }
                    }

                    this.setState({
                        selectedAcceptPoint: selectedAcceptPoint,
                        selectedGiveoutPoint: data[0].storageID,
                        selectedAcceptPointCity: selectedAcceptPointCity,
                        selectedAcceptPointCityID: selectedAcceptPointCityID,
                        selectedClientCity: data[0].city,
                        selectedClientCityID: data[0].cityID,
                        cities: cities,
                        selectedShopCityID: selectedShopCityID,
                        selectedReturnStorageID: selectedReturnPointID
                    });
                }

                this.fetchInterStorageRates();
                this.fetchInterCityRates();

                this.fetchClients({ pagination });
            });
        }

    }

    async fetchInterStorageRates() {

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'clients/interstoragerates',
                method: 'get',
                type: 'json',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(data => {

                this.setState({
                    interstorage_rates: data,
                }, function () {

                    this.setState({
                        deliveryAmount: this.getGiveoutPrice(),
                        orgDeliveryAmount: this.getGiveoutPrice(),
                    }, function () {
                        //this.calcTotalAmount();
                    });
                });
            });
        }
    }

    async fetchInterCityRates() {

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'clients/intercityrates',
                method: 'get',
                type: 'json',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(data => {

                this.setState({
                    intercity_rates: data,
                }, function () {

                    this.setState({
                        deliveryAmount: this.getGiveoutPrice(),
                        orgDeliveryAmount: this.getGiveoutPrice(),
                    }, function () {
                        //this.calcTotalAmount();
                    });
                });
            });
        }
    }

    async fetchClients (params) {

        this.setState({ loading: true });
        const { interstorage_rates } = this.state;

        var that = this;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'clients',
                method: 'get',
                type: 'json',
                data: getClientsParams(params),
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {
                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {
                console.log(data);
                this.setState({
                    loading: false,
                    clients: data.results,
                    pagination: {
                        ...params.pagination,
                        total: data.totalCount,
                    },
                    showNick: data.showNick
                }, function () {
                    if (contextData.selectedClientID > 0 && contextData.selectedGoods.length > 0) {

                        var total_goods_value = 0;
                        contextData.selectedGoods.forEach(function (itm) {
                            total_goods_value += itm.totalAmount;
                        });

                        that.setState({ itemsAmount: total_goods_value }, function () {
                            //that.calcTotalAmount();
                            that.handleClientAddOrder(contextData.selectedClientRecord, null);
                        });
                    }
                });
            });
        }
    }

    async fetchOrders(client_id) {

        this.setState({ loading: true });

        var that = this;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'clients/orders',
                method: 'get',
                type: 'json',
                data: { clientID: client_id },
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {
                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {
                console.log(data);
                this.setState({
                    loading: false,
                    orders: data.results,
                    clientOrdersShow: true,
                });
            });
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        const search = this.state.search;

        this.fetchClients({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            search,
            ...filters,
        });
    };

    handleClientDelete = (key, e) => {
        e.preventDefault();
        this.setState({ selectedClientID: key }, function () {
            this.deleteClient();
        });
    };

    handleSettings = () => {
        this.setState({ settingsShow: true }, function () {
        });
    };

    handleNewClient = () => {
        this.setState({ selectedClientID: 0, selectedClientCity: "", selectedClientAddress: "", selectedClientName: "", selectedClientPhone: "", addClientHeader: "Новый клиент", selectedClientNick: "", selectedClientNotes: "" });
        this.setState({
            form: Object.assign({}, this.state.form, {
                ["clientPhone"]: "",
                ["clientName"]: "",
                ["clientAddress"]: "",
                ["clientNick"]: "",
                ["clientNotes"]: "",
                ["clientCity"]: "",
            }),
        });
        this.setAddClientShow();
    };

    handleClientEdit = (record, e) => {

        e.preventDefault();


        var selectedClientCityObject = [{ "id": null, "name": "" }];

        var courierDeliveryCityWarning = true;

        if (record.cityID != null) {

            selectedClientCityObject = [{ "id": record.cityID, "name": record.city }];
            this.state.cities.map((e1, key) => {
                if (record.cityID == key) {
                    courierDeliveryCityWarning = false;
                }
            });
        }
        else {
            courierDeliveryCityWarning = false;
        }

        this.setState({ record: record, courierDeliveryCityWarning: courierDeliveryCityWarning, selectedClientCityObject: selectedClientCityObject, client_record: record, selectedClientPhone: record.phone, selectedClientID: record.clientID, selectedClientName: record.name, selectedClientNick: record.nick, addClientHeader: "Изменение клиента", selectedClientCityID: record.cityID, selectedClientCity: record.city, selectedClientAddress: record.address, selectedClientNotes: record.notes });

        this.setState({
            form: Object.assign({}, this.state.form, {
                ["clientPhone"]: record.phone,
                ["clientName"]: record.name,
                ["clientAddress"]: record.address,
                ["clientNick"]: record.nick,
                ["clientNotes"]: record.notes,
                ["clientCity"]: record.city,
                record: record
            }),
        });

        this.setAddClientShow();
    };

    handleClientAddOrderNew = (record, e) => {
        this.props.history.push("/add-order/" + record.clientID);
    }

    handleClientAddOrder = (record, e) => {

        var profileClientCity = "";
        var profileClientCityID = 0;
        profileClientCity = record.city;
        profileClientCityID = record.cityID;
        var intercityAllowed = true;
        var selectedAddOrderClientCityObject = [{ "id": record.cityID, "name": record.city }];
        var selectedAddOrderClientCity = record.city;
        var selectedAddOrderClientCityRegion = "";
        var selectedAddOrderClientCityIndex = "";
        var showPriceRequest = false;
        var priceRequestProcessing = false;

        if (this.state.all_cities != null) {
            this.state.all_cities.forEach(function (ct) {
                if (ct.id == selectedAddOrderClientCityObject[0].id) {
                    selectedAddOrderClientCityObject = [ct];
                    if (ct.pvzCity == true) {
                        intercityAllowed = false;
                    }
                    else {
                        if (ct.pricePerKg == null) {
                            showPriceRequest = true;
                        }
                        else {
                            if (ct.pricePerKg.nServiceID == 0) {
                                priceRequestProcessing = true;
                            }
                        }
                    }
                }
            });
        }

        if (e != null) {
            e.preventDefault();
            contextData.selectedClientID = 0;
            contextData.selectedClientRecord = null;
            contextData.selectedGoods = [];
        }

        const { interstorage_rates } = this.state;

        if (Object.keys(interstorage_rates).length > 0) {

            var name_hdr = record.name;

            if (this.state.showNick && record.nick != undefined && record.nick.length > 0) {
                name_hdr += " (" + record.nick + ")";
            }

            var itemsDescription = "";
            var itemsDescriptionDisabled = false;
            if (contextData.selectedGoods != null && contextData.selectedGoods.length > 0) {
                contextData.selectedGoods.forEach(function (val) {
                    itemsDescription += val.clientName + "\n";
                });

                itemsDescriptionDisabled = true;
            }
            //mas
            this.setState({
                selectedAddOrderClientCityObject: selectedAddOrderClientCityObject,
                profileClientCity: profileClientCity,
                profileClientCityID: profileClientCityID,
                loading: false,
                selectedClientPhone: record.phone,
                selectedClientID: record.clientID,
                selectedClientNameHdr: name_hdr,
                selectedClientName: record.name,
                selectedClientNick: record.nick,
                addClientHeader: "",
                selectedClientAddress: record.address,
                selectedClientCity: record.city,
                selectedClientCityID: record.cityID,
                addOrderNotes: record.notes,
                itemsDescription: itemsDescription,
                itemsDescriptionDisabled: itemsDescriptionDisabled,
                record: record,
                selectedAddOrderClientCity: selectedAddOrderClientCity,
                selectedAddOrderClientCityRegion: selectedAddOrderClientCityRegion,
                selectedAddOrderClientCityIndex: selectedAddOrderClientCityIndex,
                intercityAllowed: intercityAllowed,
                showPriceRequest: showPriceRequest,
                priceRequestProcessing: priceRequestProcessing,
                selectedDeliveryType: 0,
                selectedDeliveryTypeName: "ПВЗ-ПВЗ",
                saveButtonDisabled: true,
                intercityDeliveryPrice: 0,
                intercityParcelWeight: 0,
                intercityParcelDim: "0x0x0 см",
                intercityParcelWidth: 0,
                intercityParcelHeight: 0,
                intercityParcelLength: 0,
                selectedGiveoutPoint: -1,
                totalAmount: 0
            }, function () {
                this.handleOrderDeliveryTypeChange(0);
            });
            this.setAddOrderShow();
        }
        else {
            this.setState({ loading: true });
        }
    };

    handleShowNotAcceptedClientOrders = (record, e) => {
        e.preventDefault();
        this.fetchOrders(record.clientID);
    };

    handleShowInStorageClientOrders = (record, e) => {
        e.preventDefault();
        this.fetchOrders(record.clientID);
    };

    handleShowClientNotes = (record, e) => {
        e.preventDefault();

        if (record.notes != null && record.notes.length > 0) {
            this.setState({ showAlert: true, alertText: record.notes });
        }
    };

    handleConfirmDeleteNo = () => {
        this.setState({ showConfirmDeleteClient: false });
    };

    handleAlertClose = () => {
        const { refreshData, pagination, search } = this.state;

        var r = refreshData;

        this.setState({ showAlert: false, refreshData: false }, function () {
            if (r) {
                if (search.length == 0) {
                    this.fetchClients({ pagination });
                }
                else {
                    this.fetchClients({ pagination, search });
                }
            }
        });
    };

    handleCityChange(e) {
        if (e != null && e.length > 0) {

            var courierDeliveryCityWarning = true;

            this.state.cities.map((e1, key) => {
                if (e[0].id == key) {
                    courierDeliveryCityWarning = false;
                }
            });

            this.setState({
                selectedClientCity: e[0].name,
                selectedClientCityID: e[0].id,
                selectedClientCityObject: e,
                form: Object.assign({}, this.state.form, {
                    ["clientCity"]: e[0].name,
                }),
                courierDeliveryCityWarning: courierDeliveryCityWarning
            });
        }
        else {
            this.setState({
                selectedClientCity: "",
                selectedClientCityID: null,
                selectedClientCityObject: null,
                form: Object.assign({}, this.state.form, {
                    ["clientCity"]: "",
                }),
            });
        }
    }

    handleFieldChange(event) {

        this.setState({
            form: Object.assign({}, this.state.form, {
                [event.target.name]: event.target.value,
            }),
        });

        if (event.target.name == "clientPhone") {
            this.setState({ selectedClientPhone: event.target.value });
        }
        if (event.target.name == "clientName") {
            this.setState({ selectedClientName: event.target.value });
        }

        if (event.target.name == "clientAddress") {
            this.setState({ selectedClientAddress: event.target.value }, function () {

                if (this.state.selectedDeliveryType == 4) {
                    this.setIntercityDim();
                }
            });
        }

        if (event.target.name == "clientCity") {
            this.setState({ selectedClientCity: event.target.value });
        }

        if (event.target.name == "clientNick") {
            this.setState({ selectedClientNick: event.target.value });
        }

        if (event.target.name == "clientNotes") {

            this.setState({ selectedClientNotes: event.target.value });
        }

        if (event.target.name == "itemsAmount") {
            if (!isNaN(parseFloat(event.target.value))) {
                this.setState({ itemsAmount: parseFloat(event.target.value), saveButtonDisabled: true, recalcButtonDisabled: false }, function () {
                    //this.calcTotalAmount();
                });
            }
        }

        if (event.target.name == "deliveryAmount") {
            var amt = parseFloat(event.target.value);
            if (!isNaN(amt)) {
                this.setState({ deliveryAmount: parseFloat(event.target.value), saveButtonDisabled: true, recalcButtonDisabled: false }, function () {
                    //this.calcTotalAmount();
                });
            }
        }
    }

    handleOrderDeliveryTypeChange(e) {

        const { cities, all_cities } = this.state;

        var courierOrderDeliveryCityWarning = false;
        var courierOrderDeliveryCityWarningText = "";
        var addOrderPricesBlockShow = true;
        var addOrderNotesShow = false;

        if (e == 0 || e == 1) {
            if (e == 0) {
                this.setState({ recalcButtonDisabled: false, saveButtonDisabled: true, addOrderNotesShow: addOrderNotesShow, addOrderPricesBlockShow: addOrderPricesBlockShow, courierOrderDeliveryCityWarningText: courierOrderDeliveryCityWarningText, courierOrderDeliveryCityWarning: courierOrderDeliveryCityWarning, selectedDeliveryType: e, selectedDeliveryTypeName: deliveryTypes[e], addOrderAcceptPointShow: true, addOrderGiveoutPointShow: true, addOrderClientAddressShow: false }, function () {
                    var amt = this.getGiveoutPrice();
                    this.setState({
                        deliveryAmount: amt, orgDeliveryAmount: amt
                    }, function () {
                        //this.calcTotalAmount();
                    });
                });
            }
            else {
                courierOrderDeliveryCityWarning = true;

                var city = "";
                var profileClientCity = this.state.profileClientCity;
                cities.map((e1, key) => {
                    if (this.state.selectedClientCityID == key) {
                        city = e1;
                        courierOrderDeliveryCityWarning = false;
                    }
                });

                if (city.length == 0) {
                    all_cities.map((e1, key) => {
                        if (e1.id == this.state.selectedClientCityID) {
                            city = e1.name;
                        }
                    });
                }

                if (this.state.selectedDeliveryType != 4) {
                }
                else {
                    courierOrderDeliveryCityWarning = false;
                }

                if (courierOrderDeliveryCityWarning) {
                    if (profileClientCity.length > 0) {
                        courierOrderDeliveryCityWarningText = "У получателя в профиле указан город доставки " + profileClientCity + ". Если доставка необходима в " + city + " выберите тип доставки «Межгород» или замените город получателя в его профиле.";
                    }
                    else {
                        courierOrderDeliveryCityWarningText = "У получателя в профиле не указан город доставки. Если доставка необходима в " + city + " выберите тип доставки «Межгород» или замените город получателя в его профиле.";
                    }
                }

                this.setState({ recalcButtonDisabled: false, saveButtonDisabled: true, addOrderNotesShow: addOrderNotesShow, addOrderPricesBlockShow: addOrderPricesBlockShow, courierOrderDeliveryCityWarningText: courierOrderDeliveryCityWarningText, courierOrderDeliveryCityWarning: courierOrderDeliveryCityWarning, selectedDeliveryType: e, addOrderAcceptPointShow: true, addOrderGiveoutPointShow: false, addOrderClientAddressShow: true }, function () {
                    var amt = this.getGiveoutPrice();
                    this.setState({
                        deliveryAmount: amt, orgDeliveryAmount: amt
                    }, function () {
                        //this.calcTotalAmount();
                    });
                });
            }
        }
        else {
            if (e == 4) {
                addOrderPricesBlockShow = false;

                if (this.state.selectedPayer == "DELIVERY_OPT_PAY_RECEIVER") {
                    addOrderNotesShow = true;
                }

                this.setState({ saveButtonDisabled: true,  addOrderNotesShow: addOrderNotesShow, addOrderPricesBlockShow: addOrderPricesBlockShow, courierOrderDeliveryCityWarningText: courierOrderDeliveryCityWarningText, courierOrderDeliveryCityWarning: courierOrderDeliveryCityWarning, selectedDeliveryType: e, addOrderAcceptPointShow: false, addOrderGiveoutPointShow: false, addOrderClientAddressShow: true }, function () {
                    var amt = this.getGiveoutPrice();
                    this.setState({
                        deliveryAmount: amt, orgDeliveryAmount: amt
                    }, function () {
                        //this.calcTotalAmount();
                    });
                });
            }
            else {
                this.setState({ saveButtonDisabled: true,  addOrderNotesShow: addOrderNotesShow, addOrderPricesBlockShow: addOrderPricesBlockShow,  courierOrderDeliveryCityWarningText: courierOrderDeliveryCityWarningText, courierOrderDeliveryCityWarning: courierOrderDeliveryCityWarning, selectedDeliveryType: e, addOrderAcceptPointShow: false, addOrderGiveoutPointShow: false, addOrderClientAddressShow: true, saveButtonDisabled: true, recalcButtonDisabled: true }, function () {
                    var amt = this.getGiveoutPrice();
                    this.setState({
                        deliveryAmount: amt, orgDeliveryAmount: amt
                    }, function () {
                        //this.calcTotalAmount();
                    });
                });
            }
        }
    }

    getGiveoutPrice() {
        var price = 0;

        const { selectedAcceptPoint, selectedGiveoutPoint, interstorage_rates, giveout_points, selectedDeliveryType, selectedClientCityID, selectedAcceptPointCityID, intercity_rates } = this.state;

        if (selectedDeliveryType == 0) {

            giveout_points.map((e, key) => {
                if (e.storageID == selectedGiveoutPoint) {
                    price = interstorage_rates[selectedAcceptPoint + "_" + selectedGiveoutPoint] + e.giveoutPrice;
                }
            })
        }
        else if (selectedDeliveryType == 1) {

            giveout_points.map((e, key) => {
                if (e.cityID == selectedClientCityID) {

                    giveout_points.map((e, key) => {
                        if (e.cityID == selectedClientCityID) {
                            price = interstorage_rates[selectedAcceptPoint + "_" + e.storageID] + e.courierPrice;
                        }
                    })
                }
            })
        }

        if (isNaN(price)) {
            price = 0;
        }

        return price;
    }

    handleAcceptPointChange(e) {

        const { accept_points } = this.state;

        accept_points.map((e1, key) => {
            if (e1.storageID == e) {
                this.setState({ selectedAcceptPoint: e, selectedAcceptPointCityID: e1.cityID, selectedAcceptPointCity: e1.city, selectedClientCity: e1.city, selectedClientCityID: e1.cityID }, function () {

                    var amt = this.getGiveoutPrice();
                    this.setState({
                        selectedAcceptPoint: e, deliveryAmount: amt, orgDeliveryAmount: amt
                    }, function () {
                        //this.calcTotalAmount();
                    });

                });
                return;
            }
        });
    }

    handleItemsDesription(e) {
        this.setState({ itemsDescription: e.target.value });
    }

    handleAddOrderNotes(e) {
        this.setState({ addOrderNotes: e.target.value });
    }

    handleClientAddressChange(e) {
        this.setState({ selectedClientAddress: e.target.value }, function () {
            if (this.state.selectedDeliveryType == 4) {
                this.setIntercityDim();
            }
        });
    }

    handleClientCityChange(ea) {

        var selectedAddOrderClientCityObject = ea;
        var that = this;
        if (ea != null && ea.length > 0) {

            var e = ea[0];

            const { cities, all_cities } = this.state;

            var courierOrderDeliveryCityWarning = true;
            var courierOrderDeliveryCityWarningText = "";
            var intercityDeliveryPrice = 0;
            var intercityAllowed = true;
            var showPriceRequest = false;
            var profileClientCity = this.state.profileClientCity;
            var selectedAddOrderClientCity = e.name;
            var selectedAddOrderClientCityRegion = e.region;
            var selectedAddOrderClientCityIndex = "";

            if (e.pricePerKg != null && e.pricePerKg.szCityIndex != null) {
                selectedAddOrderClientCityIndex = e.pricePerKg.szCityIndex;
            }

            var priceRequestProcessing = false;

            var city = e.name;
            cities.map((e1, key) => {
                if (e.id == key) {
                    city = e1;
                    courierOrderDeliveryCityWarning = false;
                }
            });

            if (city != null && city.length == 0) {
                all_cities.map((e1, key) => {
                    if (e1.id == e.id) {
                        city = e1.name;
                    }
                });
            }

            if (this.state.selectedDeliveryType != 4) {

            }
            else {
                courierOrderDeliveryCityWarning = false;

                if (selectedAddOrderClientCityObject[0] != null && selectedAddOrderClientCityObject[0].pvzCity == true) {
                    intercityAllowed = false;
                }
            }

            if (courierOrderDeliveryCityWarning) {
                if (profileClientCity.length > 0) {
                    courierOrderDeliveryCityWarningText = "У получателя в профиле указан город доставки " + profileClientCity + ". Если доставка необходима в " + city + " выберите тип доставки «Межгород» или замените город получателя в его профиле.";
                }
                else {
                    courierOrderDeliveryCityWarningText = "У получателя в профиле не указан город доставки. Если доставка необходима в " + city + " выберите тип доставки «Межгород» или замените город получателя в его профиле.";
                }
            }

            this.setState({
                selectedClientCityID: e.id,
                selectedClientCity: e.name,
                courierOrderDeliveryCityWarning: courierOrderDeliveryCityWarning,
                courierOrderDeliveryCityWarningText: courierOrderDeliveryCityWarningText,
                selectedAddOrderClientCityObject: selectedAddOrderClientCityObject,
                intercityDeliveryPrice: intercityDeliveryPrice,
                intercityAllowed: intercityAllowed,
                selectedAddOrderClientCity: selectedAddOrderClientCity,
                selectedAddOrderClientCityRegion: selectedAddOrderClientCityRegion,
                selectedAddOrderClientCityIndex: selectedAddOrderClientCityIndex,
                showPriceRequest: showPriceRequest,
                priceRequestProcessing: priceRequestProcessing

            }, function () {

                var amt = this.getGiveoutPrice();

                this.setState({
                    deliveryAmount: amt, orgDeliveryAmount: amt
                }, function () {
                    //this.calcTotalAmount();

                    if (this.state.selectedDeliveryType == 4) {
                        if (selectedAddOrderClientCityObject[0].pricePerKg != null) {
                            this.calcDeliveryAmount(function (amt) {
                                intercityDeliveryPrice = amt;
                                priceRequestProcessing = selectedAddOrderClientCityObject[0].pricePerKg.nServiceID == 0;
                                that.setState({ intercityDeliveryPrice: intercityDeliveryPrice, priceRequestProcessing: priceRequestProcessing });
                            });
                        }
                        else {
                            if (selectedAddOrderClientCityObject[0].pvzCity == false) {
                                showPriceRequest = true;
                                this.setState({ showPriceRequest: showPriceRequest });
                            }
                        }
                    }
                });
            });
        }
        else {
            //mas
            this.setState({ selectedAddOrderClientCityObject: selectedAddOrderClientCityObject, saveButtonDisabled: true, selectedAddOrderClientCityIndex: "", selectedAddOrderClientCityRegion: "", intercityAllowed: false, selectedAddOrderClientCity: "" });
        }
    }

    async handleReturnStorage(e) {
        var c = e;
        this.setState({ selectedReturnStorageID: c });
        var that = this;

        const token = await authService.getAccessToken();

        fetch('clients/save_return_storage',
            {
                method: "POST",
                body: JSON.stringify({ return_storage_id: c }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).
            then(response => response.text())
            .then(data => {

                that.fetchAcceptPoints();
            });
    }

    async handleShopCity(e) {
        var c = e;
        this.setState({ selectedShopCityID: c });
        var that = this;

        const token = await authService.getAccessToken();

        fetch('clients/save_shop_city',
            {
                method: "POST",
                body: JSON.stringify({ shop_city_id: c }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).
            then(response => response.text())
            .then(data => {

                that.fetchAcceptPoints();
            });

    }

    async handleShowNick(e) {
        var c = false;
        c = e.target.checked;
        this.setState({ showNick: c });

        const token = await authService.getAccessToken();

        fetch('clients/save_show_nick',
            {
                method: "POST",
                body: JSON.stringify({ show_nick: c }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).
            then(response => response.text())
            .then(data => {
            });
    }

    handleGiveoutPointChange(e) {

        this.setState({
            selectedGiveoutPoint: e
        }, function () {
            var amt = this.getGiveoutPrice();
            if (!isNaN(amt)) {

                this.setState({
                    deliveryAmount: amt, orgDeliveryAmount: amt,
                    recalcButtonDisabled: false
                }, function () {

                    this.calcTotalAmount();
                });
            }
        });
    }

    onSearch = (e) => {

        this.state.pagination.current = 1;

        this.setState({ search: e });

        const { pagination } = this.state;
        const search = e;

        this.fetchClients({ pagination, search });
    };

    onSelectWeight = (s) => {

    };

    onChangeWeight = (s) => {
        this.setState({ intercityParcelWeight: s }, function () {
            this.setIntercityDim();
        });
    };

    onSearchWeight = (s) => {
        if (s.length > 0) {
            s = s.replace("кг", "").replace("г", "").trim();
            this.setState({ weightOptions: [{ "value": s + ' г' }, { "value": s + ' кг' }] });
        }
        else {
            this.setState({ weightOptions: [] });
        }
    };

    renderNotesButton(text, record) {
        if (record.notes != null && record.notes.length > 0) {
            return <Button title="Примечания" type="text" onClick={(e) => this.handleShowClientNotes(record, e)} ><ExclamationCircleOutlined style={{ color: "blue" }} /></Button>;
        }
        else {
            return "";
        }
    }

    render() {

        const { clients, pagination, loading, showConfirmDeleteClient,
            selectedClientPhone, selectedClientName, selectedClientAddress, selectedClientNick,
            isAuthenticated, accept_points, giveout_points, selectedAcceptPoint,
            selectedGiveoutPoint, addOrderClientAddressShow, selectedDeliveryType,
            addOrderAcceptPointShow, addOrderGiveoutPointShow, deliveryAmount, selectedClientNameHdr,
            itemsAmount, totalAmount, recalcButtonDisabled, saveButtonDisabled, showDeliveryAmountWarning,
            deliveryAmountWarning, selectedClientCity, selectedClientCityID, cities, selectedClientNotes,
            showAlert, alertText, itemsDescription, orders, selectedPayer, weightOptions } = this.state;

        var showEmptyBlock = this.intercityAllowed;

        if (this.state.showPriceRequest == true || this.state.priceRequestProcessing == true) {
            showEmptyBlock = false;
        }

        if (this.state.intercityAllowed == false && this.state.selectedDeliveryType == 4 && this.state.selectedAddOrderClientCity.length > 0) {
            showEmptyBlock = false;
        }

        if (selectedDeliveryType == 4 && selectedPayer == "DELIVERY_OPT_PAY_SENDER" && this.state.intercityAllowed) {
            showEmptyBlock = false;
        }

        this.columns = [
            {
                title: 'Телефон',
                dataIndex: 'phone',
                sorter: false,
                width: '80px',
            },
            {
                title: 'Имя',
                dataIndex: 'name',
            },
            {
                title: this.state.showNick ? 'Ник' : '',
                dataIndex: 'nick',
                width: this.state.showNick ? 70 : 0,
                render: (text, record) => (this.state.showNick ? record.nick : "")
            },
            {
                title: '',
                dataIndex: '',
                key: 'e',
                render: (text, record) => (<Button title="Новый заказ" type="text" onClick={(e) => this.handleClientAddOrder(record, e)}><FileAddOutlined style={{ color: "green" }} /></Button>),
                width: '10px',
                fixed: 'right',
            },
            {
                title: '',
                dataIndex: '',
                key: 'e',
                render: (text, record) => (<Button title="Новый заказ в постамат (отладка)" type="text" onClick={(e) => this.handleClientAddOrderNew(record, e)}><FileAddOutlined style={{ color: "green" }} /></Button>),
                width: '10px',
                fixed: 'right',
            },
            {
                title: '',
                dataIndex: '',
                key: 'e',
                render: (text, record) => (<Button title="Редактировать" type="text" onClick={(e) => this.handleClientEdit(record, e)}><EditOutlined style={{ color: "green" }} /></Button>),
                width: '10px',
                fixed: 'right',
            },
            {
                title: '',
                dataIndex: '',
                key: 'x',
                render: (text, record) => (<Popconfirm title="Удалить клиента?" okText="Да" cancelText="Нет" onConfirm={(e) => this.handleClientDelete(record.clientID, e)}><DeleteOutlined title="Удалить клиента" style={{ color: "red" }} /></Popconfirm>),
                width: '10px',
                fixed: 'right',
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (text, record) => (this.renderNotesButton(text, record)),
                width: '10px',
                fixed: 'right',
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (text, record) => (<a href="#" onClick={(e) => { this.handleShowNotAcceptedClientOrders(record, e) }} ><Badge title="Не принятые" style={{ backgroundColor: 'red' }} count={record.notAcceptedOrdersCount}></Badge></a>),
                width: '10px',
                fixed: 'right',
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                render: (text, record) => (<a href="#" onClick={(e) => { this.handleShowInStorageClientOrders(record, e) }}><Badge title="На складе" style={{ backgroundColor: 'green' }} count={record.inStorageOrdersCount}></Badge></a>),
                width: '10px',
                fixed: 'right',
            },
        ];

        const cols = this.columns;
        const orders_cols = this.orders_columns;

        return (
            <div>
                <Modal
                    visible={this.state.addClientShow}
                    title={this.state.addClientHeader}
                    footer={[
                        <Button key="back" onClick={this.setAddClientToggle}>
                            Закрыть
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.saveClient}>
                            Сохранить
                        </Button>
                    ]}
                    onOk={this.saveClient}
                    onCancel={this.setAddClientToggle}>
                    <Form layout="vertical">
                        <Form.Item label="Номер телефона">
                            <Input type="phone" id="clientPhone" name="clientPhone" value={selectedClientPhone} placeholder="Номер телефона" onChange={e => this.handleFieldChange(e)} />
                        </Form.Item>
                        <Form.Item label="ФИО">
                            <Input type="text" id="clientName" name="clientName" value={selectedClientName} placeholder="ФИО" onChange={e => this.handleFieldChange(e)} />
                        </Form.Item>
                        <Form.Item label="Ник" style={{ display: this.state.showNick ? 'block' : 'none' }}>
                            <Input type="text" id="clientNick" name="clientNick" value={selectedClientNick} placeholder="Ник" onChange={e => this.handleFieldChange(e)} />
                        </Form.Item>

                        <Form.Item label="Населенный пункт">
                                <Typeahead 
                                size="small"
                                clearButton
                                placeholder="Населенный пункт"
                                id="clientCity"
                                labelKey="name"
                                options={this.state.all_cities}
                                selected={this.state.selectedClientCityObject}
                                onChange={(e) => { this.handleCityChange(e); }}
                            />
                        </Form.Item>
                        <Alert style={{ display: this.state.courierDeliveryCityWarning ? "inline-block" : "none" }} showIcon type="info" message="В примечаниях укажите как можно больше контактов получателя: WhatsApp, Telegram (если номер отличается от номера телефона), логин VK, Instagram, e-mail, что-нибудь еще, чтобы мы могли связаться с получателем для согласования доставки." />

                        <Form.Item label="Адрес для курьерской или междугородней доставки">
                            <Input type="text" id="clientAddress" name="clientAddress" value={selectedClientAddress} placeholder="Не обязательно, можно не заполнять" onChange={e => this.handleFieldChange(e)} />
                        </Form.Item>
                        <Form.Item label="Примечания">
                            <TextArea rows={2} id="clientNotes" name="clientNotes" value={selectedClientNotes} onChange={e => this.handleFieldChange(e)} />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    style={{ maxWidth: "400px" }}
                    visible={this.state.addOrderShow}
                    title={this.state.addOrderHeader}
                    footer={[
                        <Button key="none" type="dashed" onClick={this.calcTotalAmount} disabled={recalcButtonDisabled}>
                            Пересчитать
                        </Button>,
                        <Button key="back" onClick={this.setAddOrderToggle}>
                            Закрыть
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.saveOrder} disabled={saveButtonDisabled}>
                            Сохранить
                        </Button>
                    ]}
                    onOk={this.saveOrder}
                    onCancel={this.setAddOrderToggle}>
                    <Form layout="vertical" size="small">
                        <Form.Item label="Новый заказ">
                            <h5>{selectedClientNameHdr}</h5>
                        </Form.Item>

                        <Collapse>
                            <Panel header="Описание вложения" key="1">
                                <Button size='small' type='dashed' onClick={this.clickSelectOrders} style={{ marginBottom: "4px", display: this.state.itemsDescriptionDisabled ? "none" : "block" }} >Выбрать товары</Button>
                                <TextArea rows={2} id="orderItems" value={itemsDescription} readOnly={this.state.itemsDescriptionDisabled } onChange={e => { this.handleItemsDesription(e) }} name="orderItems" />
                            </Panel>

                            <Panel header={`Тип доставки - ${this.state.selectedDeliveryTypeName}`} key="2">
                                <Select style={{width:"260px"} } value={selectedDeliveryType} onChange={e => { this.handleOrderDeliveryTypeChange(e) }}>
                                    <Option value={0}>ПВЗ-ПВЗ</Option>;
                                    <Option value={1}>ПВЗ-Курьер</Option>;
                                    <Option value={2}>Курьер-ПВЗ</Option>;
                                    <Option value={3}>Курьер-Курьер</Option>;
                                    <Option value={4}>Межгород</Option>;
                                </Select>

                                <div style={{ display: selectedDeliveryType == 4 ? "block" : "none", marginTop:"0px" }}>
                                    <span>Оплата доставки:</span>&nbsp;
                                    <Radio.Group value={selectedPayer} onChange={(e) => { this.setState({ selectedPayer: e.target.value }, function () { this.setAddOrderShow() }) }}>
                                        <Radio value="DELIVERY_OPT_PAY_SENDER">Отправитель</Radio>&nbsp;&nbsp;
                                        <Radio value="DELIVERY_OPT_PAY_RECEIVER">Получатель</Radio>
                                    </Radio.Group>
                                </div>
                                <div style={addOrderAcceptPointShow ? { display: "block" } : { display: "none" }}>
                                    <span>Пункт приема заказа:</span>&nbsp;
                                    <Select id="addOrderAcceptPoint" name="addOrderAcceptPoint" value={selectedAcceptPoint} onChange={e => { this.handleAcceptPointChange(e) }}>
                                        {
                                            accept_points.map((e, key) => {
                                                return <Option value={e.storageID}>{e.name + " " + e.city + ", " + e.address}</Option>;
                                            })
                                        }
                                    </Select>
                                </div>
                            </Panel>
                            <Panel header="Адрес доставки" key="3">
                                <div style={addOrderGiveoutPointShow ? { display: "block" } : { display: "none" }}>
                                    <span>Пункт выдачи заказа:&nbsp;</span>
                                    <Select value={selectedGiveoutPoint} onChange={e => { this.handleGiveoutPointChange(e) }}>
                                        {
                                            giveout_points.map((e, key) => {
                                                if (e.address != null && e.address.length > 0) {
                                                    return <Option value={e.storageID}>{e.name + " " + e.city + ", " + e.address}</Option>;
                                                }
                                                else {
                                                    return <Option value={e.storageID}>{e.name + " " + e.city}</Option>;
                                                }
                                            })
                                        }
                                    </Select>
                                </div>
                                <div style={addOrderClientAddressShow ? { display: "block" } : {display: "none"}}>
                                    <Row gutter={16} style={{ marginTop: "-5px" }}>
                                        <Col span={12} className="gutter-row">
                                            <span>Адрес доставки:</span>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }} className="gutter-row">
                                            <b>{this.state.selectedAddOrderClientCityRegion}</b>
                                        </Col>
                                    </Row>
                                    <Input.Group >
                                        <Row gutter={16}>
                                            <Col span={18}  className="gutter-row">
                                                <Typeahead style={{ width: "220px" }}
                                                    emptyLabel="Ничего не найдено"
                                                    clearButton
                                                    size="small"
                                                    placeholder="Населенный пункт"
                                                    id="clientCityAddOrder"
                                                    labelKey={option => { return option.name }}
                                                    options={this.state.all_cities}
                                                    selected={this.state.selectedAddOrderClientCityObject}
                                                    onChange={(e) => { this.handleClientCityChange(e); }}
                                                ></Typeahead>
                                            </Col>
                                            <Col span={6} style={{ textAlign: 'right' }}  className="gutter-row">
                                                <b>{this.state.selectedAddOrderClientCityIndex}</b>
                                            </Col>
                                        </Row>
                                    </Input.Group>
                                    <Input.Group style={{ marginTop: "8px" }}>
                                            <Input style={{ width: '100%' }} value={selectedClientAddress} onChange={e => { this.handleClientAddressChange(e) }} id="addOrderClientAddress" name="addOrderClientAddress" placeholder="Адрес" />
                                    </Input.Group>

                                    <div style={{ display: this.state.showPriceRequest && selectedPayer == "DELIVERY_OPT_PAY_SENDER" ? "block" : "none" }}>
                                        <Button type="primary" onClick={this.handleRequestDeliveryPrice} style={{ marginTop: "5px" }}>Запросить расчет</Button>
                                        <Alert style={{ marginTop: "4px" }} showIcon type="info" message="Выбранное направление не тарифицировано. Для получения расчета нажать «Запросить расчет». Мы постараемся обновить информацию в самое ближайшее время." />
                                    </div>    

                                    <div style={{ display: this.state.priceRequestProcessing == true && selectedPayer == "DELIVERY_OPT_PAY_SENDER" ? "block" : "none" }}>
                                        <Alert style={{ marginTop: "4px" }} showIcon type="info" message="Заявка на расчет стоимости обрабатывается" />
                                    </div>

                                    <div style={{ display: this.state.showPriceRequest || this.state.priceRequestProcessing ? "none" : "block" }}>
                                        <div style={{ display: selectedDeliveryType == 4 && selectedPayer == "DELIVERY_OPT_PAY_SENDER" && this.state.intercityAllowed ? "block" : "none" }}>
                                            <Row gutter={16}>
                                                <Col span={12} style={{ textAlign: "center" }} className="gutter-row">
                                                    <span style={{ fontSize: "1.5em" }}>Стоимость&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                </Col>
                                                <Col span={12} style={{ textAlign: "center" }} className="gutter-row">
                                                    <span style={{ fontSize: "2em" }} >{this.state.intercityDeliveryPrice}р</span>
                                                </Col>
                                            </Row>
                                            <Alert style={{ marginTop: "4px", display: this.state.weightLimitReached == true ? "block" : "none" }} showIcon type="error" message="Превышен вес" />
                                            <div style={{ marginTop: "-4px" }} >
                                                <span>Вес:&nbsp;{this.state.intercityParcelWeight}</span>,&nbsp;
                                                <span>Размер:&nbsp;{this.state.intercityParcelDim}</span>&nbsp;
                                                <Button type="primary" onClick={this.handleParcelDimAndWeight} >Ввести размеры посылки</Button>
                                                &nbsp;
                                                <Select value={this.state.selectedDeliveryServiceID} onChange={e => { this.handleDeliveryServiceChange(e) }}>
                                                    <Option value="11594" >Почта России</Option>
                                                    <Option visible={ false } value="11623" >ДПД</Option>
                                                </Select>
                                                <br />
                                                <Alert style={{ marginTop: "4px" }} showIcon type="info" message="Для более точного расчета укажите габариты и вес посылки. Окончательный расчет будет при фактическом оформлении доставки менеджером." />
                                            </div>
                                        </div>

                                        <Alert style={{ marginTop: "4px", display: this.state.intercityAllowed == false && this.state.selectedDeliveryType == 4 && this.state.selectedAddOrderClientCity.length > 0 ? "block" : "none" }} showIcon type="info" message={`Для оформления доставки в ${this.state.selectedAddOrderClientCity} необходимо использовать тип доставки ПВЗ-ПВЗ или ПВЗ-Курьер`} />

                                        <Radio.Group value="DELIVERY_OPT_NEAREST_PVZ" style={{ marginTop: "4px", display: selectedDeliveryType == 4 && selectedPayer == "DELIVERY_OPT_PAY_SENDER" && this.state.intercityAllowed ? "block" : "none" }}>
                                            <Radio value="DELIVERY_OPT_NEAREST_PVZ">Доставить до ближайшего пункта выдачи</Radio>
                                            <Radio value="DELIVERY_OPT_COURIER" disabled={true}>Доставить по адресу курьером</Radio>
                                            <Radio value="DELIVERY_OPT_PVZ" disabled={true}>Доставить в указанный пункт выдачи</Radio>
                                        </Radio.Group>
                                        <Alert style={{ display: this.state.courierOrderDeliveryCityWarning ? "inline-block" : "none", marginTop: "4px" }} showIcon type="info" message={this.state.courierOrderDeliveryCityWarningText} />
                                    </div>
                                    <div style={showEmptyBlock == false ? {display:"none"} : { display: "block", minHeight: "100px" }}>
                                    </div>
                                </div>

                                <div style={{ display: this.state.addOrderNotesShow && this.state.intercityAllowed ? "block" : "none" }}>
                                    <span>Примечания:</span>&nbsp;
                                    <TextArea rows={2} id="addOrderNotes" value={this.state.addOrderNotes} onChange={e => { this.handleAddOrderNotes(e) }} name="addOrderNotes" />
                                    <Alert style={{ marginTop: "4px" }} showIcon type="info" message="В примечаниях укажите как можно больше контактов получателя: WhatsApp, Telegram (если номер отличается от номера телефона), логин VK, Instagram, e-mail, что-нибудь еще, чтобы мы могли связаться с получателем для согласования доставки." />
                                </div>
                            </Panel>

                        </Collapse>

                        <div style={{ display: this.state.addOrderPricesBlockShow ? "block" : "none", paddingTop:"10px" }}>
                            <Form.Item>
                                <Input.Group>
                                    <Input style={{ textAlign: 'right' }} addonBefore="Стоимость вложения:" addonAfter=" руб." type="text" value={itemsAmount} id="itemsAmount" name="itemsAmount" placeholder="Цена" onChange={e => this.handleFieldChange(e)} />
                                </Input.Group>
                            </Form.Item>

                            <Form.Item style={{ marginTop:"-15px" }}>
                                <Input.Group >
                                    <Input style={{ textAlign: 'right' }} addonBefore="Доставка:" addonAfter=" руб." value={deliveryAmount} type="text" id="deliveryAmount" name="deliveryAmount" placeholder="Стоимость доставки" onChange={e => this.handleFieldChange(e)} />
                                </Input.Group>
                            </Form.Item>

                            <Form.Item style={{ marginTop: "-15px" }}>
                                <Input style={{ textAlign: 'right' }} addonBefore="Итого к оплате получателем:" value={totalAmount} readOnly={true} addonAfter=" руб." type="text" id="orderAmount" name="orderAmount" placeholder="Цена" onChange={e => this.handleFieldChange(e)} />
                            </Form.Item>
                        </div>

                        <div style={showDeliveryAmountWarning ? { display: "block" } : { display: "none" }}>
                            <Alert
                                message="Предупреждение"
                                description={deliveryAmountWarning}
                                type="warning"
                                showIcon
                            />
                        </div>
                    </Form>
                </Modal>

                <Modal
                    title='Заказы клиента'
                    visible={this.state.clientOrdersShow}
                    onCancel={this.setClientOrdersShowToggle}
                    footer={[
                        <Button key="back" onClick={this.setClientOrdersShowToggle}>
                            Закрыть
                        </Button>
                    ]}
                    >

                    <div>
                        <Table
                            locale={{ emptyText: 'Нет данных' }}
                            columns={orders_cols}
                            loading={loading}
                            dataSource={orders}
                            //pagination={pagination}
                            rowKey={record => record.orderID}
                            //onChange={this.handleTableChange}
                        />
                    </div>

                </Modal>

                <h2>Управление клиентами</h2>
                <div style={{ paddingTop: 10 }}>
                    <Search placeholder="Поиск" style={{ width: '300px', paddingTop: 0 }} onSearch={e => this.onSearch(e)} enterButton allowClear />
                    <Button type="primary" title="Обновить" style={{ backgroundColor: "green" }} onClick={e => this.onSearch("")}><ReloadOutlined style={{ backgroundColor: "green" }} /></Button>
                    <Button type="primary" style={{ backgroundColor: "green", marginLeft:"1px" }} onClick={this.handleSettings}>Настройки</Button>
                    <Button type="primary" className="float-right" style={{ backgroundColor: "green" }} onClick={this.handleNewClient}>Новый клиент</Button>
                    <div>
                        <Table
                            locale={{ emptyText: 'Нет данных' }}
                            columns={cols}
                            loading={loading}
                            dataSource={clients}
                            pagination={pagination}
                            rowKey={record => record.clientID}
                            onChange={this.handleTableChange}
                        />
                    </div>
                </div>

                <Modal
                    title='Настройки'
                    visible={this.state.settingsShow}
                    onCancel={this.setSettingsShowToggle}
                    footer={[
                        <Button key="back" onClick={this.setSettingsShowToggle}>
                            Закрыть
                        </Button>
                    ]}
                >
                    <div>
                        Использовать ник:&nbsp;<Checkbox checked={this.state.showNick} onChange={e => { this.handleShowNick(e) }} ></Checkbox>
                    </div>

                    <div style={{ marginTop: "5px" }}>
                        <span title="Пункт приема посылок для отправки в другие пункты выдачи или для курьерской доставки">Город нахождения склада магазина:&nbsp;</span>
                        <Select  id="settingsShopCity" name="settingsShopCity" onChange={e => { this.handleShopCity(e) }}
                            placeholder="Населенный пункт"
                            value={this.state.selectedShopCityID}
                        >
                            {
                                cities.map((e, key) => {
                                    return <Option value={key}>{e}</Option>;
                                })
                            }
                        </Select>
                    </div>

                    <div style={{ marginTop: "5px" }}>
                        <span title="Пункт возврата посылок">ПВЗ для возврата посылок:&nbsp;</span>
                        <Select id="settingsReturnStorage" name="settingsReturnStorage" onChange={e => { this.handleReturnStorage(e) }}
                            placeholder="ПВЗ"
                            value={this.state.selectedReturnStorageID}
                        >
                            {
                                accept_points.map((e, key) => {
                                    return <Option value={e.storageID}>{e.name + " " + e.city}</Option>;
                                })
                            }
                        </Select>
                    </div>

                </Modal>

                <Modal
                    title='Размер и вес'
                    visible={this.state.showParcelDimAndWeight}
                    onCancel={() => { this.setState({ showParcelDimAndWeight: false }) }}
                    footer={[
                        <Button key="back" onClick={() => { this.setState({ showParcelDimAndWeight: false }) }}>
                            Закрыть
                        </Button>
                    ]}
                >
                    <Form.Item label="Вес">
                        <AutoComplete
                            placeholder="Вес"
                            value={this.state.intercityParcelWeight}
                            options={weightOptions}
                            onSearch={this.onSearchWeight}
                            onChange={this.onChangeWeight}
                        />
                    </Form.Item>

                    <Form.Item label="Размер">
                        <Input.Group >
                            <Input addonBefore="Ш" style={{ width: "100px" }} type="text" value={this.state.intercityParcelWidth} onChange={e => { this.setState({ intercityParcelWidth: e.target.value }, function () { this.setIntercityDim() }) }} placeholder="Ш" />
                            <Input addonBefore="В" style={{ width: "100px" }} type="text" value={this.state.intercityParcelHeight} onChange={e => { this.setState({ intercityParcelHeight: e.target.value }, function () { this.setIntercityDim() }) }} placeholder="В" />
                            <Input addonBefore="Д" style={{ width: "140px" }} addonAfter="см." type="text" value={this.state.intercityParcelLength} onChange={e => { this.setState({ intercityParcelLength: e.target.value }, function () { this.setIntercityDim() }) }} placeholder="Д" />
                        </Input.Group>
                    </Form.Item>

                </Modal>

                <Bootbox show={showConfirmDeleteClient}
                    successLabel={"Да"}
                    cancelLabel={"Нет"}
                    type={"confirm"}
                    message={"Удалить?"}
                    onSuccess={this.handleDeleteClient}
                    onCancel={this.handleConfirmDeleteNo}
                    onClose={this.handleConfirmDeleteNo}
                />

                <Bootbox show={showAlert}
                    successLabel={"OK"}
                    type={"alert"}
                    message={alertText}
                    onClose={this.handleAlertClose}
                />
            </div>
        );
    }
}

export default ManageClients;
