import React, { Component, PropTypes, createRef } from 'react';
import authService from './api-authorization/AuthorizeService'
import ReactDOM from 'react-dom';
import { Tabs, Table, Input, Button, Modal, Select, Form, Checkbox, Popconfirm, Spin, DatePicker } from 'antd'
import reqwest from 'reqwest'
import Parser from 'html-react-parser';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { param } from 'jquery';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const getPaymentsParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        search: params.search,
        ...params,
    };
};

const getRegistryParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        search: params.search,
        registry_from: params.registry_from,
        registry_to: params.registry_to,
        ...params,
    };
};

export class Accounting extends Component {
    displayName = "Расчёты";

    constructor(props) {
        super(props);

        this.clickHandler = this.clickHandler.bind(this);
        this.clickReport = this.clickReport.bind(this);
        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.handleRegistryDatesChange = this.handleRegistryDatesChange.bind(this);
        this.poTypeChanged = this.poTypeChanged.bind(this);
        this.handleCashOnly = this.handleCashOnly.bind(this);
        this.requestPayout = this.requestPayout.bind(this);
        this.modeChanged = this.modeChanged.bind(this);
        this.handlePaymentsTableChange = this.handlePaymentsTableChange.bind(this);
        this.handleRegistryTableChange = this.handleRegistryTableChange.bind(this);

        this.state = {
            isAuthenticated: false,
            loading: false,
            accounting_table: "",
            date_from: "",
            report_from: "",
            report_to: "",
            registry_from: "",
            registry_to: "",
            requestPayoutShow: false,
            poType: "CARD",
            cardNumber: "",
            cardHolder: "",
            cardComment: "",
            cashPlace: "",
            cashComment: "",
            bankComment: "",
            payoutAmount: 0,
            mode: 1,
            paymens: [],
            registry: [],
            cash_only: true,
            pagination: {
                current: 1,
                pageSize: 20,
                position: ['topRight', 'bottomRight'],
                showQuickJumper: true,
                showSizeChanger: true,
                defaultPageSize: 20
            },
            registry_pagination: {
                current: 1,
                pageSize: 20,
                position: ['topRight', 'bottomRight'],
                showQuickJumper: true,
                showSizeChanger: true,
                defaultPageSize: 20
            },
            userBalance: null,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated
        });

        if (isAuthenticated) {
            this.fetchPayouts();
            this.getBalance();
        }
        else {
            this.redirectToLogin();
        }
    }

    modeChanged(e) {

        if (this.state.mode != e) {

            if (e == 1) {
                this.fetchPayouts();
            }

            if (e == 2) {
                const { pagination, cash_only } = this.state;
                const search = "";
                this.fetchPayments({ pagination, search, cash_only });
            }

            if (e == 3) {
                const { registry_pagination } = this.state;
                var pagination = registry_pagination;
                const search = "";
                const registry_from = "";
                const registry_to = "";
                this.fetchRegistry({ pagination, search, registry_from, registry_to });
            }
        }

        this.setState({ mode: e });
    }

    async getBalance() {

        var that = this;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'storage/get_balance',
                method: 'get',
                type: 'json',
                data: {},
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {

                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                if (data != null) {
                    var bl = parseFloat(data);
                    if (!isNaN(bl)) {
                        that.setState({
                            userBalance: bl,
                        }, function () {
                        });
                    }
                }
            });
        }
    }

    async fetchPayments(params) {

        this.setState({ loading: true });

        var that = this;
        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: '/accounting/payments',
                method: 'get',
                type: 'json',
                data: getPaymentsParams(params),
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {
                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {
                this.setState({
                    loading: false,
                    payments: data.payments,
                    pagination: {
                        ...params.pagination,
                        total: data.totalCount,
                    },
                });

            });
        }

    }

    async fetchRegistry(params) {

        this.setState({ loading: true });

        var that = this;
        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: '/accounting/registry',
                method: 'get',
                type: 'json',
                data: getRegistryParams(params),
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {
                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {
                this.setState({
                    loading: false,
                    registry: data.registry,
                    registry_pagination: {
                        ...params.pagination,
                        total: data.totalCount,
                    },
                });
            });
        }

    }


    async fetchPayouts() {

        var that = this;
        const token = await authService.getAccessToken();
        if (token.length > 0) {
            this.setState({ loading: true });
            reqwest({
                url: 'accounting/payouts?dt_from=' + this.state.date_from,
                method: 'get',
                type: 'json',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {
                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                this.setState({
                    accounting_table: data.table,
                    loading: false
                });

            });
        }
        else {
            this.redirectToLogin();
        }
    }

    clickPrevMonth(dt) {
        alert(dt);
    }

    clickNextMonth(dt) {
        alert(dt);
    }

    redirectToLogin() {
        this.props.history.push("/authentication/login");
    }

    poTypeChanged(e) {

        if (e.currentTarget.value != null) {
            this.setState({ poType: e.currentTarget.value });
        }
    }

    clickHandler(e) {
        e.preventDefault();
        const el = e.target.closest("A");
        if (el && e.currentTarget.contains(el)) {
            if (el.id == "prev_month" || el.id == "next_month") {
                var dt = el.getAttribute("data-day");
                this.setState({ date_from: dt }, function () {
                    this.fetchPayouts();
                });
            }
        }

        const el1 = e.target.closest("BUTTON");
        if (el1 && e.currentTarget.contains(el1)) {
            if (el1.id == "confirm_payout") {

                var details = el1.getAttribute("data-details");
                var amount = el1.getAttribute("data-amount");
                details = details.replace(new RegExp("<br/>", 'g'), "\n");

                if (window.confirm("Подтверждаете получение суммы: " + amount + "?\n" + details)) {
                    this.confirmPayout(amount);
                }
            }

            if (el1.id == "request_payout_view") {
                alert(el1.getAttribute("data-details"));
            }

            if (el1.id == "request_payout") {

                var amount = e.target.getAttribute("data-amount").replace(",", ".");

                this.setState({ requestPayoutShow: true, payoutAmount: amount });

            }
        }
    }


    handleRegistryDatesChange(dates, dateStrings) {

        if (dateStrings.length > 1) {
            this.setState({ registry_from: dateStrings[0], registry_to: dateStrings[1] }, function () {

                const { registry_pagination, registry_from, registry_to } = this.state;
                var pagination = registry_pagination;
                const search = "";
                this.fetchRegistry({ pagination, search, registry_from, registry_to });
            });
        }
    }

    handleDatesChange(dates, dateStrings) {

        if (dateStrings.length > 1) {
            this.setState({ report_from: dateStrings[0], report_to: dateStrings[1] });
        }
    }

    async confirmPayout(amount) {
        var that = this;
        const token = await authService.getAccessToken();
        if (token.length > 0) {
            this.setState({ loading: true });

            var cpo_data = {
                amount: amount
            };

            fetch('accounting/confirm_payout',
                {
                    method: "POST",
                    body: JSON.stringify(cpo_data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {

                    this.setState({ loading: false }, function () {

                        if (data.length > 0) {
                            var po = JSON.parse(data);
                            if (po.error == "OK") {
                                that.fetchPayouts();
                            }
                            else {
                                alert(po.error);
                            }
                        }
                    });
                });
        }
    }

    async requestPayout(e) {
        e.preventDefault();

        var that = this;
        var amount = this.state.payoutAmount;

        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var rpo_data = {
                poType: this.state.poType,
                cardNumber: this.state.cardNumber,
                cardHolder: this.state.cardHolder,
                cardComment: this.state.cardComment,
                cashPlace: this.state.cashPlace,
                cashComment: this.state.cashComment,
                bankComment: this.state.bankComment,
                amount: amount
            };

            this.setState({ loading: true, requestPayoutShow: false });

            fetch('accounting/request_payout',
                {
                    method: "POST",
                    body: JSON.stringify(rpo_data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {

                    this.setState({ loading: false }, function () {

                        if (data.length > 0) {
                            var po = JSON.parse(data);
                            if (po.error == "OK") {
                                that.fetchPayouts();
                            }
                            else {
                                alert(po.error);
                            }
                        }
                    });
                });
        }
    }

    async clickReport(e) {
        e.preventDefault();

        const token = await authService.getAccessToken();
        if (token.length > 0) {

            this.setState({ loading: true });

            fetch('orders/orders_report?dt_from=' + this.state.report_from + "&dt_to=" + this.state.report_to, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/xlsx',
                    'Authorization': `Bearer ${token}`
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `orders.xlsx`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);

                    this.setState({ loading: false });

                });
        }
    }

    handleCashOnly(e) {
        var that = this;
        this.setState({ cash_only: e.target.checked }, function () {

            const { pagination, cash_only } = that.state;
            const search = "";
            that.fetchPayments({ pagination, search, cash_only });

        });
    }

    handlePaymentsTableChange(pagination, filters, sorter) {

        const search = this.state.search;
        const cash_only = this.state.cash_only;
        this.fetchPayments({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            search,
            cash_only,
            ...filters,
        });
    }

    handleRegistryTableChange(pagination, filters, sorter) {

        const search = this.state.search;
        const registry_from = this.state.registry_from;
        const registry_to = this.state.registry_to;
        this.fetchRegistry({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            search,
            registry_from,
            registry_to,
            ...filters,
        });
    }

    renderNotes(record) {
        if (record.orderID > 0) {
            return (<a target="_blank" href={`/manage-orders/parceldetails/${record.orderID}`}>{record.notes}</a>)
        }
        else {
            return (record.notes);
        }
    }

    registryItemsRender = (data) => {
        const columns = [
            {
                title: '№ заказа',
                dataIndex: 'orderID',
            },
            {
                title: 'Сумма',
                dataIndex: 'amount',
                align: 'right',
            },
        ];

        return <Table columns={columns} dataSource={data} pagination={false} />;
    }


    render() {

        //var accounting_table = Parser(this.state.accounting_table);

        this.payments_cols = [
            {
                title: 'Дата',
                dataIndex: 'time',
            },
            {
                title: "Приход",
                dataIndex: 'income',
                align: 'right',
            },
            {
                title: "Расход",
                dataIndex: 'outcome',
                align: 'right',
            },
            {
                title: "Баланс",
                dataIndex: 'balance',
                align: 'right',
            },
            {
                title: "",
                dataIndex: 'notes',
                render: (text, record) => (this.renderNotes(record))
            },
        ];

        this.registry_cols = [
            {
                title: 'Дата',
                dataIndex: 'date',
            },
            {
                title: "№ реестра",
                dataIndex: 'registryNo',
                align: 'left',
            },
            {
                title: "Кол-во",
                dataIndex: 'itemsCount',
                align: 'right',
            },
            {
                title: "Сумма",
                dataIndex: 'itemsAmount',
                align: 'right',
            },
        ];

        return (
            <div>
                <h2>{this.displayName}</h2>

                &nbsp;<span>Баланс Л/C:&nbsp;{this.state.userBalance}</span>

                <Tabs defaultActiveKey="1" onChange={this.modeChanged} activeKey={this.state.mode.toString()}>
                    <TabPane tab="Расчёты" key="1" style={{ minHeight: "400px" }}>

                        <div>

                            <div>
                                <RangePicker locale={locale} onChange={(dates, dateStrings) => this.handleDatesChange(dates, dateStrings)} /> <Button type='primary' onClick={this.clickReport} >Отчет</Button>
                                <Spin spinning={this.state.loading}>
                                    <div onClick={this.clickHandler} dangerouslySetInnerHTML={{ __html: this.state.accounting_table }}>
                                    </div>
                                </Spin>
                            </div>

                            <Modal
                                visible={this.state.requestPayoutShow}
                                title='Запросить выплату'
                                onCancel={e => { this.setState({ requestPayoutShow: false }) }}
                                footer={[
                                    <Button key="back" onClick={e => { this.setState({ requestPayoutShow: false }) }}>
                                        Закрыть
                        </Button>,
                                    <Button key="submit" type="primary" onClick={this.requestPayout}>
                                        Запросить
                        </Button>
                                ]}
                            >
                                <Form layout="vertical">
                                    <table className='table table-condensed'>
                                        <tr cellpadding="4">
                                            <td>
                                                <Form.Item label="На карту:">
                                                    <Input type="radio" name="RB_PAY" value="CARD" defaultChecked onChange={e => this.poTypeChanged(e)} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item label="№ карты">
                                                    <Input type="text" value={this.state.cardNumber} onChange={e => { this.setState({ cardNumber: e.currentTarget.value }) }} />
                                                </Form.Item>
                                                <Form.Item label="ФИО получателя">
                                                    <Input type="text" value={this.state.cardHolder} onChange={e => { this.setState({ cardHolder: e.currentTarget.value }) }} />
                                                </Form.Item>
                                                <Form.Item label="Комментарий">
                                                    <Input type="text" value={this.state.cardComment} onChange={e => { this.setState({ cardComment: e.currentTarget.value }) }} />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr style={{ borderTop: "1px solid #cccccc" }}>
                                            <td>
                                                <Form.Item label="Наличные:">
                                                    <Input type="radio" name="RB_PAY" value="CASH" onChange={e => this.poTypeChanged(e)} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item label="Место получения">
                                                    <Input type="text" value={this.state.cashPlace} onChange={e => { this.setState({ cashPlace: e.currentTarget.value }) }} />
                                                </Form.Item>
                                                <Form.Item label="Комментарий">
                                                    <Input type="text" value={this.state.cashComment} onChange={e => { this.setState({ cashComment: e.currentTarget.value }) }} />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                        <tr style={{ borderTop: "1px solid #cccccc" }}>
                                            <td>
                                                <Form.Item label="Банковский первод:">
                                                    <Input disabled={true} type="radio" name="RB_PAY" value="BANK" onChange={e => this.poTypeChanged(e)} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item label="Комментарий">
                                                    <Input type="text" value={this.state.bankComment} onChange={e => { this.setState({ bankComment: e.currentTarget.value }) }} />
                                                </Form.Item>
                                            </td>
                                        </tr>
                                    </table>

                                </Form>
                            </Modal>
                        </div>
                    </TabPane>
                    <TabPane tab="Журнал операций" key="2">
                        <div>
                            <Checkbox checked={this.state.cash_only ? true : false} onChange={e => { this.handleCashOnly(e) }} />&nbsp;Только кассовые операции
                            <Table
                                style={{ marginTop: 10 }}
                                locale={{ emptyText: 'Нет данных' }}
                                columns={this.payments_cols}
                                loading={this.state.loading}
                                dataSource={this.state.payments}
                                pagination={this.state.pagination}
                                rowKey={record => record.paymentID}
                                onChange={this.handlePaymentsTableChange}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Реестр приёмок" key="3">
                        <div>
                            <RangePicker locale={locale} onChange={(dates, dateStrings) => this.handleRegistryDatesChange(dates, dateStrings)} />

                            <Table
                                style={{ marginTop: 10 }}
                                locale={{ emptyText: 'Нет данных' }}
                                columns={this.registry_cols}
                                loading={this.state.loading}
                                dataSource={this.state.registry}
                                pagination={this.state.registry_pagination}
                                rowKey={record => record.registryID}
                                onChange={this.handleRegistryTableChange}
                                expandedRowRender={record => this.registryItemsRender(record.items)}
                            />
                        </div>
                    </TabPane>
                </Tabs>

            </div>
        );
    }
}

export default Accounting;
