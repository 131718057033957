import React, { Component, PropTypes } from 'react';
import { Table, Input, Button, Modal, Form, Select, Alert, Popconfirm, Badge, Checkbox, Radio, Collapse, AutoComplete, Row, Col } from 'antd'
import { dataContext, contextData } from './DataContext';
import authService from './api-authorization/AuthorizeService'
import reqwest from 'reqwest'
import Bootbox from 'bootbox-react';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const MODE_PARCELS = 0,
    MODE_SALE = 1,
    MODE_STORAGE = 2;

const getOrdersParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        search: params.search,
        ...params,
    };
};

export class ManageStickers extends Component {

    static displayName = "Ценники и стикеры";

    constructor(props) {
        super(props);

        this.handleShopChange = this.handleShopChange.bind(this);
        this.handleSelectAllOrders = this.handleSelectAllOrders.bind(this);
        this.clickStickers = this.clickStickers.bind(this);
        this.handleAcceptedChange = this.handleAcceptedChange.bind(this);

        this.displayName = "Ценники и стикеры";
        var mode = 1;

        this.state = {
            loading: false,
            shops: [{ "shopID": -1, "name": "Выберите Магазин" }],
            shopID: -1,
            parcelsMode: MODE_PARCELS,
            mode: mode,
            allChecked: false,
            allCheckedVisible: true,
            stickersVisible: true,
            stickersDisabled: true,
            accepted: false,
            stickersLabel: "Скачать наклейки",
            pagination: {
                current: 1,
                pageSize: 20,
                position: ['topRight', 'bottomRight'],
                showQuickJumper: true,
                showSizeChanger: true,
                defaultPageSize: 20
            },
            showNick: true
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated,
        });

        if (isAuthenticated) {
            this.fetchShops();
        }
        else {
            this.redirectToLogin();
        }
    }

    redirectToLogin() {

        this.props.history.push("/authentication/login");
    }

    async fetchShops(cb) {
        const token = await authService.getAccessToken();
        if (token.length > 0) {

            reqwest({
                url: 'orders/shops?accepted=' + (this.state.accepted ? "1" : "0"),
                method: 'get',
                type: 'json',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(data => {

                var pts = data;

                pts.splice(0, 0, { "shopID": -1, "name": "Выберите Магазин" });

                this.setState({
                    shops: pts
                }, function () {
                    if (cb != null) {
                        cb();
                    }
                });
            });
        }
        else {
            this.redirectToLogin();
        }
    }

    handleShopChange(e) {

        var stickersLabel = "Скачать наклейки";

        if (this.state.parcelsMode == MODE_SALE) {
            stickersLabel = "Скачать ценники";
        }

        this.setState({ shopID: e, stickersLabel: stickersLabel }, function () {

            const { pagination, mode, shopID, accepted } = this.state;
            const search = "";

            this.fetchOrders({ pagination, search, mode, shopID, accepted });
        });
    }

    handleAcceptedChange(e) {

        var that = this;
        this.setState({ accepted: e.target.checked }, function () {

            this.fetchShops(function () {

                const { pagination, mode, shopID, accepted } = that.state;
                const search = "";

                that.fetchOrders({ pagination, search, mode, shopID, accepted });

            });
        });
    }

    async fetchOrders(params) {

        this.setState({ loading: true, allChecked: false });

        var that = this;

        params.parcelsMode = this.state.parcelsMode;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: params.accepted == true ? 'orders/accepted_orders' : 'orders/unaccepted_orders',
                method: 'get',
                type: 'json',
                data: getOrdersParams(params),
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {
                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {
                this.setState({
                    allChecked: false,
                    loading: false,
                    orders: data.results,
                    pagination: {
                        ...params.pagination,
                        total: data.totalCount,
                    },
                });
            });
        }
    }

    selectionCount() {
        var cnt = 0;
        this.state.orders.forEach(function (el) {
            if (el.checked) {
                cnt++;
            }
        });

        return cnt;
    }

    handleSelectOrder(record, e) {
        if (e.target.checked) {
            record.checked = true;
        }
        else {
            record.checked = false;
        }

        const { orders, mode } = this.state;

        this.setState({ orders: orders, stickersDisabled: this.selectionCount() == 0 });
    }

    handleSelectAllOrders = e => {

        if (e.target.checked) {

            this.state.orders.forEach(function (el) {
                el.checked = true;
            });
        }
        else {

            this.state.orders.forEach(function (el) {
                el.checked = false;
            });

        }

        const { orders, mode } = this.state;

        this.setState({ orders: orders, allChecked: e.target.checked, stickersDisabled: this.selectionCount() == 0  }, function () {
            this.setState({ orders: orders, allChecked: e.target.checked });
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        const search = this.state.search;
        const { mode, shopID, accepted } = this.state;

        this.fetchOrders({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            search,
            mode,
            shopID,
            accepted,
            ...filters,
        });
    };

    async clickStickers(e) {
        e.preventDefault();

        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var ids = new Array();
            this.state.orders.forEach(function (el) {
                if (el.checked) {
                    ids.push(el.orderID);
                }
            });

            if (ids.length > 0) {

                this.state.orders.forEach(function (el) {
                    el.checked = false;
                });

                const { orders } = this.state;

                this.setState({ loading: true, allChecked: false, orders: orders, stickersDisabled: true });

                fetch('orders/stickers?ids=' + ids.join(',') + "&shopId=" + this.state.shopID, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf',
                        'Authorization': `Bearer ${token}`
                    },
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                            new Blob([blob]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            `Stickers.pdf`,
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);

                        this.setState({ loading: false });

                    });
            }
        }
    }


    render() {

        this.columns = [
            {
                title: '№ заказа',
                dataIndex: 'orderID',
                //render: (text, record) => (this.renderOrderDetailsLink(text, record))
            },
            {
                title: this.state.parcelsMode == MODE_PARCELS ? 'Клиент' : 'Товар',
                dataIndex: 'clientName',
                align: 'left',
            },
            {
                title: '',
                dataIndex: 'clientNick',
                width: this.state.showNick ? 70 : 0,
                render: (text, record) => (this.state.showNick ? record.clientNick : "")
            },
            {
                title: 'Сумма',
                dataIndex: 'totalAmount',
                align: 'right',
            },
            {
                title: <span title="Пункт Выдачи Заказов">ПВЗ</span>,
                dataIndex: 'giveoutStorageName',
                align: 'right',
            },
            {
                title: <Checkbox className="select-all-orders" style={{ display: this.state.allCheckedVisible ? "block" : "none" }} checked={this.state.allChecked} name="selectAllOrders" id="selectAllOrders" onChange={this.handleSelectAllOrders}></Checkbox>,
                render: (text, record) => (<Checkbox className="select-order" style={{ display: record.selectable ? "block" : "none" }} checked={record.checked ? true : false} value={record.orderID} onChange={(e) => this.handleSelectOrder(record, e)}></Checkbox >),
                align: 'center',
            },
        ];


        return (
            <div>
                <h4>{this.displayName}</h4>
                <span>Магазин:</span>&nbsp;
                <Select style={{ minWidth: "150px" }} value={this.state.shopID} onChange={e => { this.handleShopChange(e) }}>
                    {
                        this.state.shops.map((e, key) => {
                            return <Option value={e.shopID}>{e.name}</Option>;
                        })
                    }
                </Select>&nbsp;
                <Radio.Group value={this.state.parcelsMode} onChange={(e) => { this.setState({ parcelsMode: e.target.value }, function () { this.handleShopChange(this.state.shopID) }) }}>
                    <Radio value={MODE_PARCELS}>Посылки</Radio>&nbsp;&nbsp;
                    <Radio value={MODE_SALE}>Продажи</Radio>
                    <Radio value={MODE_STORAGE}>Аренда</Radio>
                </Radio.Group>&nbsp;
                <Checkbox checked={this.state.accepted} onChange={(e) => { this.handleAcceptedChange(e) }}>Принятые</Checkbox>
                <br />
                <Button type="primary" onClick={this.clickStickers} style={{ display: this.state.stickersVisible ? "inline" : "none", marginTop:"5px" }} disabled={this.state.stickersDisabled} >{this.state.stickersLabel}</Button>
                <Table
                    style={{ marginTop: 10 }}
                    locale={{ emptyText: 'Нет данных' }}
                    columns={this.columns}
                    loading={this.state.loading}
                    dataSource={this.state.orders}
                    pagination={this.state.pagination}
                    rowKey={record => record.orderID}
                    onChange={this.handleTableChange}
                />

            </div>
        );

    }

}

export default ManageStickers;
