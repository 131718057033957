import React, { Component } from 'react';
import { Collapse, BackTop, Table, Row, Col, Button, Modal, Form, Input, Select, Carousel } from 'antd'
import { FastBackwardFilled } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        var contacts = false;

        if (this.props.location.pathname.indexOf("/contacts") >= 0) {
            contacts = true;
        }

        this.state = {
            showModal: false,
            queryText: "",
            queryContact: "",
            queryTheme: "Выдача заказов для Интернет-магазинов",
            contacts: contacts
        };

        this.setModalToggle = this.setModalToggle.bind(this);
        this.sendQuery = this.sendQuery.bind(this);
        this.handleQueryText = this.handleQueryText.bind(this);
        this.handleQueryContact = this.handleQueryContact.bind(this);
        this.handleQueryTheme = this.handleQueryTheme.bind(this);

    }

    setModalToggle(e) {
        e.preventDefault();

        var r = !this.state.showModal;
        this.setState({ showModal: r });
    }

    async sendQuery() {
        this.setState({ showModal: false });

        const { queryTheme, queryText, queryContact } = this.state;

        var query_data = { theme: queryTheme, text: queryText, contact: queryContact };

        await fetch('home/save_query',
            {
                method: "POST",
                body: JSON.stringify(query_data),
                headers: {
                    'Content-Type': 'application/json',
                }
            }).
            then(response => response.text())
            .then(data => {
                var cl = JSON.parse(data);
                if (cl != null) {
                    if (cl.message.length > 0) {
                        alert(cl.message);
                    }
                }
            });
    }

    handleQueryText(e) {
        this.setState({ queryText: e.target.value });
    }

    handleQueryContact(e) {
        this.setState({ queryContact: e.target.value });
    }

    handleQueryTheme(e) {
        this.setState({ queryTheme: e });
    }


    render() {
        const carouselStyle = {
            //height: '160px',
            color: 'black',
            //lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };


        return (
            <div>
                <div style={{ display: this.state.contacts == true ? "none" : "block" }}>
                    <Row>
                        <Col span={24} className='mp-block'>
                            <b>Стеллажи</b> – система управления пунктами выдачи, курьерской доставкой и сопутствующими продажами.<br />
                        </Col>
                    </Row>
               {/*

                <Row>
                    <Col span={24} style={{ marginTop: 5, marginBottom: 5 }} className='mp-block'>
                        <Carousel autoplay autoplaySpeed={10000}>
                            <div>
                                <img style={carouselStyle} alt='Image' src="/images/carousel/1.jpg" />
                            </div>
                            <div>
                                <img style={carouselStyle} alt='Image' src="/images/carousel/2.jpg" />
                            </div>
                            <div>
                                <img style={carouselStyle} alt='Image' src="/images/carousel/3.jpg" />
                            </div>
                            <div>
                                <img style={carouselStyle} alt='Image' src="/images/carousel/4.jpg" />
                            </div>
                            <div>
                                <img style={carouselStyle} alt='Image' src="/images/carousel/5.jpg" />
                            </div>
                            <div>
                                <img style={carouselStyle} alt='Image' src="/images/carousel/6.jpg" />
                            </div>
                        </Carousel>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h4 id="buyers">Покупателям</h4>
                    </Col>
                    <Col span={24} className='mp-block'>
                        Отследить свою посылку<br />
                        Заказать курьерскую доставку посылки, находящейся в одном из наших пунктов выдачи<br />
                        Отправить посылку через пункт выдачи или курьером
                </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        &nbsp;
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <h4 id="sellers">Продавцам</h4>
                    </Col>
                    <Col span={24} className='mp-block'>
                        Удобный интерфейс для оформления посылок на отправку.<br />
                        Полная статистика и отчеты по отправкам, расчетам с покупателями и оплате услуг доставки.<br />
                        Оперативная информация о месте нахождения посылки на всем ее пути следования до адресата.<br />
                        Услуги ответственного хранения и фулфилмента (ваш товар хранится у нас, при заказе мы формируем посылку и доставляем по указанному адресу).
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        &nbsp;
                    </Col>
                </Row>

                */}

                    <Row>
                        <Col span={24}>
                            <h4 id="shops">Для магазинов</h4>
                        </Col>
                        <Col span={24} className='mp-block'>
                            Система учета движения товаров. Особенно актуально для комиссионного товара или товаров HandMade.<br/>
                            Полный учет и прозрачность перемещения, продажи и расчетов. Магазины, подключившиеся к Системе, получают дополнительную возможность заработка как пункт выдачи заказов.
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <h4 id="owners">Владельцам ПВЗ и курьерским службам</h4>
                        </Col>
                        <Col span={24} className='mp-block'>
                            Предлагаем сотрудничество. Взаимовыгодное расширение географии доставки.
                            <br/>
                            Мы готовы выдавать и доставлять посылки ваших клиентов и передавать вам посылки наших клиентов
                            для выдачи в пунктах и для доставки курьерами.
                            <br />
                            Использование системы Стеллажи для автоматизации работы пункта выдачи заказов.
                            <br />
                            Работа с федеральными службами доставки в одном интерфейсе.
                            Полноценно интегрированы DPD, OZON, PickPoint, BoxBerry, B2Cpl... список пополняется.
                            <br />
                            И пункты выдачи, и курьерская доставка позволяет работать как с заказами частных лиц,
                            так и с местными интернет магазинами и организаторами Совместных Покупок.
                            <br />
                            Учет продаж как единичного товара, так и номенклатуры товаров.
                            <br />
                            Касса предприятия. Работа с ККМ и банковскими эквайринговыми терминалами.
                            <br />
                            Полная автоматизация процесса!
                            <br />
                            <br />
                            <Collapse defaultActiveKey={['0']}>
                                <Panel header="Как это работает" key="1">
                                    <p>
                                        <b>Для оффлайн-магазина.</b>
                                        <br />
                                        Для начала работы необходимо зарегистрироваться в Системе и запросить права Владельца оффлайн-магазина/пункта выдачи заказов. После получения доступа войти как Сотрудник, создать в Системе свой магазин/ПВЗ, разместить товары или заказы и можно начинать работать.
                                        <br/>
                                        <b>Для продавца.</b>
                                        <br />
                                        Для начала работы необходимо зарегистрироваться в Системе и запросить права Владельца интернет-магазина (даже если у Вас нет представительства в интернет, нет юридического оформления и прочего, работать все равно можно). После получения доступа необходимо обратиться в интересующие Вас точки продаж через контакты указанные в Системе и запросить разрешение на сотрудничество.
                                        После получения согласия можно оформлять свои товары для продажи или заказы для выдачи своим клиентам.
                                    </p>
                                </Panel>
                                <Panel header="Тарифы" key="2">
                                    <p>
                                        <b>Для оффлайн-магазина.</b>
                                        <br />
                                        При сотрудничестве с интернет-магазином, который выставляет товары для продажи в точках продаж Владельца Системы
                                        (т.е. при продаже товаров в ПВЗ по адресу ул. Луговая, 63) использование Системы бесплатно.
                                        В противном случае взимается плата в размере 5 рублей за каждый проданый товар, но не менее 100р в месяц за каждого продавца.
                                        <br />
                                        <b>Для пункта выдачи заказов (ПВЗ).</b>
                                        <br />
                                        При использовани Системы для выдачи посылок взимается плата за каждую принятую для выдачи посылку в размере 5 рублей,
                                        но не менее 500р в месяц за каждый ПВЗ.
                                        <br />
                                        <b>Для интернет-магазина.</b>
                                        <br />
                                        Условия продажи оговариваются напрямую с владельцем оффлайн-магазина. Обычно это процент от продажи.
                                        За испльзование Системы оплата не взимается.
                                        Условия выдачи посылок оговариваются напрямую с владельцем офлайн-магазина. Обычно это фиксированная сумма, которую оплачивает получатель посылки в момент выдачи.
                                    </p>
                                </Panel>
                                <Panel header="Новости" key="3">
                                    <p>
                                        <b>1/10/2022</b>
                                        <br/>
                                        Уведомление на WhatsApp продавца о реализации товара.
                                        Для получения уведомлений о продаже Вашего товара необходимо в своем профиле корректно указать номер телефона,
                                        на котором установлен WhatsApp. В момент продажи Вы получите сообщение. Сделать заявку на выплату можно на следующий день.
                                        <br />
                                        <br />
                                        <b>20/09/2022</b>
                                        <br />
                                        Луговая - Стеллажи
                                        По посылкам, сданным на склад для выдачи, начиная с 1/10/2022г вводится платное хранение. Бесплатно посылка хранится на складе 7 дней.
                                        Начиная с 8го будет взиматься оплата 5р за каждые полные или не полные сутки хранения.
                                        Посылки, поступившие на склад до 1/10 хранятся бесплатно.
                                        Стоимость выдачи не изменяется. Т.е. за 50р можно будет получить посылку только в первые 7 дней после ее поступления на склад.
                                        Пожалуйста, уведомите своих получателей.
                                        Оплатить хранение будет необходимо и при возврате посылки отправителю. Предельный срок хранения 180 суток.
                                    </p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>

                <Row>
                    <Col span={24}>
                        &nbsp;
                    </Col>
                </Row>
                </div>

                <Row>
                    <Col span={24} className='mp-block'>
                        Есть вопросы и/или пожелания? Обращайтесь:<br />
                        <Row>
                            <Col span={9} >
                            </Col>
                            <Col span={6} style={{ textAlign: "center" }} >
                                <a target="_blank" href="https://wa.me/79024830700"><img style={{ width:64}} alt='WA' src="/images/logo WA.png"/></a>&nbsp;
                                <a target="_blank" href="https://t.me/stellazhi_net"><img style={{ width: 58 }} alt='TLG' src='/images/logo Telegram.png' /></a>&nbsp;
                                <a href="#" onClick={(e) => this.setModalToggle(e)} ><img alt='EML' style={{ width: 58 }} src='/images/logo eMail.png' /></a>
                            </Col>
                            <Col span={9} >
                                Помните: мы во Владивостоке. Время мск+7. Т.е. когда в Москве обед (14 часов) - у нас ужин (21 час), а потом мы спим...
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <br />

                <Modal
                    visible={this.state.showModal}
                    title="Задать вопрос"
                    footer={[
                        <Button key="back" onClick={this.setModalToggle}>
                            Закрыть
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.sendQuery}>
                            Сохранить
                        </Button>
                    ]}
                    onOk={this.sendQuery}
                    onCancel={this.setModalToggle}>
                    <Form layout="vertical">
                        <Form.Item label="Тема">
                            <Select id="queryTheme" name="queryTheme" value={this.state.queryTheme} onChange={e => { this.handleQueryTheme(e) }}>
                                <Option value={"Выдача заказов для Интернет-магазинов"}>Выдача заказов для Интернет-магазинов</Option>;
                                <Option value={"Автоматизация ПВЗ"}>Автоматизация ПВЗ</Option>;
                                <Option value={"Другое"}></Option>;
                            </Select>
                        </Form.Item>
                        <Form.Item label="Вопрос">
                            <TextArea rows={5} id="queryText" name="queryText" placeholder="Вопрос" onChange={e => this.handleQueryText(e)} />
                        </Form.Item>
                        <Form.Item label="Контактные данные">
                            <Input type="text" id="queryContact" name="queryContact" placeholder="Телефон, WhatsApp, Telegram, E-mail" onChange={e => this.handleQueryContact(e)} />
                        </Form.Item>
                    </Form>
                </Modal>

                <BackTop />
            </div>
        );
    }

    render1() {

        return (
            <div>
                <div style={{ zIndex:-1000, position: "absolute", left: 0, right: 0, top: 150, bottom: 0, backgroundImage: `url(${require("./images/Logo-site.png")})`, backgroundRepeat: "no-repeat", backgroundPosition: "center center", opacity: 0.08, }}
                >
                </div>

                <div>
                    <Row>
                        <Col span={24} style={{ fontSize:"1.2em" }}>
                            Друзья!<br/>
                            Мы постоянно работаем над улучшением и усовершенствованием Системы. В будущем году планируются существенные изменения и начать мы решили прямо сегодня!<br /><br />
                            1.	Владельцы, администраторы, операторы и курьеры пункта выдачи переходят на пункт меню «Управление ПВЗ»<br />
                            2.	Владельцы интернет-магазинов входят в систему через пункт меню «Вход». Логин и пароль в Системе сохраняется.<br />
                            3.	Для получателей посылок пункт меню «Отследить посылку»<br/><br/>
                            <div style={{float:"right"}}>23.12.2020</div>
                            <br /><br />
                            Есть вопросы и/или пожелания пишите на WhatsApp +7 902 483 07 00 <br/> или просто кликните по ссылке <a target="_blank" href="https://wa.me/79024830700">https://wa.me/79024830700</a>
                            <br />
                            Также можете написать <a href="#" onClick={this.setModalToggle} >здесь</a>
                        </Col>
                    </Row>
                </div>

                <Modal
                    visible={this.state.showModal}
                    title="Задать вопрос"
                    footer={[
                        <Button key="back" onClick={this.setModalToggle}>
                            Закрыть
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.sendQuery}>
                            Сохранить
                        </Button>
                    ]}
                    onOk={this.sendQuery}
                    onCancel={this.setModalToggle}>
                    <Form layout="vertical">
                        <Form.Item label="Тема">
                            <Select id="queryTheme" name="queryTheme" value={this.state.queryTheme} onChange={e => { this.handleQueryTheme(e) }}>
                                <Option value={"Выдача заказов для Интернет-магазинов"}>Выдача заказов для Интернет-магазинов</Option>;
                                <Option value={"Автоматизация ПВЗ"}>Автоматизация ПВЗ</Option>;
                                <Option value={"Другое"}></Option>;
                            </Select>
                        </Form.Item>
                        <Form.Item label="Вопрос">
                            <TextArea rows={5} id="queryText" name="queryText" placeholder="Вопрос" onChange={e => this.handleQueryText(e)} />
                        </Form.Item>
                        <Form.Item label="Контактные данные">
                            <Input type="text" id="queryContact" name="queryContact" placeholder="Контактные данные" onChange={e => this.handleQueryContact(e)} />
                        </Form.Item>
                    </Form>
                </Modal>

            </div>
        );
  }
}
