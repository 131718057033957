import React, { Component, PropTypes, useRef, useState } from 'react';
import { dataContext, contextData } from './DataContext';
import { Layout, Button, Input, Form, Spin, message } from 'antd'
import ReactTooltip from 'react-tooltip';
import withComponentHooks from 'with-component-hooks'
import GoogleMapReact from 'google-map-react';
import useSupercluster from "use-supercluster";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import authService from './api-authorization/AuthorizeService'
import reqwest from 'reqwest'

//import Layout from 'antd/lib/layout/layout';

var mapRef = null;
var mapsRef = null;

const { Header, Footer, Sider, Content } = Layout;

const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
        bounds.extend(new maps.LatLng(
            place.lat,
            place.lng,
        ));
    });
    return bounds;
};

const Marker = ({ children }) => children;
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const apiIsLoaded = (map, maps, places) => {

    mapRef = map;
    mapsRef = maps;

    if (places.length > 0) {
        // Get bounds by our places
        const bounds = getMapBounds(map, maps, places);
        // Fit map to bounds
        map.fitBounds(bounds);
        // Bind the resize listener
        bindResizeListener(map, maps, bounds);
    }
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
        maps.event.addDomListener(window, 'resize', () => {
            map.fitBounds(bounds);
        });
    });
};

export class PVZPicker extends Component {
    displayName = "Выбор ПВЗ";

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            loading: false,
            places: [],
            showTooltip: [],
            selectedPlace: null,
            selectedCity: "",
            selectedCityID: -1,
            cities: [],
            selectedCityObject: null
        };

        this.selectPlace = this.selectPlace.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);

        //if (this.props.location.pathname.indexOf("/sale") > 0) {
        //}
    }

    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        //authService.unsubscribe(this._subscription);
    }

    async populateState() {
        this.fetchCities();
    }

    resizeMap = (places) => {
        if (places.length > 0) {
            // Get bounds by our places
            const bounds = getMapBounds(mapRef, mapsRef, places);
            // Fit map to bounds
            mapRef.fitBounds(bounds);

            // Bind the resize listener
            bindResizeListener(mapRef, mapsRef, bounds);

            if (places.length == 1) {
                mapRef.setZoom(15);
            }
        }
    };

    async fetchCities() {
        var that = this;
        this.setState({ loading: true });
        reqwest({
            url: 'pvzpicker/cities',
            method: 'get',
            type: 'json',
            headers: {
            }
        }).then(data => {

            that.setState({ cities: data, loading: false });

            that.fetchPlaces();
        });
    }

    async fetchPlaces() {
        var that = this;
        this.setState({ loading: true });
        reqwest({
            url: 'pvzpicker/places?city=' + that.state.selectedCity,
            method: 'get',
            type: 'json',
            headers: {
            }
        }).then(data => {

            if (data != null) {

                var places = new Array();

                for (var i = 0; i < data.length; i++) {
                    var p = data[i];
                    places.push(p);
                }

                this.setState({
                    places: places,
                    loading: false
                }, function () {

                    that.resizeMap(places);

                    that.forceUpdate();
                });
            }
        });
    }

    selectPlace() {

        var onPlaceSelected = this.props.onPlaceSelected;

        if (onPlaceSelected != null) {
            onPlaceSelected(this.state.selectedPlace);
        }
    }

    handleCityChange(ea) {

        var that = this;
        var name = "";
        var id = -1;
        if (ea != null && ea.length > 0) {

            var e = ea[0];
            name = e.name;
            id = e.id;
        }

        this.setState({ selectedCityID: id, selectedCity: name, selectedCityObject: ea, selectedPlace: null }, function () {
            if (name.length > 0) {
                that.fetchPlaces();
            }

            this.selectPlace();
        });
    }

    render() {

        const [bounds, setBounds] = React.useState(null);
        const [zoom, setZoom] = React.useState(10);
        const places = this.state.places;
        const selectedCity = this.state.selectedCity;
        const selectedCityID = this.state.selectedCityID;
        const loading = this.state.loading;
        var idx = 0;
        const points = places.map(place => ({
            type: "Feature",
            properties: { lng: parseFloat(place.lng), lat: parseFloat(place.lat), city: selectedCity, cityId: selectedCityID, cluster: false, placeId: place.id, category: "Постамат", address: place.address, idx: idx++, schedule: place.schedule, descr: place.description },
            geometry: {
                type: "Point",
                coordinates: [
                    parseFloat(place.lng),
                    parseFloat(place.lat)
                ]
            }
        }));

        const { clusters, supercluster } = useSupercluster({
            points,
            bounds,
            zoom,
            options: { radius: 75, maxZoom: 20 }
        });

        return (
            <Spin size="large" spinning={loading}>

            <div>

                <Layout style={{ backgroundColor: "white" }} >
                <Layout>
                    <Content>
                        <div style={{ width: "100%", height: "60vh" }}>
                    <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAmNOW1UAGLC0MjapV2nlOCa2JMF-scaGk" }}
                    defaultZoom={10}
                    defaultCenter={{ lat: 43.1708533333333, lng: 131.913896666667 }}
                    center={{ lat: 43.1708533333333, lng: 131.913896666667 }}
                    zoom={10}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => { apiIsLoaded(map, maps, places) }}
                    onChange={({ zoom, bounds }) => {
                        setZoom(zoom);
                        setBounds([
                            bounds.nw.lng,
                            bounds.se.lat,
                            bounds.se.lng,
                            bounds.nw.lat
                        ]);
                    }}
                    
                >
                    {
                        clusters.map(cluster => {
                            const [longitude, latitude] = cluster.geometry.coordinates;
                            const {
                                cluster: isCluster,
                                point_count: pointCount
                            } = cluster.properties;


                            if (isCluster) {
                                return (<Marker
                                    key={`cluster-${cluster.id}`}
                                    lat={latitude}
                                    lng={longitude}
                                >
                                    <div
                                        className="cluster-marker"
                                        style={{
                                            width: `${30 + (pointCount / points.length) * 20}px`,
                                            height: `${30 + (pointCount / points.length) * 20}px`
                                        }}
                                        onClick={() => {
                                            const expansionZoom = Math.min(
                                                supercluster.getClusterExpansionZoom(cluster.id),
                                                20
                                            );
                                            mapRef.setZoom(expansionZoom);
                                            mapRef.panTo({ lat: latitude, lng: longitude });
                                        }}
                                    >
                                        {pointCount}
                                    </div>
                                </Marker>
                                );
                            }

                            return (
                                <Marker
                                    key={`place-${cluster.properties.placeId}`}
                                    lat={latitude}
                                    lng={longitude}
                                >
                                    <ReactTooltip
                                        id={`tooltip-${cluster.properties.placeId}`}
                                        place='top'
                                        type='light'
                                        effect='float'
                                        multiline={true}
                                    >
                                        <span>{cluster.properties.address}</span>
                                        <br/>
                                        <br />
                                        <span style={{ fontWeight:"bold" }}>{cluster.properties.descr}</span>
                                    </ReactTooltip>

                                    <div
                                        data-tip
                                        data-for={`tooltip-${cluster.properties.placeId}`}
                                        className="place-marker"
                                        style={{
                                            width: `${10 + 20}px`,
                                            height: `${10 + 20}px`
                                        }}
                                        onClick={() => {
                                            if (this.state.selectedCityID > 0) {
                                                this.setState({ selectedPlace: cluster }, function () {
                                                });
                                            }
                                            else {
                                                message.error("Сначала выберите город!");
                                            }
                                        }}
                                    >
                                        1
                                    </div>
                                </Marker>
                            );
                        })}
                    </GoogleMapReact>
                    </div>
                    </Content>
                    <Sider style={{ backgroundColor: "white" }}>
                        <div style={{ paddingLeft: "10px" }}>
                            <h5>Выбор ПВЗ</h5>

                            <Typeahead 
                                emptyLabel="Ничего не найдено"
                                clearButton
                                size="small"
                                placeholder="Населенный пункт"
                                id="selectedCityTA"
                                labelKey={option => { return option.name }}
                                options={this.state.cities}
                                selected={this.state.selectedCityObject}
                                onChange={(e) => { this.handleCityChange(e); }}
                            ></Typeahead>

                            {
                                this.state.selectedPlace != null ?
                                    <div>
                                        <h5>{this.state.selectedPlace.properties.descr}</h5>
                                        <span style={{ fontWeight: "bold" }} >{this.state.selectedPlace.properties.address}</span>
                                        <br/>
                                        <span>{this.state.selectedPlace.properties.schedule}</span>
                                        <br />
                                        <br />
                                        <Button type="primary" onClick={this.selectPlace}>Выбрать этот ПВЗ</Button>
                                    </div>
                                    :
                                    <span></span>
                            }
                        </div>
                    </Sider>
                </Layout>
            </Layout>
                </div>

            </Spin>
        );
    }
}

export default withComponentHooks(PVZPicker)
//export default PVZPicker
