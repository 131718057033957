import React, { Component, PropTypes } from 'react';
import { Table, Input, Button, Modal, Form, Select, Alert, Popconfirm, Badge, Checkbox, Radio, Collapse, AutoComplete, Row, Col } from 'antd'
import { dataContext, contextData } from './DataContext';
import authService from './api-authorization/AuthorizeService'
import reqwest from 'reqwest'
import Bootbox from 'bootbox-react';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;


export class ManageIntercity extends Component {

    static displayName = "Междугородняя отправка";

    constructor(props) {
        super(props);

        this.displayName = "Междугородняя отправка";

        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated,
        });

        if (isAuthenticated) {
        }
        else {
            this.redirectToLogin();
        }
    }

    redirectToLogin() {

        this.props.history.push("/authentication/login");
    }

    render() {
        return (
            <div>
                <h4>{this.displayName}</h4>


            </div>
            );
    }

};

export default ManageIntercity;
