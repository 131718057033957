import React, { Component, PropTypes, useState } from 'react';
import { dataContext, contextData } from './DataContext';
import authService from './api-authorization/AuthorizeService'
import { Spin, Steps, Button, Input, message, Select, Form, Alert, List, Typography } from 'antd'
import { PVZPicker } from './PVZPicker'
//import { Alert } from '../../../wwwroot/lib/bootstrap/dist/js/bootstrap.bundle';

import { CheckOutlined } from "@ant-design/icons";

const { Step } = Steps;
const { TextArea } = Input;
const { Option } = Select;

export class AddOrder extends Component {
    static displayName = "Новый заказ";

    constructor(props) {
        super(props);

        var id = this.props.match.params.id;

        this.size_name = ["S - 26 × 17 × 8 см", "M - 30 × 20 × 15 см", "L - 40 × 27 × 18 см", "XL - 53 × 36 × 22 см"];


        this.handleItemsDesription = this.handleItemsDesription.bind(this);
        this.handlePlaceSelected = this.handlePlaceSelected.bind(this);
        this.setIntercityDim = this.setIntercityDim.bind(this);
        this.finishAddOrder = this.finishAddOrder.bind(this);
        this.handleAcceptPointChange = this.handleAcceptPointChange.bind(this);

        this.state = {
            client_id: id,
            isAuthenticated: false,
            loading: true,
            currentStep: 0,
            itemsDescription: "",
            itemsDescriptionDisabled: false,
            selectedPlace: null,
            intercityParcelWidth: "",
            intercityParcelHeight: "",
            intercityParcelLength: "",
            boxSizeName: "",
            boxSizeIndex: -2,
            boxSizeDescription: "",
            selectedAcceptPoint: 0
        };
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated,
            loading: false,
        });

        if (isAuthenticated) {

        }
        else {
            this.props.history.push("/authentication/login");
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    setCurrent(current) {

        this.setState({ currentStep: current });
    }

    next = () => {

        if (this.state.currentStep == 1) {
            window.dispatchEvent(new Event('resize'));
        }

        if (this.state.currentStep == 0) {
            if (this.state.itemsDescription.length == 0) {
                message.error('Введите описание вложения!')
                return;
            }

            if (this.state.intercityParcelWidth == 0 || this.state.intercityParcelHeight == 0 || this.state.intercityParcelLength == 0) {
                message.error('Введите размеры!')
                return;
            }

            if (this.state.boxSizeIndex < 0) {
                message.error('Нет ячейки постамата под указанный размер посылки!')
                return;
            }
        }

        if (this.state.currentStep == 2) {
            if (this.state.selectedPlace == null) {
                message.error('Выберите постамат!')
                return;
            }

            if (this.state.cityId < 0) {
                message.error('Выберите город!')
                return;
            }
        }

        this.setCurrent(this.state.currentStep + 1);
    };

    prev = () => {
        this.setCurrent(this.state.currentStep - 1);
    };

    handleItemsDesription(e) {
        this.setState({ itemsDescription: e.target.value });
    }

    handlePlaceSelected(e) {

        this.setState({ selectedPlace: e }, function () {
            if (e != null) {
                this.next();
            }
        });
    }

    setIntercityDim() {
/*
 *  S (до 26 × 17 × 8 см)
    M (до 30 × 20 × 15 см)
    L (до 40 × 27 × 18 см)
    XL (до 53 × 36 × 22 см)
*/

        var w = parseInt(this.state.intercityParcelWidth);
        if (!isNaN(w)) {
            this.setState({ intercityParcelWidth: w });
        }
        else {
            this.setState({ intercityParcelWidth: "" });
        }

        var h = parseInt(this.state.intercityParcelHeight);
        if (!isNaN(h)) {
            this.setState({ intercityParcelHeight: h });
        }
        else {
            this.setState({ intercityParcelHeight: "" });
        }

        var l = parseInt(this.state.intercityParcelLength);
        if (!isNaN(l)) {
            this.setState({ intercityParcelLength: l });
        }
        else {
            this.setState({ intercityParcelLength: "" });
        }

        var size_s = [26, 17, 8];
        var size_m = [30, 20, 15];
        var size_l = [40, 27, 18];
        var size_xl = [53, 36, 22];

        size_s.sort(function (a, b) { return a - b });
        size_m.sort(function (a, b) { return a - b });
        size_l.sort(function (a, b) { return a - b });
        size_xl.sort(function (a, b) { return a - b });

        var sizes = [size_s, size_m, size_l, size_xl];
        if (!isNaN(w) && !isNaN(h) && !isNaN(l)) {
            var whl = [w, h, l];
            whl.sort(function (a, b) { return a - b });
            var selected_size_idx = -1;
            for (var s = 0; s < sizes.length; s++) {
                var size = sizes[s];

                if (whl[0] <= size[0] && whl[1] <= size[1] && whl[2] <= size[2]) {
                    selected_size_idx = s;
                    break;
                }
            }

            if (selected_size_idx >= 0) {

                this.setState({ boxSizeName: this.size_name[selected_size_idx], boxSizeIndex: selected_size_idx, boxSizeDescription: ""})
            }
            else {
                var boxSizeName = "Нет ячейки постамата под указанный размер!";
                this.setState({ boxSizeName: boxSizeName, boxSizeIndex: selected_size_idx, boxSizeDescription: "Разделите посылку или воспользуйтесь другим методом доставки." })
            }
        }
    }

    handleAcceptPointChange(e) {
        this.setState({ selectedAcceptPoint: e });
    }

    async finishAddOrder() {

        const token = await authService.getAccessToken();
        var that = this;
        if (token.length > 0) {

            var order_data = {
                ClientID: this.state.client_id,
                DeliveryAmount: 0,
                ItemsAmount: 0,
                AcceptStorageID: this.state.selectedAcceptPoint,
                GiveoutStorageID: this.state.selectedPlace.properties.placeId,
                ////DeliveryCityID: selectedClientCityID,
                DeliveryAddress: this.state.selectedPlace.properties.address,
                Items: this.state.itemsDescription,
                DeliveryType: 5,
                Notes: "",
                ItemsList: null,
                PaySender: true,
                PostamatTypeID: this.state.boxSizeIndex,
                PostamatID: this.state.selectedPlace.properties.placeId,
                Lat: this.state.selectedPlace.properties.lat,
                Lon: this.state.selectedPlace.properties.lng,
                DeliveryCityID: this.state.selectedPlace.properties.cityId
            };

            order_data.Weight = 0;
            order_data.Width = this.state.intercityParcelWidth;
            order_data.Length = this.state.intercityParcelLength;
            order_data.Height = this.state.intercityParcelHeight;

            this.setState({ loading: true });

            await fetch('clients/save_order',
                {
                    method: "POST",
                    body: JSON.stringify(order_data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {
                    var order = JSON.parse(data);
                    if (order != null) {
                        that.setState({ loading: false }, function () {
                            if (order.orderID != null && order.orderID > 0) {
                                message.success(order.error);
                                that.props.history.push("/add-clients");
                            }
                            else {
                                message.error(order.error);
                            }
                        });
                    }
                    else {
                        that.setState({ loading: false }, function () {
                            message.error("Произошла неизвестная ошибка");
                        });
                    }
                });
        }
        else {
            message.error('Ошибка авторизации');
        }
    }

    renderBoxSize(item) {
        if (item == this.state.boxSizeName) {
            return (
                <Alert message=<b>{item}</b> showIcon style = {{ fontSize: "small", width: "200px" }
        } type = { "success"} >
                </Alert>
            )
        }
        else {
    return (
        <Alert message={item} showIcon style={{ fontSize: "small", width: "200px" }} type={"error"}>
        </Alert>
    )
}
    }

    render() {

        const current = this.state.currentStep;
        const itemsDescription = this.state.itemsDescription;
        const itemsDescriptionDisabled = this.state.itemsDescriptionDisabled;
        const steps = 4;
        return (
            <div>
                <h3>Оформление заказа</h3>

                <Spin spinning={this.state.loading}>
                    <div style={{ padding:"10px" }}>
                        <Steps current={current}>
                            <Step key={1} title="Параметры посылки">
                            </Step>
                            <Step key={2} title="Тип доставки">
                            </Step>
                            <Step key={3} title="Адрес доставки">
                            </Step>
                            <Step key={4} title="Завершение">
                            </Step>
                        </Steps>

                        <div className="steps-content" style={{ padding: "10px"} }>
                            <div style={{ display: current == 0 ? "block" : "none", height: "60vh" }}>
                                <Form>
                                    <Form.Item>
                                        <span>Описание:</span>
                                        <TextArea rows={3} id="orderItems" value={itemsDescription} readOnly={this.state.itemsDescriptionDisabled} onChange={e => { this.handleItemsDesription(e) }} name="orderItems" />
                                    </Form.Item>

                                    <Form.Item>
                                        <span>Размер:</span>
                                        <Input.Group >
                                            <Input addonBefore="Ш" style={{ width: "100px" }} type="text" value={this.state.intercityParcelWidth} onChange={e => { this.setState({ intercityParcelWidth: e.target.value }, function () { this.setIntercityDim() }) }} placeholder="Ш" />
                                            <Input addonBefore="В" style={{ width: "100px" }} type="text" value={this.state.intercityParcelHeight} onChange={e => { this.setState({ intercityParcelHeight: e.target.value }, function () { this.setIntercityDim() }) }} placeholder="В" />
                                            <Input addonBefore="Д" style={{ width: "140px" }} addonAfter="см." type="text" value={this.state.intercityParcelLength} onChange={e => { this.setState({ intercityParcelLength: e.target.value }, function () { this.setIntercityDim() }) }} placeholder="Д" />
                                        </Input.Group>
                                    </Form.Item>

                                    <Form.Item>
                                        <Alert description={this.state.boxSizeDescription} message={this.state.boxSizeName} showIcon style={{ display: this.state.boxSizeIndex == -1 ? "block" : "none", fontSize:"large" }} type={this.state.boxSizeIndex >= 0 ? "success" : "error"}>
                                        </Alert>
                                        <List
                                            size = "small"
                                            split = "false"
                                            dataSource={this.size_name}
                                            renderItem={item => (
                                                <List.Item>
                                                    {this.renderBoxSize(item)}
                                                </List.Item>
                                            )}
                                        />
                        
                                    </Form.Item>

                                </Form>
                            </div>

                            <div style={{ display: current == 1 ? "block" : "none", height: "60vh" }}>
                                <Form.Item>
                                    <span>Тип доставки:</span><br/>
                                    <Select value={0} style={{ width: "260px" }}>
                                        <Option value={0}>ПВЗ-Постамат</Option>;
                                    </Select>
                                </Form.Item>

                                <Form.Item>
                                    <span>Пункт приема заказа:</span><br />
                                    <Select value={this.state.selectedAcceptPoint} style={{ width: "260px" }} onChange={e => { this.handleAcceptPointChange(e) }}>
                                        <Option value={0}>Луговая г. Владивосток, ул. Луговая, 63</Option>;
                                        <Option value={6015}>Артем-Центр г. Артём, ул. Лазо, 2</Option>;
                                        <Option value={6016}>Уссурийск-Центр г. Уссурийск, ул. Калинина, 41</Option>;
                                    </Select>
                                </Form.Item>
                            </div>

                            <div style={{ display: current == 2 ? "block" : "none", height: "60vh" }}>
                                <PVZPicker onPlaceSelected={e => { this.handlePlaceSelected(e) }} />
                            </div>

                            <div style={{ display: current == 3 ? "block" : "none", height: "60vh", padding:"10px" }}>
                                {
                                    this.state.selectedPlace != null ?
                                        <>
                                            <table className="table">
                                                <tr>
                                                    <td colSpan="2">
                                                        Параметры посылки:
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Вложение:
                                                    </td>
                                                    <td>
                                                        <span>{this.state.itemsDescription}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Город:
                                                    </td>
                                                    <td>
                                                        <span>{this.state.selectedPlace.properties.city}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Адрес:
                                                    </td>
                                                    <td>
                                                        <span>{this.state.selectedPlace.properties.address}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Ячейка:
                                                    </td>
                                                    <td>
                                                        <span>{this.state.boxSizeName}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                            <br />
                                        </>
                                        : "<span></span>"
                                }

                            </div>

                        </div>
                        <div className="steps-action">
                            {current > 0 && (
                                <></>
                            )}
                            {current < steps - 1 && (
                                <>
                                    <Button disabled={current==0} style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                                        Назад
                                    </Button>
                                    <Button type="primary" onClick={() => this.next()}>
                                        Далее
                                    </Button>
                                </>
                            )}
                            {current === steps - 1 && (
                                <>
                                    <Button disabled={current == 0} style={{ margin: '0 8px' }} onClick={() => this.prev()}>
                                        Назад
                                    </Button>
                                    <Button type="primary" onClick={() => this.finishAddOrder()}>
                                        Оформить
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </Spin>
            </div>
        );
    }
}

//<iframe src='/headless/pvz-picker' width="100%" height="100%">
//</iframe>

export default AddOrder;
