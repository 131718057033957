import React, { Component, PropTypes } from 'react';
import { Redirect } from 'react-router'
import { dataContext, contextData } from './DataContext';

import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import authService from './api-authorization/AuthorizeService';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isAuthenticated: false,
            role: "",
            token: "",
            redirect: false,
            userID: ""
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async getUser() {
        const token = await authService.getAccessToken();
        var that = this;

        if (token.length > 0) {
            await fetch('cabinet/user',
                {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
                .then(response => response.json())
                .then(
                    (data) => {
                        var user = {};
                        if (data != null) {
                            user = data;
                            this.setState({
                                isAuthenticated: true,
                                role: user.role,
                                token: token,
                                redirect: false,
                                userID: user.userID
                            });
                        }
                        else {
                            this.setState({ redirect: true })
                        }
                    },
                    (error) => {
                        this.setState({ redirect: true })
                    }
                );
        }
        else {
            this.setState({ redirect: true })
        }
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])

        if (isAuthenticated) {
            //this.setState({ isAuthenticated: true });
            await this.getUser();
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    authenticatedViewEmployee() {

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img src="/images/logo_beta.png" alt="Стеллажи" /></NavbarBrand>
                        <a tag={Link} target="_blank" href="http://iss-vladik.stellazhi.net/%D0%9C%D0%BE%D0%B9%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/%D0%9E%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%82%D0%BE%D1%80%D0%A1%D0%9F/tabid/68/ctl/ISHOP_MANAGEMENT/mid/405/Default.aspx?SkinSrc=[G]Skins/DarkKnight-ISS-Wide/3-Column-Standard-Menu">Перейти в старый интерфейс</a>
                        &nbsp;|&nbsp;<a tag={Link} target="_blank" href="http://iss-vladik.stellazhi.net/Help/ShopManual.htm">Инструкция</a>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Управление
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/manage-stickers">Ценники и стикеры</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/manage-intercity">Междугородняя отправка</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <a target="_blank" className="text-dark nav-link" href="http://www.stellazhi.net/pvz/map.aspx">Карта ПВЗ</a>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    authenticatedView() {

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img src="/images/logo_beta.png" alt="Стеллажи" /></NavbarBrand>
                        <a tag={Link} target="_blank" href="http://iss-vladik.stellazhi.net/%D0%9C%D0%BE%D0%B9%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82/%D0%9E%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%82%D0%BE%D1%80%D0%A1%D0%9F/tabid/68/ctl/ISHOP_MANAGEMENT/mid/405/Default.aspx?SkinSrc=[G]Skins/DarkKnight-ISS-Wide/3-Column-Standard-Menu">Перейти в старый интерфейс</a>
                        &nbsp;|&nbsp;<a tag={Link} target="_blank" href="http://iss-vladik.stellazhi.net/Help/ShopManual.htm">Инструкция</a>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Управление
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/add-clients">Клиенты</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/manage-orders/parcels">Посылки</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/manage-orders/sale">Продажи</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/manage-orders/storage">Склад</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink tag={Link} className="text-dark" to="/manage-accounting">Расчёты</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <a target="_blank" className="text-dark nav-link" href="http://www.stellazhi.net/pvz/map.aspx">Карта ПВЗ</a>
                                        </DropdownItem>
                                        
                                        {this.state.userID == 6 ?
                                            <DropdownItem style={{ display: this.state.userID == 6 ? "block" : "none" }}>
                                                <NavLink tag={Link} className="text-dark" to="/manage-categories">Каталог</NavLink>
                                            </DropdownItem> : <span style={{ display: "none" }} ></span>
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    anonimousView() {

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img src="/images/logo_beta.png" alt="Стеллажи" /></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Получателям
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>
                                            <a target="_blank" className="text-dark nav-link" href="http://cdvl.stellazhi.net/">Отследить посылку</a>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <a target="_blank" className="text-dark nav-link" href="http://www.stellazhi.net/pvz/map.aspx">Карта ПВЗ</a>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Сотрудникам
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>
                                            <a className="text-dark nav-link" href="http://www.stellazhi.net/pvz">Управление ПВЗ</a>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem>
                                </NavItem>
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    redirectToLogin() {
        this.props.history.push("/authentication/login");
    }

    render() {

        const { isAuthenticated, role, token, redirect } = this.state;

        if (redirect) {
            return <Redirect to="/authentication/login" push={true} />
        }

        if (isAuthenticated) {
            if (token.length > 0) {

                if (role == "Shop") {
                    return this.authenticatedView();
                }
                else {
                    if (role == "Employee") {
                        return this.authenticatedViewEmployee();
                    }
                }
            }
            else {
                return this.anonimousView();
            }
        }
        else {
            return this.anonimousView();
        }
    }
}
