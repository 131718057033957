import React, { Component, PropTypes } from 'react';
import { dataContext, contextData } from './DataContext';
import ReactDOM from 'react-dom';
import { Tabs, Table, Input, Button, Modal, Select, Form, Checkbox, Popconfirm } from 'antd'
import authService from './api-authorization/AuthorizeService'
import reqwest from 'reqwest'
import { CheckOutlined } from "@ant-design/icons";
import Bootbox from 'bootbox-react';
import { Link } from 'react-router-dom';

const { Search } = Input;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const getOrdersParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        search: params.search,
        ...params,
    };
};

const getContactsParams = params => {
    return {
        results: params.contactsPagination.pageSize,
        page: params.contactsPagination.current,
        search: params.search,
        ...params,
    };
};

const MODE_PARCELS = 0,
    MODE_SALE = 1,
    MODE_STORAGE = 2;

export class ManageOrders extends Component {
    displayName = "Управление заказами";

    constructor(props) {
        super(props);

        this.modeChanged = this.modeChanged.bind(this);
        this.clickStickers = this.clickStickers.bind(this);
        this.clickGroup = this.clickGroup.bind(this);
        this.addSale = this.addSale.bind(this);
        this.addGood = this.addGood.bind(this);
        this.saveSale = this.saveSale.bind(this);
        this.saveGood = this.saveGood.bind(this);
        this.moveToSell = this.moveToSell.bind(this);
        this.clickReturn = this.clickReturn.bind(this);
        this.clickMakeOrderFromRentItems = this.clickMakeOrderFromRentItems.bind(this);
        this.clickMoveToSellItems = this.clickMoveToSellItems.bind(this);
        this.clickDelete = this.clickDelete.bind(this);
        this.uploadOrders = this.uploadOrders.bind(this);
        this.downloadOrdersTemplate = this.downloadOrdersTemplate.bind(this);
        this.handleSalePriceChange = this.handleSalePriceChange.bind(this);
        this.handleGiveoutPointChange = this.handleGiveoutPointChange.bind(this);
        this.clickShelfsRent = this.clickShelfsRent.bind(this);
        this.clickShelfsManage = this.clickShelfsManage.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);

        this.setSaleShowToggle = this.setSaleShowToggle.bind(this);
        this.setGoodShowToggle = this.setGoodShowToggle.bind(this);

        this.parcelsMode = MODE_PARCELS;
        this.uploadTitle = "Загрузка заказов";
        this.uploadButtonTitle = "Загрузить заказы";
        this.giveoutTabTitle = "Выданные";
        this.deliveryTabTitle = "На доставке";
        this.stickersLabel = "Стикеры";
        this.uploadOrdersLabel = "Создать заказы";

        if (this.props.location.pathname.indexOf("/sale") > 0) {
            this.parcelsMode = MODE_SALE;
            this.displayName = "Управление продажами";
            this.uploadTitle = "Загрузить товары";
            this.uploadButtonTitle = "Загрузить список товаров";
            this.giveoutTabTitle = "Продано";
            this.deliveryTabTitle = "На витрине";
            this.stickersLabel = "Ценники";
            this.uploadOrdersLabel = "Создать товары";
        }

        if (this.props.location.pathname.indexOf("/storage") > 0) {
            this.parcelsMode = MODE_STORAGE;
            this.displayName = "Управление складом";
            this.uploadTitle = "Загрузка товаров";
            this.uploadButtonTitle = "Загрузить товары";
            this.giveoutTabTitle = "В заказах";
            this.deliveryTabTitle = "На хранении";
        }

        var mode = 1;
        var ordersSelectionMode = false;
        var moveToSellItemsVisible = false;
        if (this.props.location.pathname.indexOf("/select") > 0) {

            if (contextData.selectedClientID > 0 && contextData.selectedClientName.length > 0) {
                mode = 2;
                ordersSelectionMode = true;
                moveToSellItemsVisible = false;
            }
            else {
                this.props.history.push("/manage-orders/storage");
            }
        }

        this.state = {
            isAuthenticated: false,
            loading: false,
            stickersDisabled: true,
            stickersVisible: true,
            groupVisible: false,
            groupDisabled:true,
            hideVisible: true,
            returnDisabled: true,
            returnVisible: false,
            makeOrderFromRentItemsVisible: ordersSelectionMode,
            makeOrderFromRentItemsDisabled: true,
            moveToSellItemsVisible: moveToSellItemsVisible,
            moveToSellItemsDisabled: true,
            moveToSellVisible: false,
            ordersVisible: true,
            contactsVisible: false,
            uploadOrdersVisible: false,
            uploadedOrdersVisible: false,
            selectedSaleItems: "",
            showNick: false,
            uploadedOrders: "",
            saleShow: false,
            goodShow: false,
            manageShelfsVisible: false,
            createOrdersDisabled: true,
            uploaded_orders_items: [],
            contacts_items: [],
            giveout_points: [],
            accept_points: [],
            selectedName: "",
            selectedSaleComission: 30,
            selectedAcceptPoint: 0,
            selectedAcceptPointCity: "",
            selectedAcceptPointCityID: 0,
            selectedGiveoutPoint: 0,
            selectedShopCityID: 0,
            selectedShopCity: "",
            salePayoutPrice: 0,
            salePrice: 0,
            saleComission: 0,
            orders: [],
            cities: [],
            search: "",
            changeMode : false,
            mode: mode,
            allChecked: false,
            allCheckedVisible: true,
            showAlert: false,
            alertText: "",
            description : "",
            stickerFontSize: "18px",
            ordersSelectionMode: ordersSelectionMode,
            pagination: {
                current: 1,
                pageSize: 20,
                position: ['topRight', 'bottomRight'],
                showQuickJumper: true,
                showSizeChanger: true,
                defaultPageSize: 20
            },
            contactsPagination: {
                current: 1,
                pageSize: 20,
                position: ['topRight', 'bottomRight'],
                showQuickJumper: true,
                showSizeChanger: true,
                defaultPageSize: 20
            },
            selectedOwnerID: 6
        };
    }

    handleGiveoutPointChange(e) {

        var comission = this.state.selectedSaleComission;
        var ownerID = this.state.selectedOwnerID;
        for (var i = 0; i < this.state.giveout_points.length; i++) {
            var gp = this.state.giveout_points[i];
            if (parseInt(e) == gp.storageID) {
                comission = gp.saleComission;
                ownerID = gp.ownerID;
            }
        }

        this.setState({ selectedSaleComission: comission, selectedGiveoutPoint: e, selectedAcceptPoint: e, selectedOwnerID: ownerID });
    }

    handleSalePriceChange(e) {

        var price = parseFloat(e.target.value.replace(".",","));
        if (!isNaN(price)) {

            var comission = price * this.state.selectedSaleComission / 100;
            comission = comission.toFixed(1);

            var po_price = price - comission;

            this.setState({ salePrice: price, salePayoutPrice: po_price, saleComission: comission });
        }
        else {
            this.setState({ salePrice: 0, salePayoutPrice: 0, saleComission: 0 });
        }
    }

    handleSelectOrder(record, e) {
        if (e.target.checked) {
            record.checked = true;
        }
        else {
            record.checked = false;
        }

        const { orders, mode } = this.state;

        var moveToSellItemsVisible = this.parcelsMode == MODE_STORAGE && mode == 2;
        var moveToSellItemsDisabled = true;

        if (this.state.ordersSelectionMode) {
            moveToSellItemsVisible = false;
        }

        if (moveToSellItemsVisible == true) {
            moveToSellItemsDisabled = this.selectionCount() != 1;
        }

        this.setState({
            orders: orders,
            moveToSellItemsDisabled: moveToSellItemsDisabled,
            groupDisabled: this.selectionCount() < 2,
            stickersDisabled: this.selectionCount() == 0,
            returnDisabled: this.selectionCount() == 0,
            makeOrderFromRentItemsDisabled: this.selectionCount() == 0
        });
    }

    clickShelfsRent() {
        this.props.history.push("/shelfs-rent");
    }

    clickShelfsManage() {
        this.props.history.push("/shelfs");
    }

    selectionCount() {
        var cnt = 0;
        this.state.orders.forEach(function (el) {
            if (el.checked) {
                cnt++;
            }
        });

        return cnt;
    }

    handleSelectAllOrders = e => {

        if (e.target.checked) {

            this.state.orders.forEach(function (el) {
                el.checked = true;
            });
        }
        else {

            this.state.orders.forEach(function (el) {
                el.checked = false;
            });

        }

        const { orders, mode } = this.state;

        var moveToSellItemsVisible = this.parcelsMode == MODE_STORAGE && mode == 2;
        var moveToSellItemsDisabled = true;

        if (this.state.ordersSelectionMode) {
            moveToSellItemsVisible = false;
        }

        if (moveToSellItemsVisible == true) {
            moveToSellItemsDisabled = this.selectionCount() != 1;
        }


        this.setState({
            orders: orders, moveToSellItemsDisabled: moveToSellItemsDisabled,
            allChecked: e.target.checked,
            stickersDisabled: this.selectionCount() == 0,
            groupDisabled: this.selectionCount() < 2,
            returnDisabled: this.selectionCount() == 0,
            makeOrderFromRentItemsDisabled: this.selectionCount() == 0
        }, function () {
            this.setState({ orders: orders, allChecked: e.target.checked });
        });
    }

    chunkSubstr(str, size) {
        const numChunks = Math.ceil(str.length / size);
        const chunks = new Array(numChunks);

        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = str.substr(o, size);
        }

        return chunks;
    }

    handleNameChange(e) {

        var items = e.target.value;

        items = items.replace(/\n/g, "");

        this.setState({ selectedName: items }, function () {

        });
    }

    handleDescriptionChange(e) {

        var font_size = "18px";
        var max_length = 20
        var items = e.target.value;
        var el = e.target;

        items = items.replace(/\n/g, "");

        if (items.length >= 60) {
            max_length = 30;
            font_size = "14px";
        }

        var start = el.selectionStart;

        var lines = this.chunkSubstr(items, max_length);
        if (lines.length < 4) {
            items = lines.join("\n");
            this.setState({ description: items, stickerFontSize: font_size }, function () {

                el.setSelectionRange(start, start);
            });
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated
        });

        const { pagination, mode } = this.state;

        if (isAuthenticated) {

            this.modeChanged(mode);
            //this.fetchData({ pagination, mode });
            this.fetchAcceptPoints(mode);

            if (this.parcelsMode == MODE_STORAGE) {
                this.getIsOwner();
            }
        }
        else {
            this.redirectToLogin();
        }
    }

    redirectToLogin() {

        this.props.history.push("/authentication/login");
    }

    async getIsOwner() {
        var that = this;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'storage/is_owner',
                method: 'get',
                type: 'json',
                data: {},
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {

                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                that.setState({
                    manageShelfsVisible: data == true,
                }, function () {
                });
            });
        }
    }


    async fetchData(params) {

        this.setState({ loading: true, allChecked: false });

        var that = this;

        params.parcelsMode = this.parcelsMode;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'orders',
                method: 'get',
                type: 'json',
                data: getOrdersParams(params),
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {
                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {
                this.setState({
                    showNick: data.showNick,
                    allChecked: false,
                    loading: false,
                    orders: data.results,
                    pagination: {
                        ...params.pagination,
                        total: data.totalCount,
                    },
                });
            });
        }
    }

    async fetchContacts(params) {

        this.setState({ loading: true});

        var that = this;

        params.parcelsMode = this.parcelsMode;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'orders/contacts',
                method: 'get',
                type: 'json',
                data: getContactsParams(params),
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {
                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {
                this.setState({
                    loading: false,
                    contacts_items: data.results,
                    contactsPagination: {
                        ...params.contactsPagination,
                        total: data.totalCount,
                    },
                });
            });
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        const search = this.state.search;
        const { mode } = this.state;

        this.fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            search,
            mode,
            ...filters,
        });
    };

    stripPhone(phone) {
        var _phone = "";

        if (phone != undefined) {
            for (var i = 0; i < phone.length; i++) {
                var c = phone[i];
                if (c >= '0' && c <= '9') {
                    _phone += c;
                }
            }
        }

        return _phone;
    }

    setSaleShowToggle() {
        this.setState({ saleShow: !this.state.saleShow });
    }

    setGoodShowToggle() {
        this.setState({ goodShow: !this.state.goodShow });
    }

    validatePhone(phone) {
        if (phone.length != 10) {
            return "Длина телефона должна быть 10 цифр";
        }

        return "OK";
    }

    downloadOrdersTemplate(e) {
        e.preventDefault();

        if (this.parcelsMode == MODE_PARCELS || this.parcelsMode == MODE_SALE || this.parcelsMode == MODE_STORAGE) {

            var name = "OrdersTemplate.xlsx";
            if (this.parcelsMode == MODE_SALE) {
                name = "SaleTemplate.xlsx";
            }
            if (this.parcelsMode == MODE_STORAGE) {
                name = "RentTemplate.xlsx";
            }
            const link = document.createElement('a');
            link.href = '/data/' + name;
            link.setAttribute(
                'download',
                name,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        }
        else {
            alert("Для текущего режима шаблоны не реализованы");
        }
    }

    async uploadOrders(e) {
        const { uploadedOrders, uploadedOrdersVisible } = this.state;
        e.preventDefault();
        var error = "";
        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var that = this;

            if (uploadedOrdersVisible) {

                const { uploaded_orders_items } = this.state;

                this.setState({ loading: true, orders: uploaded_orders_items, createOrdersDisabled: true });

                fetch('orders/create',
                    {
                        method: "POST",
                        body: JSON.stringify(uploaded_orders_items),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {

                        var b_ok = true;
                        if (data.length > 0) {
                            var orders = JSON.parse(data);
                            if (orders.length > 0) {
                                for (var i = 0; i < orders.length; i++) {
                                    var itm = orders[i];
                                    if (itm.error != undefined) {
                                        if (itm.error.length > 0 && itm.error != "OK") {
                                            b_ok = false;
                                        }
                                        else {
                                            itm.error = "OK";
                                        }
                                    }
                                    else {
                                    }
                                }

                                if (b_ok) {
                                    that.setState({ changeMode: true, loading: false, createOrdersDisabled: false, uploaded_orders_items: orders, showAlert: true, alertText: "Заказы успешно созданы, смотрите в разделе \"Не принятые\"" }, function () {
                                    });
                                }
                                else {
                                    for (var i = 0; i < orders.length; i++) {
                                        var itm = orders[i];
                                        itm.orderID = i + 1;
                                    }

                                    that.setState({ changeMode: false, loading: false, createOrdersDisabled: false, uploaded_orders_items: orders, showAlert: true, alertText: "Произошла одна или несколько ошибок при создании заказов. Заказы не созданы. Исправьте ошибки в файле и загрузите еще раз." }, function () {
                                    });
                                }
                            }
                            else {
                                that.setState({ changeMode: false, loading: false, createOrdersDisabled: false, showAlert: true, alertText: "Произошла непредвиденная ошибка при создании заказов. Заказы не созданы." }, function () {
                                });
                            }
                        }
                        else {
                            that.setState({ changeMode: false, loading: false, createOrdersDisabled: false, showAlert: true, alertText: "Произошла непредвиденная ошибка при создании заказов. Заказы не созданы." }, function () {
                            });
                        }
                    });

            }
            else {
                if (uploadedOrders.length > 0) {
                    var doc = uploadedOrders.replace(/<br>/g, " ");
                    var rows = doc.split("\x0a");
                    if (rows.length > 1) {
                        var hdr_row = rows[0];
                        var cells = hdr_row.split("\x09");
                        var hdr_cells = new Array();
                        var hdr_cells_count = 0;
                        cells.forEach(function (c, idx) {
                            if (c.length > 0) {
                                hdr_cells[c] = idx;
                                hdr_cells_count++;
                            }
                        });

                        var cnt_ref = 7;
                        if (that.parcelsMode == MODE_SALE || that.parcelsMode == MODE_STORAGE) {
                            cnt_ref = 4;
                        }

                        if (cnt_ref == 4) {
                            if (hdr_row.indexOf("Описание") < 0) {
                                error = "Отсутствует заголовок";
                                alert(error);
                                return;
                            }
                        }
                        else {
                            if (cnt_ref == 7) {
                                if (hdr_row.indexOf("Телефон") < 0) {
                                    error = "Отсутствует заголовок";
                                    alert(error);
                                    return;
                                }
                            }
                        }

                        if (hdr_cells_count == cnt_ref) {
                            var items = new Array();
                            var no = 1;
                            rows.forEach(function (row, idx) {

                                if (idx > 0) {
                                    if (that.parcelsMode == MODE_PARCELS) {
                                        var cells = row.split("\x09");
                                        var itm = {
                                            clientName: cells[hdr_cells["ФИО"]],
                                            clientNick: cells[hdr_cells["Ник"]],
                                            clientPhone: that.stripPhone(cells[hdr_cells["Телефон"]]),
                                            totalAmount: cells[hdr_cells["Сумма к оплате"]],
                                            giveoutStorageName: cells[hdr_cells["Пункт выдачи"]],
                                            giveoutStorageCity: cells[hdr_cells["Город"]],
                                            giveoutStorageID: -1,
                                            orderID: no,
                                            error: "",
                                            bSale: false,
                                            bRentForShelf: false
                                        };

                                        itm.totalAmount = parseFloat(itm.totalAmount);
                                        if (isNaN(itm.totalAmount)) {
                                            itm.totalAmount = 0;
                                        }

                                        if (itm.giveoutStorageName != null) {

                                            if (itm.clientName.length > 0 || itm.clientPhone.length > 0 || itm.giveoutStorageName.length > 0) {
                                                items.push(itm);
                                                no++;
                                            }
                                        }
                                    }
                                    else {
                                        if (that.parcelsMode == MODE_SALE) {
                                            var cells = row.split("\x09");
                                            var itm = {
                                                clientName: "Продажа",
                                                clientNick: "Продажа",
                                                clientPhone: "0000000000",
                                                items: cells[hdr_cells["Описание товара"]],
                                                totalAmount: cells[hdr_cells["Стоимость товара"]],
                                                itemsAmount: cells[hdr_cells["Стоимость товара"]],
                                                giveoutStorageName: cells[hdr_cells["Пункт выдачи"]],
                                                giveoutStorageCity: cells[hdr_cells["Город"]],
                                                giveoutStorageID: -1,
                                                orderID: no,
                                                error: "",
                                                description: "",//cells[hdr_cells["Описание товара"]],
                                                bSale: true,
                                                bRentForShelf: false
                                            };

                                            itm.totalAmount = parseFloat(itm.totalAmount);
                                            if (isNaN(itm.totalAmount)) {
                                                itm.totalAmount = 0;
                                            }

                                            if (itm.giveoutStorageName != null) {
                                                if (itm.items.length > 0 && itm.giveoutStorageName.length > 0) {
                                                    items.push(itm);
                                                    no++;
                                                }
                                            }
                                        }
                                        else {
                                            if (that.parcelsMode == MODE_STORAGE) {
                                                var cells = row.split("\x09");
                                                var itm = {
                                                    clientName: "Аренда",
                                                    clientNick: "Аренда",
                                                    clientPhone: "0000000001",
                                                    items: cells[hdr_cells["Описание товара"]],
                                                    totalAmount: cells[hdr_cells["Стоимость товара"]],
                                                    itemsAmount: cells[hdr_cells["Стоимость товара"]],
                                                    giveoutStorageName: cells[hdr_cells["Пункт выдачи"]],
                                                    giveoutStorageCity: cells[hdr_cells["Город"]],
                                                    giveoutStorageID: -1,
                                                    orderID: no,
                                                    error: "",
                                                    description: "",
                                                    bSale: false,
                                                    bForRentShelf: true
                                                };

                                                itm.totalAmount = parseFloat(itm.totalAmount);
                                                if (isNaN(itm.totalAmount)) {
                                                    itm.totalAmount = 0;
                                                }

                                                if (itm.giveoutStorageName != null) {
                                                    if (itm.items.length > 0 && itm.giveoutStorageName.length > 0) {
                                                        items.push(itm);
                                                        no++;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            });

                            if (items.length > 0) {
                                var b_ok = true;
                                for (var i = 0; i < items.length; i++) {
                                    var itm = items[i];
                                    if (b_ok) {
                                        if (that.parcelsMode == MODE_PARCELS) {
                                            error = that.validatePhone(itm.clientPhone);
                                        }
                                        else {
                                            error = "OK";
                                        }
                                        if (error != "OK") {
                                            b_ok = false;
                                            break;
                                        }
                                    }
                                }

                                if (b_ok) {
                                    error = "";
                                    that.setState({ uploadOrdersVisible: false, uploadedOrdersVisible: true, createOrdersDisabled: false, uploaded_orders_items: items });
                                }
                            }
                            else {
                                error = "Заказы не распознаны";
                            }
                        }
                        else {
                            error = "Неправильное количество колонок в файле";
                        }
                    }
                    else {
                        error = "Недостаточно строк в файле";
                    }
                }
                else {
                    error = "Скопируйте содержимое файла в поле";
                }
            }
        }

        if (error.length > 0) {
            alert(error);
        }
    }

    async addSale(e) {
        e.preventDefault();
        const token = await authService.getAccessToken();
        if (token.length > 0) {
            this.setState({ saleShow: true });
        }
    }

    async addGood(e) {
        e.preventDefault();
        const token = await authService.getAccessToken();
        if (token.length > 0) {
            this.setState({ goodShow: true });
        }
    }

    async saveGood(e) {
        e.preventDefault();

        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var that = this;

            var order_data = {
                ItemsAmount: this.state.salePrice,
                AcceptStorageID: this.state.selectedAcceptPoint,
                GiveoutStorageID: this.state.selectedGiveoutPoint,
                Items: this.state.selectedSaleItems,
                BSale: false,
                BForRentShelf: true
            };

            if (order_data.ItemsAmount > 0) {

                if (order_data.Items.length > 0) {

                    this.setState({ loading: true, goodShow: false });

                    await fetch('orders/save_good',
                        {
                            method: "POST",
                            body: JSON.stringify(order_data),
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        }).
                        then(response => response.text())
                        .then(data => {
                            var order = JSON.parse(data);
                            if (order != null) {
                                that.setState({ showAlert: true, alertText: order.error, loading: false, saleShow: false, changeMode: true });
                            }
                            else {
                                that.setState({ showAlert: true, alertText: "Произошла неизвестная ошибка", loading: false, saleShow: false });
                            }
                        });
                }
                else {
                    alert("Введите наименование.")
                }
            }
            else {
                alert("Введите цену.")
            }
        }
    }

    async saveSale(e) {
        e.preventDefault();

        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var that = this;

            var order_data = {
                ItemsAmount: this.state.salePrice,
                AcceptStorageID: this.state.selectedAcceptPoint,
                GiveoutStorageID: this.state.selectedGiveoutPoint,
                Items: this.state.selectedName,
                Description: this.state.description,
                BSale: true,
                BForRentShelf: false,
                OwnerID: this.state.selectedOwnerID,
                SaleComission: this.state.selectedSaleComission
            };

            if (order_data.ItemsAmount > 0) {

                if (order_data.Items.length > 0) {

                    this.setState({ loading: true, saleShow: false });

                    await fetch('orders/save_sale',
                        {
                            method: "POST",
                            body: JSON.stringify(order_data),
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        }).
                        then(response => response.text())
                        .then(data => {
                            var order = JSON.parse(data);
                            if (order != null) {
                                that.setState({ showAlert: true, alertText: order.error, loading: false, saleShow: false, changeMode: true });
                            }
                            else {
                                that.setState({ showAlert: true, alertText: "Произошла неизвестная ошибка", loading: false, saleShow: false });
                            }
                        });
                }
                else {
                    alert("Введите наименование.")
                }
            }
            else {
                alert("Введите цену.")
            }
        }
    }

    async clickGroup(e) {
        e.preventDefault();
        var that = this;
        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var ids = new Array();
            this.state.orders.forEach(function (el) {
                if (el.checked) {
                    ids.push(el.orderID);
                }
            });

            if (ids.length > 0) {

                this.state.orders.forEach(function (el) {
                    el.checked = false;
                });

                const { orders } = this.state;

                this.setState({ loading: true, allChecked: false, orders: orders, stickersDisabled: true, groupDisabled: true });

                await fetch('orders/group_orders',
                    {
                        method: "POST",
                        body: JSON.stringify(ids),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {
                        if (data.indexOf("ERROR:") == 0) {
                            that.setState({ showAlert: true, alertText: data.replace("ERROR:", ""), loading: false });
                        }
                        else {
                            that.setState({ loading: false }, function () {
                                that.onSearch(that.state.search);
                            });
                        }
                    });

            }
        }
    }

    async clickStickers(e) {
        e.preventDefault();

        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var ids = new Array();
            this.state.orders.forEach(function (el) {
                if (el.checked) {
                    ids.push(el.orderID);
                }
            });

            if (ids.length > 0) {

                this.state.orders.forEach(function (el) {
                    el.checked = false;
                });

                const { orders } = this.state;

                this.setState({ loading: true, allChecked: false, orders: orders, stickersDisabled: true, groupDisabled: true });

                fetch('orders/stickers?ids=' + ids.join(','), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/pdf',
                        'Authorization': `Bearer ${token}`
                    },
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                            new Blob([blob]),
                        );
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                            'download',
                            `Stickers.pdf`,
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);

                        this.setState({ loading: false });

                    });
            }
        }
    }

    async clickDelete(e) {
        e.preventDefault();

        var that = this;
        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var ids = new Array();
            this.state.orders.forEach(function (el) {
                if (el.checked) {
                    ids.push(el.orderID);
                }
            });

            if (ids.length > 0) {

                this.state.orders.forEach(function (el) {
                    el.checked = false;
                });

                const { orders } = this.state;

                this.setState({ loading: true, allChecked: false, orders: orders, stickersDisabled: true, groupDisabled: true });

                fetch('orders/delete',
                    {
                        method: "POST",
                        body: JSON.stringify(ids),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {
                        that.setState({ loading: false }, function () {
                            that.onSearch("");
                        });
                    });
            }
        }
    }

    handleUploadOrdersChange(e) {
        this.setState({ uploadedOrders: e.target.value });
    }

    async moveToSell(e) {
        var that = this;

        var ids = new Array();
        var selected_orders = new Array();
        this.state.orders.forEach(function (el) {
            if (el.checked) {
                ids.push(el.orderID);
                selected_orders.push(el);
            }
        });

        if (ids.length == 1) {

            this.state.orders.forEach(function (el) {
                el.checked = false;
            });

            const token = await authService.getAccessToken();
            if (token.length > 0) {

                this.setState({ loading: true, moveToSellVisible:false });

                fetch('orders/move_to_sale',
                    {
                        method: "POST",
                        body: JSON.stringify({ "ids": ids, name: this.state.selectedSaleItems, price: this.state.salePrice }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {
                        that.setState({ loading: false, moveToSellVisible: false }, function () {
                            that.onSearch("");
                        });
                    });
            }
        }
    }

    async clickMoveToSellItems(e) {
        e.preventDefault();
        var that = this;
        var ids = new Array();
        var selected_orders = new Array();
        var item_name = "";
        this.state.orders.forEach(function (el) {
            if (el.checked) {
                ids.push(el.orderID);
                selected_orders.push(el);
                item_name = el.clientName;
            }
        });

        if (ids.length == 1) {
            this.setState({ moveToSellVisible: true, selectedSaleItems: item_name});
        }
    }

    async clickMakeOrderFromRentItems(e) {
        e.preventDefault();
        var that = this;
        var ids = new Array();
        var selected_orders = new Array();
        this.state.orders.forEach(function (el) {
            if (el.checked) {
                ids.push(el.orderID);
                selected_orders.push(el);
            }
        });

        if (ids.length > 0) {
            this.state.orders.forEach(function (el) {
                el.checked = false;
            });

            const { orders } = this.state;

            this.setState({ loading: false, allChecked: false, orders: orders, makeOrderFromRentItemsDisabled: true }, function () {
                contextData.selectedGoods = selected_orders;
                this.props.history.push("/add-clients");
            });
        }
    }

    async clickMakeOrderFromRentItemsOld(e) {
        e.preventDefault();
        var that = this;
        const token = await authService.getAccessToken();
        if (token.length > 0) {
            var ids = new Array();
            var cl = {};
            this.state.orders.forEach(function (el) {
                if (el.checked) {
                    ids.push(el.orderID);
                }
            });

            if (ids.length > 0) {

                this.state.orders.forEach(function (el) {
                    el.checked = false;
                });

                const { orders } = this.state;

                this.setState({ loading: true, allChecked: false, orders: orders, makeOrderFromRentItemsDisabled: true });

                fetch('orders/move_to_order',
                    {
                        method: "POST",
                        body: JSON.stringify({ "ids": ids, "cl": cl }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {
                        that.setState({ loading: false }, function () {
                            that.onSearch("");
                        });
                    });
            }
        }
    }

    async clickReturn(e) {
        e.preventDefault();

        var that = this;
        const token = await authService.getAccessToken();
        if (token.length > 0) {

            var ids = new Array();
            this.state.orders.forEach(function (el) {
                if (el.checked) {
                    ids.push(el.orderID);
                }
            });

            if (ids.length > 0) {

                this.state.orders.forEach(function (el) {
                    el.checked = false;
                });

                const { orders } = this.state;

                this.setState({ loading: true, allChecked: false, orders: orders, returnDisabled: true });

                fetch('orders/return',
                    {
                        method: "POST",
                        body: JSON.stringify(ids),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {
                        that.setState({ loading: false }, function () {
                            that.onSearch("");
                        });
                    });
            }
        }
    }

    onSearch(e) {
        this.state.pagination.current = 1;

        this.setState({ search: e });

        const { pagination, mode } = this.state;
        const search = e;

        this.fetchData({ pagination, search, mode });
    }

    modeChanged(e) {
        var that = this;
        var acv = true;
        var ordersVisible = true;
        var uploadOrdersVisible = false;
        var uploadedOrdersVisible = false;
        var contactsVisible = false;
        var moveToSellItemsVisible = this.parcelsMode == MODE_STORAGE && e == 2;

        if (this.state.ordersSelectionMode) {
            moveToSellItemsVisible = false;
        }

        if (e == 3 || e == 4) {
            acv = false;
        }

        if (e == 5) {
            ordersVisible = false;
            uploadOrdersVisible = true;
        }

        if (e == 6) {
            ordersVisible = false;
            contactsVisible = true;
        }

        var stickersVisible = e == 1;
        var hideVisible = e == 1;
        var groupVisible = false;

        if (e == 2) {
            if (this.parcelsMode == MODE_SALE) {
                stickersVisible = true;
                groupVisible = true;
            }
        }

        this.setState({
            changeMode: false,
            mode: e,
            makeOrderFromRentItemsVisible: e == 2 && this.parcelsMode == MODE_STORAGE && this.state.ordersSelectionMode == true,
            moveToSellItemsVisible: moveToSellItemsVisible,
            returnVisible: e == 2 && (this.parcelsMode == MODE_PARCELS || this.parcelsMode == MODE_SALE),
            makeOrderFromRentItemsDisabled: this.selectionCount() == 0,
            returnDisabled: this.selectionCount() == 0,
            stickersVisible: stickersVisible,
            stickersDisabled: this.selectionCount() == 0,
            groupDisabled: this.selectionCount() < 2,
            allCheckedVisible: acv, ordersVisible: ordersVisible,
            uploadOrdersVisible: uploadOrdersVisible,
            uploadedOrdersVisible: uploadedOrdersVisible,
            hideVisible: hideVisible,
            groupVisible: groupVisible,
            contactsVisible: contactsVisible
        }, function () {
            if (e < 5) {
                const { pagination, mode } = that.state;
                that.fetchData({ pagination, mode });
            }
            else {
                if (e == 6) {
                    const { contactsPagination, mode } = that.state;
                    that.fetchContacts({ contactsPagination, mode });
                }
            }
        });
    }

    renderOrderDetailsLink(text, record) {
        var lk = "";

        lk = <a target="_blank" href={`/manage-orders/parceldetails/${record.orderID}`}>{text}</a>;

        return lk;
    }

    renderOrderDetailsGroupLink(text, record) {
        var lk = "";

        if (record.groupID != null) {
            lk = <a target="_blank" href={`/manage-orders/parceldetails/${record.groupID}`}>{text}</a>;
        }

        return lk;
    }

    renderOrderStatus(text, record) {
        var st = "";

        if (record.error == "OK") {
            st = <CheckOutlined />;
        }
        else {
            st = record.error;
        }

        return st;
    }

    handleAlertClose = () => {

        this.setState({ showAlert: false, alertText: "" }, function () {
            if (this.state.changeMode) {
                this.modeChanged(1);
            }
        });
    };

    async fetchAcceptPoints(mode) {

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            this.setState({ loading: true });
            reqwest({
                url: 'clients/acceptpoints?mode=' + mode,
                method: 'get',
                type: 'json',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(data => {

                this.setState({
                    accept_points: data,
                    giveout_points: data
                });

                if (data.length > 0) {

                    var cities = new Array();

                    var selectedShopCityID = 0;
                    var selectedAcceptPoint = data[0].storageID;
                    var selectedAcceptPointCity = data[0].city;
                    var selectedAcceptPointCityID = data[0].cityID;
                    var selectedSaleComission = data[0].saleComission;
                    var selectedGiveoutPoint = data[0].storageID;
                    var selectedOwnerID = data[0].ownerID;

                    for (var i = 0; i < data.length; i++) {
                        cities[data[i].cityID] = data[i].city;

                        if (data[i].shopSelectedCity == true) {
                            selectedShopCityID = data[i].cityID;
                            selectedAcceptPoint = data[i].storageID;
                            selectedAcceptPointCity = data[i].city;
                            selectedAcceptPointCityID = data[i].cityID;
                            selectedSaleComission = data[i].saleComission;
                            selectedGiveoutPoint = data[i].storageID;
                            selectedOwnerID = data[i].ownerID;
                        }
                    }

                    this.setState({
                        selectedAcceptPoint: selectedAcceptPoint,
                        selectedGiveoutPoint: selectedGiveoutPoint,
                        selectedAcceptPointCity: selectedAcceptPointCity,
                        selectedAcceptPointCityID: selectedAcceptPointCityID,
                        selectedOwnerID: selectedOwnerID,
                        selectedClientCity: selectedAcceptPointCity,//data[0].city,
                        selectedClientCityID: selectedAcceptPointCityID,//data[0].cityID,
                        cities: cities,
                        selectedShopCityID: selectedShopCityID,
                        selectedSaleComission: selectedSaleComission
                    });
                }
            });
        }

    }

    render() {

        const { loading, orders, pagination, uploaded_orders_items, showAlert, alertText, contacts_items } = this.state;
        const cols = this.columns;
        this.uploaded_orders_columns = [];

        if (this.parcelsMode == MODE_PARCELS) {
            this.uploaded_orders_columns = [
                {
                    title: '№',
                    dataIndex: 'orderID',
                },
                {
                    title: 'ФИО',
                    dataIndex: 'clientName',
                },
                {
                    title: 'Ник',
                    dataIndex: 'clientNick',
                },
                {
                    title: 'Телефон',
                    dataIndex: 'clientPhone',
                },
                {
                    title: 'Сумма',
                    dataIndex: 'totalAmount',
                    align: 'right',
                },
                {
                    title: 'ПВЗ',
                    dataIndex: 'giveoutStorageName',
                },
                {
                    title: 'Город',
                    dataIndex: 'giveoutStorageCity',
                },
                {
                    title: 'Статус',
                    dataIndex: 'error',
                    render: (text, record) => (this.renderOrderStatus(text, record))
                },
            ];
        }
        else {
            this.uploaded_orders_columns = [
                {
                    title: '№',
                    dataIndex: 'orderID',
                },
                {
                    title: 'Описание товара',
                    dataIndex: 'items',
                },
                {
                    title: 'Стоимость товара',
                    dataIndex: 'totalAmount',
                    align: 'right',
                },
                {
                    title: 'ПВЗ',
                    dataIndex: 'giveoutStorageName',
                },
                {
                    title: 'Город',
                    dataIndex: 'giveoutStorageCity',
                },
                {
                    title: 'Статус',
                    dataIndex: 'error',
                    render: (text, record) => (this.renderOrderStatus(text, record))
                },
            ];
        }
        const uploaded_orders_cols = this.uploaded_orders_columns;

        this.contacts_columns = [
            {
                title: 'Город',
                dataIndex: 'city',
            },
            {
                title: 'Адрес',
                dataIndex: 'address',
            },
            {
                title: 'Контакт',
                dataIndex: 'contact',
            },
        ];

        const contacts_cols = this.contacts_columns;

        this.columns = [
            {
                width: this.parcelsMode == MODE_SALE ? "50px" : "1px",
                title: this.parcelsMode == MODE_SALE ? 'Группа' : '',
                dataIndex: 'groupID',
                render: (text, record) => (this.renderOrderDetailsGroupLink(text, record))
            },
            {
                title: '№',
                dataIndex: 'orderID',
                render: (text, record) => (this.renderOrderDetailsLink(text, record))
            },
            {
                title: this.parcelsMode == MODE_PARCELS ? 'Клиент' : 'Товар',
                dataIndex: 'clientName',
                align: 'left',
            },
            {
                title: '',
                dataIndex: 'clientNick',
                width: this.state.showNick ? 70 : 0,
                render: (text, record) => (this.state.showNick ? record.clientNick : "")
            },
            {
                title: 'Сумма',
                dataIndex: 'totalAmount',
                align: 'right',
            },
            {
                title: this.parcelsMode == MODE_SALE ? 'Магазин' : 'ПВЗ',
                dataIndex: 'giveoutStorageName',
                align: 'right',
            },
            {
                title: 'Статус',
                dataIndex: 'status',
            },
            {
                title: 'Дата',
                dataIndex: 'statusTimeString',
            },
            {
                title: <Checkbox className="select-all-orders" style={{ display: this.state.allCheckedVisible ? "block" : "none" }} checked={this.state.allChecked} name="selectAllOrders" id="selectAllOrders" onChange={this.handleSelectAllOrders}></Checkbox>,
                render: (text, record) => (<Checkbox className="select-order" style={{ display: record.selectable ? "block" : "none" }} checked={record.checked ? true : false} value={record.orderID} onChange={(e) => this.handleSelectOrder(record, e)}></Checkbox >),
                align: 'center',
            },
        ];

        return (
            <div>
                <h2>{this.displayName}</h2>
                <div style={{ paddingTop: 10 }}>

                    <Tabs defaultActiveKey="1" onChange={this.modeChanged} activeKey={this.state.mode.toString()}>
                        <TabPane tab="Не принятые" key="1"></TabPane>
                        <TabPane tab={this.deliveryTabTitle} key="2"></TabPane>
                        <TabPane tab={this.giveoutTabTitle} key="3"></TabPane>
                        <TabPane tab="Возврат" key="4"></TabPane>
                        <TabPane tab={this.uploadTitle} key="5"></TabPane>
                        <TabPane tab="Контакты" key="6"></TabPane>
                    </Tabs>

                    <div style={{ display: this.state.ordersVisible ? "block" : "none" }} >
                        <div><h5 style={{ display: this.state.ordersSelectionMode ? "block" : 'none' }} >Выберите товары для: {contextData.selectedClientName}<br /></h5></div>
                        <Search placeholder="Поиск" style={{ width: '300px', paddingTop: 0 }} onSearch={e => this.onSearch(e)} enterButton allowClear />
                        <Button type="primary" onClick={this.clickStickers} style={{ display: this.state.stickersVisible ? "inline" : "none" }} disabled={this.state.stickersDisabled} >{this.stickersLabel}</Button>
                        <Button type="primary" onClick={this.clickGroup} style={{ display: this.state.groupVisible ? "inline" : "none", marginLeft: "2px" }} disabled={this.state.groupDisabled} >Сгруппировать</Button>
                        <Popconfirm title="Скрыть заказы?" okText="Да" cancelText="Нет" onConfirm={this.clickDelete} ><Button style={{ display: this.state.hideVisible ? "inline" : "none", marginLeft: "2px" }} disabled={this.state.stickersDisabled} type="primary" title="Скрыть заказы" >Скрыть</Button></Popconfirm>
                        <Popconfirm title="Вернуть заказы?" okText="Да" cancelText="Нет" onConfirm={this.clickReturn} ><Button style={{ display: this.state.returnVisible ? "inline" : "none", marginLeft: "2px" }} disabled={this.state.returnDisabled} type="primary" title="Запретить выдачу и вернуть продавцу" >Вернуть</Button></Popconfirm>
                        {/*
                        <Popconfirm title="Сформировать заказ?" okText="Да" cancelText="Нет" onConfirm={this.clickMakeOrderFromRentItems} ><Button style={{ display: this.state.makeOrderFromRentItemsVisible ? "inline" : "none" }} disabled={this.state.makeOrderFromRentItemsDisabled} type="primary" title="Сформировать заказ из выбранных товаров" >В заказ</Button></Popconfirm>
                        */}
                        <Button onClick={this.clickMoveToSellItems} style={{ display: this.state.moveToSellItemsVisible ? "inline" : "none", marginLeft: "2px" }} disabled={this.state.moveToSellItemsDisabled} type="primary" title="Выставить на продажу" >На продажу</Button>
                        <Button onClick={this.clickMakeOrderFromRentItems} style={{ display: this.state.makeOrderFromRentItemsVisible ? "inline" : "none", marginLeft: "2px" }} disabled={this.state.makeOrderFromRentItemsDisabled} type="primary" title="Сформировать заказ из выбранных товаров" >В заказ</Button>
                        <Button onClick={this.clickShelfsManage} style={{ display: this.state.manageShelfsVisible ? 'inline-block' : 'none', marginLeft: "2px" }}>Управление полками</Button>
                        <Button onClick={this.clickShelfsRent} style={{ display: this.parcelsMode == MODE_STORAGE ? 'inline-block' : 'none', marginLeft: "2px" }}>Аренда полок</Button>
                        <Table
                            style={{ marginTop: 10 }}
                            locale={{ emptyText: 'Нет данных' }}
                            columns={cols}
                            loading={loading}
                            dataSource={orders}
                            pagination={pagination}
                            rowKey={record => record.orderID}
                            onChange={this.handleTableChange}
                        />
                    </div>
                    <div style={{ display: this.state.uploadOrdersVisible ? "block" : "none" }}>
                        <Button type="primary" onClick={this.addSale} style={{ display: this.parcelsMode != MODE_SALE ? "none" : "inline", marginLeft: "2px", marginTop: "2px" }} >Загрузить товар</Button>
                        <Button type="primary" onClick={this.addGood} style={{ display: this.parcelsMode != MODE_STORAGE ? "none" : "inline", marginLeft: "2px", marginTop: "2px" }} >Загрузить товар</Button>
                        <Button type="primary" onClick={this.uploadOrders} style={{ marginLeft: "2px", marginTop: "2px" }}>{this.uploadButtonTitle}</Button>
                        <Button type="primary" onClick={this.downloadOrdersTemplate} style={{ marginLeft: "2px", marginTop: "2px" }}>Скачать шаблон</Button>

                        <TextArea rows={24} style={{ marginTop: 10 }} placeholder='Вставьте список товаров, включая заголок, оформленный по шаблону и нажмите кнопку "Загрузить список товаров"' onChange={e => this.handleUploadOrdersChange(e)} value={this.state.uploadedOrders} />
                    </div>

                    <div style={{ display: this.state.uploadedOrdersVisible ? "block" : "none" }}>
                        <Button type="primary" disabled={this.state.createOrdersDisabled} onClick={this.uploadOrders}>{this.uploadOrdersLabel}</Button>
                        <Table
                            style={{ marginTop: 10 }}
                            locale={{ emptyText: 'Нет данных' }}
                            columns={uploaded_orders_cols}
                            dataSource={uploaded_orders_items}
                            rowKey={record => record.orderNo}
                            loading={loading}
                        />
                    </div>
                    <div style={{ display: this.state.contactsVisible ? "block" : "none" }}>
                        <Table
                            style={{ marginTop: 10 }}
                            locale={{ emptyText: 'Нет данных' }}
                            columns={contacts_cols}
                            dataSource={contacts_items}
                            rowKey={record => record.contactNo}
                            loading={loading}
                        />
                    </div>
                </div>
                {this.state.giveout_points.length > 0 ?
                    <div>
                        <Modal
                            title='Новый товар'
                            visible={this.state.saleShow}
                            onCancel={this.setSaleShowToggle}
                            footer={[
                                <Button key="back" onClick={this.setSaleShowToggle}>Закрыть</Button>,
                                <Button key="ok" onClick={this.saveSale} type="primary">Сохранить</Button>
                            ]}
                        >
                            <Form layout="vertical">
                                <Form.Item label="Пункт продажи:">
                                    <Select value={this.state.selectedGiveoutPoint} onChange={e => { this.handleGiveoutPointChange(e) }}>
                                        {
                                            this.state.giveout_points.map((e, key) => {
                                                return <Option value={e.storageID}>{e.name + " " + e.city + ", " + e.address}</Option>;
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Наименование:">
                                    <Input value={this.state.selectedName} style={{ width: "300px" }} onChange={e => { this.handleNameChange(e) }} >
                                    </Input>
                                </Form.Item>
                                <Form.Item label="Описание:">
                                    <TextArea value={this.state.description} style={{ width: "300px", height: "100px", fontSize: this.state.stickerFontSize }} onChange={e => { this.handleDescriptionChange(e) }} >
                                    </TextArea>
                                </Form.Item>
                                <Form.Item label="Стоимость товара для покупателя:">
                                    <Input type="text" style={{ width: "100px" }} onChange={e => { this.handleSalePriceChange(e) }} ></Input>
                                </Form.Item>
                                <Form.Item label="Комиссия:">
                                    <Input type="text" disabled="disabled" style={{ width: "100px" }} value={this.state.saleComission} ></Input>
                                </Form.Item>
                                <Form.Item label="К выплате:">
                                    <Input type="text" disabled="disabled" style={{ width: "100px" }} value={this.state.salePayoutPrice} ></Input>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            title='Новый товар'
                            visible={this.state.goodShow}
                            onCancel={this.setGoodShowToggle}
                            footer={[
                                <Button key="back" onClick={this.setGoodShowToggle}>Закрыть</Button>,
                                <Button key="ok" onClick={this.saveGood} type="primary">Сохранить</Button>
                            ]}
                        >
                            <Form layout="vertical">
                                <Form.Item label="Пункт хранения:">
                                    <Select value={this.state.selectedGiveoutPoint} onChange={e => { this.handleGiveoutPointChange(e) }}>
                                        {
                                            this.state.giveout_points.map((e, key) => {
                                                return <Option value={e.storageID}>{e.name + " " + e.city + ", " + e.address}</Option>;
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Наименование:">
                                    <TextArea rows="1" value={this.state.selectedSaleItems} onChange={e => { this.setState({ selectedSaleItems: e.target.value }) }} ></TextArea>
                                </Form.Item>
                                <Form.Item label="Стоимость товара:">
                                    <Input style={{ width: "100px" }} type="text" onChange={e => { this.handleSalePriceChange(e) }} ></Input>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                    : <Modal></Modal>
                }

                <Modal
                    title="Переместить на продажу"
                    visible={this.state.moveToSellVisible}
                    footer={[
                        <Button key="back" onClick={e => { this.setState({ moveToSellVisible: false }) }}>Закрыть</Button>,
                        <Button key="ok" onClick={e => { this.moveToSell(e) }} type="primary">Сохранить</Button>
                    ]}
                >
                    <Form layout="vertical">
                        <Form.Item label="Наименование:">
                            <TextArea rows="1" value={this.state.selectedSaleItems} onChange={e => { this.setState({ selectedSaleItems: e.target.value }) }} ></TextArea>
                        </Form.Item>
                        <Form.Item label="Стоимость товара:">
                            <Input style={{ width: "100px" }} type="text" onChange={e => { this.handleSalePriceChange(e) }} ></Input>
                        </Form.Item>
                    </Form>
                </Modal>

                <Bootbox show={showAlert}
                    successLabel={"OK"}
                    type={"alert"}
                    message={alertText}
                    onClose={this.handleAlertClose}
                />
            </div>
        );
    }
}

export default ManageOrders;
