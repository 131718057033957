import React, { Component, PropTypes } from 'react';

export const contextData = {
    selectedClientRecord: null,
    selectedClientID: 0,
    selectedClientName: "",
    selectedGoods: [],
};

export const dataContext = React.createContext('');

