import React, { Component, PropTypes } from 'react';
import { dataContext, contextData } from './DataContext';
import authService from './api-authorization/AuthorizeService'
import { Table, Input, Button, Modal, Form, Select, Alert, Popconfirm, Badge, Checkbox, Tabs, Spin } from 'antd'
import reqwest from 'reqwest'
import Bootbox from 'bootbox-react';
import { DeleteOutlined, RightCircleFilled } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { FileAddOutlined } from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Redirect } from 'react-router-dom';
import { InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
//import { FetchData } from './FetchData';
const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

export class Cabinet extends Component {
    static displayName = "Кабинет";

    constructor(props) {
        super(props);

        this.modeChanged = this.modeChanged.bind(this);
        this.handleErrors = this.handleErrors.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.saveConditions = this.saveConditions.bind(this);
        this.conditionChanged = this.conditionChanged.bind(this);

        this.state = {
            loading: false,
            mode: 1,
            username: "",
            user: {},
            conditions: "",
            selected_condition: 0
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    handleErrors(response) {
        if (!response.ok) {
            if (response.status == 401) {
                this.redirectToLogin();
            }
        }
        return response;
    }

    conditionChanged(e) {

        var condition = 0;
        if (e.target.checked) {
            if (e.target.value == "RB_STANDART") {
                condition = 0;
            }
            if (e.target.value == "RB_SPECIAL") {
                condition = 1;
            }
            if (e.target.value == "RB_PINCODE") {
                condition = 2;
            }
            if (e.target.value == "RB_PINCODE_AND_STANDART") {
                condition = 3;
            }

            this.setState({ selected_condition: condition });
        }
    }

    async saveConditions() {
        const token = await authService.getAccessToken();
        if (token.length > 0) {
            this.setState({ loading: true });

            var cnd = { condition: this.state.selected_condition, conditions: this.state.conditions };

            await fetch('cabinet/save_conditions',
                {
                    method: "POST",
                    body: JSON.stringify(cnd),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }).
                then(this.handleErrors)
                .then(response => response.text())
                .then(data => {

                    if (data != null && data.length > 0) {
                        if (data != "OK") {
                            alert(data);
                        }
                    }

                    this.setState({ loading: false });
                });
        }
    }

    async saveUser() {
        const token = await authService.getAccessToken();
        var that = this;

        if (this.state.mode == 3) {
            return;
        }

        if (token.length > 0) {

            this.state.user.mode = this.state.mode;

            this.setState({ loading: true });
            await fetch('cabinet/save_user',
                {
                    method: "POST",
                    body: JSON.stringify(this.state.user),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }).
                then(this.handleErrors)
                .then(response => response.text())
                .then(data => {

                    if (data != null && data.length > 0) {
                        var user = JSON.parse(data);
                        if (user.error != "OK") {
                            alert(user.error);
                            this.setState({ loading: false, user: user });
                        }
                    }

                    this.setState({ loading: false });
                });
        }
    }

    async getUser() {
        const token = await authService.getAccessToken();
        var that = this;

        if (token.length > 0) {
            this.setState({ loading: true });
            await fetch('cabinet/user',
                {
                    method: "GET",
                    headers: {
                        //'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }).
                then(this.handleErrors)
                .then(response => response.text())
                .then(data => {

                    var user = {};
                    if (data != null && data.length > 0) {
                        user = JSON.parse(data);
                        this.setState({ loading: false, user: user, selected_condition: user.conditions.condition, conditions: user.conditions.conditions });
                        user.new_email = "";
                        user.old_password = "";
                        user.new_password = "";
                        user.new_password_confirm = "";
                    }
                });
        }
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated: isAuthenticated,
            username: user.name
        });

        if (isAuthenticated) {

            this.getUser();

        }
        else {
            this.redirectToLogin();
        }
    }

    redirectToLogin() {

        this.props.history.push("/authentication/login");
    }

    modeChanged(e) {

        if (e == 3) {
            //this.props.history.push("/Identity/Account/Manage/ChangePassword");
            window.location.href = "/Identity/Account/Manage/ChangePassword";
        }
        else {
            this.setState({ mode: e });
        }
    }

    render() {

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Tabs defaultActiveKey="1" onChange={this.modeChanged} activeKey={this.state.mode.toString()}>
                        <TabPane tab="Профиль" key="1">
                            <Form layout="vertical" size="small" >
                                <Form.Item label="Активность:">
                                    <b>{this.state.user.activity}</b>
                                </Form.Item>
                                <Form.Item label="ИД пользователя:">
                                    <b>{this.state.user.userID}</b>
                                </Form.Item>
                                <Form.Item label="Логин:">
                                    <b>{this.state.user.userName}</b>
                                </Form.Item>
                                <Form.Item label="Название магазина:" style={{ display: this.state.user.role == "Shop" ? "inline-block" : "none" }}>
                                    <Input type="text" style={{ width: "200px" }} value={this.state.user.shopName} onChange={e => { var user = this.state.user; user.shopName = e.target.value; this.setState({ user: user }) }} ></Input>
                                </Form.Item>
                                <Form.Item label="Город:">
                                    <Input type="text" style={{ width: "200px" }} value={this.state.user.city} onChange={e => { var user = this.state.user; user.city = e.target.value; this.setState({ user: user }) }} ></Input>
                                </Form.Item>
                                <Form.Item label="Номер телефона:">
                                    <Input type="text" style={{ width: "200px" }} value={this.state.user.phone} onChange={e => { var user = this.state.user; user.phone = e.target.value; this.setState({ user: user }) }}></Input>
                                </Form.Item>
                                <Form.Item label="Уведомлять о продажах:" style={{ display: this.state.user.role == "Shop" ? "inline-block" : "none" }}>
                                    <Checkbox checked={this.state.user.notifyOfSale} onChange={e => { var user = this.state.user; user.notifyOfSale = e.target.checked; this.setState({ user: user }) }}></Checkbox>
                                </Form.Item>
                                <Form.Item>
                                    <Button onClick={this.saveUser} type="primary" >Сохранить</Button>
                                </Form.Item>
                            </Form>

                        </TabPane>
                        <TabPane tab="Адрес электронной почты" key="2">
                            <Form layout="vertical" size="small">
                                <Form.Item label="Адрес электронной почты:">
                                    <b>{this.state.user.email}</b>
                                </Form.Item>
                                <Form.Item label="Новый адрес:">
                                    <Input type="text" style={{ width: "200px" }} value={this.state.user.new_email} onChange={e => { var user = this.state.user; user.new_email = e.target.value; this.setState({ user: user }) }}></Input>
                                </Form.Item>
                                <Button type="primary" onClick={this.saveUser} >Сменить адрес</Button>
                            </Form>
                        </TabPane>
                        <TabPane tab="Пароль" key="3">
                            <Form layout="vertical" size="small">
                                <Form.Item label="Текущий пароль:">
                                    <Input type="password" style={{ width: "200px" }} onChange={e => { var user = this.state.user; user.old_password = e.target.value; this.setState({ user: user }) }}></Input>
                                </Form.Item>
                                <Form.Item label="Новый пароль:">
                                    <Input type="password" style={{ width: "200px" }} onChange={e => { var user = this.state.user; user.new_password = e.target.value; this.setState({ user: user }) }}></Input>
                                </Form.Item>
                                <Form.Item label="Новый пароль для проверки:">
                                    <Input type="password" style={{ width: "200px" }} onChange={e => { var user = this.state.user; user.new_password_confirm = e.target.value; this.setState({ user: user }) }}></Input>
                                </Form.Item>
                                <Button type="primary" disabled="disabled" onClick={this.saveUser} >Сменить пароль</Button>
                            </Form>
                        </TabPane>
                        <TabPane tab="Условия выдачи посылок" key="4" disabled={this.state.user.role != "Shop" } style={{ display: this.state.user.role == "Shop" ? "block" : "none" }}>
                            <Form layout="vertical" size="small">
                                <Form.Item label="">
                                    <Input name="RB_CONDITIONS" type="radio" value="RB_STANDART" checked={this.state.selected_condition == 0 ? true : false}  onChange={e => this.conditionChanged(e)} /> <b>Стандарт</b>
                                    <div style={{ width: "600px" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Выдача посылки производится по документу удостоверяющему личность или по фото паспорта. Доверенное лицо может предъявить фото паспорта владельца посылки. Документы могут не запрашиваться при выдаче посылки с наложенным платежом в размере 3000р и более.
                                    </div>
                                </Form.Item>
                                <Form.Item label="">
                                    <Input name="RB_CONDITIONS" type="radio" value="RB_SPECIAL" checked={this.state.selected_condition == 1 ? true : false} onChange={e => this.conditionChanged(e)} /> <b>Специальные</b>
                                    <div style={{ width: "600px" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;При выборе этой опции необходимо вписать условия, на которых будут выдаваться посылки. Стандартные условия при этом не действуют. Ответственность за вручение правильному адресату лежит на отправителе.
                                    </div>
                                    <TextArea rows="3" style={{ width: "600px" }} value={this.state.conditions} onChange={e => { this.setState({ conditions: e.target.value }) }} />
                                </Form.Item>
                                <Form.Item label="">
                                    <Input name="RB_CONDITIONS" type="radio" value="RB_PINCODE" checked={this.state.selected_condition == 2 ? true : false} onChange={e => this.conditionChanged(e)} /> <b>Только по коду</b>
                                    <div style={{ width: "600px", color: "#cccccc"  }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;При выборе этой опции при создании посылки будет генерироваться код, который необходимо сообщить получателю. Посылка будет выдана только при предъявлении кода.
                                    </div>
                                </Form.Item>
                                <Form.Item label=''>
                                    <Input name="RB_CONDITIONS" type="radio" value="RB_PINCODE_AND_STANDART" checked={this.state.selected_condition == 3 ? true : false} onChange={e => this.conditionChanged(e)} /> <b>По "Коду" или по "Стандарту"</b>
                                    <div style={{ width: "600px", color: "#cccccc" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;При выборе этой опции при создании посылки будет генерироваться код, который необходимо сообщить получателю. Посылка будет выдана по коду. либо по стандартным условиям.
                                    </div>
                                </Form.Item>

                                <Form.Item label='Примечания:'>
                                    <div style={{ width: "600px" }}>
                                        1.	Стандартные условия действуют в том случае, если при оформлении были указаны полные ФИО получателя. При отсутствии или указании только имени ответственность за вручение правильному адресату ложится на отправителя.<br/>
                                        2.	Независимо от условий выдачи, сотрудниками пунктов и курьерами будет приложено максимум усилий, для правильной идентификации получателя.<br />
                                        3.	Независимо от условий выдачи получатель должен сообщить оператору какие-либо данные для поиска посылки: номер посылки, ФИО получателя, номер телефона получателя.<br />
                                    </div>
                                </Form.Item>

                                <Button type="primary" onClick={ this.saveConditions }  >Сохранить</Button>
                                <br/>
                                <br />
                            </Form>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        );
    }
}

export default Cabinet;
