import React, { Component, PropTypes } from 'react';
import authService from './api-authorization/AuthorizeService'
import { Table, Input, Button, Modal, Form, Select, Alert, Popconfirm, Badge, Checkbox } from 'antd'
import reqwest from 'reqwest'
import Bootbox from 'bootbox-react';
import { DeleteOutlined, RightCircleFilled } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { FileAddOutlined } from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link, Redirect } from 'react-router-dom';
import { InputGroup, InputGroupAddon, Label } from 'reactstrap';
const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

export class ManageStorage extends Component {
    displayName = "Склад";

    constructor(props) {
        super(props);

        this.clickShelfsRent = this.clickShelfsRent.bind(this);
        this.clickShelfsManage = this.clickShelfsManage.bind(this);

        this.state = {
            loading: false,
            manageShelfsVisible: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated: isAuthenticated,
            username: user.name
        });

        if (isAuthenticated) {

            this.getIsOwner();
        }
        else {
            this.redirectToLogin();
        }
    }

    async getIsOwner() {
        this.setState({ loading: true });

        var that = this;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'storage/is_owner',
                method: 'get',
                type: 'json',
                data: {},
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {

                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                that.setState({
                    manageShelfsVisible: data == true,
                    loading: false,
                }, function () {
                });
            });
        }
    }
    

    redirectToLogin() {

        this.props.history.push("/authentication/login");
    }

    clickShelfsRent() {
        this.props.history.push("/shelfs-rent");
    }

    clickShelfsManage() {
        this.props.history.push("/shelfs");
    }

    render() {
        return (
            <div>
                <h2>{this.displayName}</h2>
                <span>Раздел в разработке.</span>
                <br />
                <Button onClick={this.clickShelfsManage} style={{ display: this.state.manageShelfsVisible ? 'inline-block' : 'none' }}>Управление полками</Button>
                &nbsp;
                <Button onClick={this.clickShelfsRent}>Аренда полок</Button>
            </div>
        )
    }
}

export default ManageStorage;
