import React, { useCallback, useRef, useState, Component } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import authService from './api-authorization/AuthorizeService'
import reqwest from 'reqwest'
import Parser from 'html-react-parser';
import { Label } from 'reactstrap';
import { TreeSelect, Tooltip, Upload, Table, Input, Button, Modal, Form, Select, Alert, Popconfirm, Badge, Checkbox, Radio, Collapse, AutoComplete, Row, Col, Spin } from 'antd'
import Bootbox from 'bootbox-react';
import { PlusOutlined } from "@ant-design/icons";
import { InboxOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
const { Dragger } = Upload;
const type = 'DragableUploadList';

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
    const ref = useRef(null);
    const index = fileList.indexOf(file);

    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};

            if (dragIndex === index) {
                return {};
            }

            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });

    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
    return (
        <div
            ref={ref}
            className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
            style={{
                cursor: 'move',
            }}
        >
            {file.status === 'error' ? errorNode : originNode}
        </div>
    );
};

export class ParcelDetails extends Component {
    static displayName = "Информация о заказе";

    constructor(props) {
        super(props);

        this.clickHandler = this.clickHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleAmount = this.handleAmount.bind(this);
        this.payParcel = this.payParcel.bind(this);
        this.changeAmount = this.changeAmount.bind(this);
        this.saveParcel = this.saveParcel.bind(this);
        this.dismissGroup = this.dismissGroup.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.moveRow = this.moveRow.bind(this);

        var id = this.props.match.params.id;

        var images = [];

        this.state = {
            parcel_id: id,
            isAuthenticated: false,
            details: '<table class="table"><tr><td>' + id + '</td></tr></table>',
            payParcelVisible: false,
            amountToPay: 0,
            amountToPayLeft: 0,
            totalAmountToPay: 0,
            showAlert: false,
            alertText: "",
            loading: true,
            newAmount: 0,
            changeAmountVisible: false,
            newAmountComment: "",
            parcelDetails: "",
            images: images,
            previewVisible: false,
            previewTitle: "",
            previewImage: null,
            token: "",
            categoriesData: null,
            selectedCategory: null,
            selectCategoryVisible: false
        };
    }

    async moveRow(dragIndex, hoverIndex) {

        var that = this;

        var fileList = this.state.images;

        var f1 = fileList[dragIndex];
        var f2 = fileList[hoverIndex];

        //alert(f1.uid + ";" + dragIndex + ";" + f2.uid + ";" + hoverIndex);

        fileList[hoverIndex] = f1;
        fileList[dragIndex] = f2;

        this.setState({ images: fileList });

        this.setState({ loading: true });

        var ids = new Array();

        for (var i = 0; i < fileList.length; i++) {
            ids.push(fileList[i].uid);
        }

        var token = await authService.getAccessToken();

        await fetch('orders/sort_images',
            {
                method: "POST",
                body: JSON.stringify(ids),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).
            then(response => response.text())
            .then(data => {
                that.setState({ loading: false });
            });
    };

/*
    moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = fileList[dragIndex];
            setFileList(
                update(fileList, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [fileList],
    );
*/
    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated
        });

        if (isAuthenticated) {

            this.getParcelDetails();
            this.getCategories();

            var token = await authService.getAccessToken();
            this.setState({
                token: token
            });
        }
        else {
            this.props.history.push("/authentication/login");
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    changeHandler(e) {
        const el = e.target.closest("INPUT");
        if (el && e.currentTarget.contains(el)) {
            if (el.id == "order_name") {
                //el.setAttribute("value", el.value);
                e.preventDefault();
            }
        }
    }

    clickHandler(e) {
        e.preventDefault();
        const el = e.target.closest("BUTTON");
        if (el && e.currentTarget.contains(el)) {
            if (el.id == "pay_parcel") {
                var amt = el.getAttribute("data-amount");

                this.setState({ payParcelVisible: true, amountToPay: 0, amountToPayLeft: amt, totalAmountToPay: amt });
            }

            if (el.id == "change_amount") {
                var amt = el.getAttribute("data-amount");

                this.setState({ changeAmountVisible: true, newAmount: amt, newAmountComment: "" });
            }

            if (el.id == "btn_save") {
                this.saveParcel();
            }

            if (el.id == "set_category") {
                this.setState({ selectCategoryVisible: true });
            }

            if (el.id == "dismiss_group") {
                this.dismissGroup();
            }
        }
    }

    async saveParcel() {

        var that = this;

        const token = await authService.getAccessToken();

        var el = document.getElementById("order_description");
        var details = "";
        if (el != null) {
            details = el.value;
        }

        el = document.getElementById("order_name");
        var name = "";
        if (el != null) {
            name = el.value;
        }

        if (token.length > 0) {
            var order_data = { id: this.state.parcel_id, details: details, name: name, error: "" };

            this.setState({ loading: true });

            await fetch('clients/save_order_details',
                {
                    method: "POST",
                    body: JSON.stringify(order_data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {
                    var order = JSON.parse(data);
                    if (order != null) {
                        if (order.error.length > 0) {
                            that.setState({ showAlert: true, alertText: order.error, loading: false }, function () {

                            });
                        }
                        else {
                            that.setState({ loading: false }, function () {
                                window.location.href = window.location.href;
                            });
                        }
                    }
                    else {

                        that.setState({ showAlert: true, alertText: "Произошла неизвестная ошибка", loading: false, addOrderShow: false });
                    }
                });
        }
    }

    async dismissGroup() {

        var that = this;

        const token = await authService.getAccessToken();

        if (token.length > 0) {
            var id = this.state.parcel_id;

            this.setState({ loading: true });

            await fetch('orders/dismiss_group',
                {
                    method: "POST",
                    body: JSON.stringify(id),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {
                    if (data.indexOf("ERROR:") == 0) {
                        that.setState({ showAlert: true, alertText: data.replace("ERROR:", ""), loading: false }, function () {

                        });
                    }
                    else {
                        that.setState({ loading: false }, function () {
                            window.location.href = window.location.href;
                        });
                    }
                });
        }
    }

    handleAmount(e) {

        var price = parseFloat(e.target.value.replace(",", "."));
        if (!isNaN(price)) {

            price = Math.abs(price);

            this.setState({ amountToPay: price, amountToPayLeft: this.state.totalAmountToPay - price });
        }
        else {
            this.setState({ amountToPay: e.target.value, amountToPayLeft: this.state.totalAmountToPay});
        }
    }

    handleNewAmount(e) {

        var price = parseFloat(e.target.value.replace(",", "."));
        if (!isNaN(price)) {

            price = Math.abs(price);

            this.setState({ newAmount: price });
        }
        else {
            this.setState({ newAmount: e.target.value });
        }
    }

    handleNewAmountComment(e) {

        this.setState({ newAmountComment: e.target.value });
    }

    async payParcel(e) {
        var that = this;
        if (this.state.amountToPay > 0) {
            var price = this.state.amountToPay;
            if (!isNaN(price)){
                if (this.state.amountToPay > 0 && this.state.amountToPayLeft >= 0) {

                    const token = await authService.getAccessToken();

                    if (token.length > 0) {
                        var order_data = { id: this.state.parcel_id, amount: this.state.amountToPay, error: "" };

                        this.setState({ loading: true, payParcelVisible: false });

                        await fetch('clients/pay_parcel',
                            {
                                method: "POST",
                                body: JSON.stringify(order_data),
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`
                                }
                            }).
                            then(response => response.text())
                            .then(data => {
                                var order = JSON.parse(data);
                                if (order != null) {
                                    if (order.error.length > 0) {
                                        that.setState({ showAlert: true, alertText: order.error, loading: false, payParcelVisible: false }, function () {

                                        });
                                    }
                                    else {
                                        that.setState({ loading: false, payParcelVisible: false }, function () {
                                            window.location.href = window.location.href;
                                        });
                                    }
                                }
                                else {

                                    that.setState({ showAlert: true, alertText: "Произошла неизвестная ошибка", loading: false, addOrderShow: false });
                                }
                            });
                    }
                }
                else {
                    if (this.state.amountToPay == 0) {
                        that.setState({ showAlert: true, alertText: "Введите сумму больше нуля" });
                    }
                    else {
                        that.setState({ showAlert: true, alertText: "Введена сумма больше, чем установленный наложенный платеж. Возврат денег получателю через пункт выдачи невозможен. Ввведите сумму равную или меньше суммы наложенного платежа." });
                    }
                }
            }
            else {
                that.setState({ showAlert: true, alertText: "Введите сумму больше нуля" });
            }
        }
        else {
            that.setState({ showAlert: true, alertText: "Введите сумму больше нуля" });
        }
    }

    async setCategory() {
        var that = this;

        const token = await authService.getAccessToken();

        if (token.length > 0) {
            var order_data = { orderID: this.state.parcel_id, amount: this.state.amountToPay, categoryId: this.state.selectedCategory, error: "" };

            this.setState({ loading: true, payParcelVisible: false });

            await fetch('orders/set_category',
                {
                    method: "POST",
                    body: JSON.stringify(order_data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {
                    var order = JSON.parse(data);
                    if (order != null) {
                        if (order.error.length > 0) {
                            that.setState({ showAlert: true, alertText: order.error, loading: false }, function () {

                            });
                        }
                        else {
                            that.setState({ loading: false }, function () {
                                window.location.href = window.location.href;
                            });
                        }
                    }
                    else {

                        that.setState({ showAlert: true, alertText: "Произошла неизвестная ошибка", loading: false });
                    }
                });
        }
    }

    async changeAmount(e) {
        var that = this;

        var price = this.state.newAmount;
        if (!isNaN(price)) {
            const token = await authService.getAccessToken();

            if (token.length > 0) {
                var order_data = { id: this.state.parcel_id, amount: this.state.newAmount, comment: this.state.newAmountComment, error: "" };

                this.setState({ loading: true, payParcelVisible: false });

                await fetch('clients/set_amount',
                    {
                        method: "POST",
                        body: JSON.stringify(order_data),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }).
                    then(response => response.text())
                    .then(data => {
                        var order = JSON.parse(data);
                        if (order != null) {
                            if (order.error.length > 0) {
                                that.setState({ showAlert: true, alertText: order.error, loading: false, changeAmountVisible: false }, function () {

                                });
                            }
                            else {
                                that.setState({ loading: false, changeAmountVisible: false }, function () {
                                    window.location.href = window.location.href;
                                });
                            }
                        }
                        else {

                            that.setState({ showAlert: true, alertText: "Произошла неизвестная ошибка", loading: false, addOrderShow: false });
                        }
                    });
            }
        }
        else {
            that.setState({ showAlert: true, alertText: "Введите сумму" });
        }

    }

    async getCategories() {

        const token = await authService.getAccessToken();

        fetch('orders/get_categories',
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).
            then(response => response.text())
            .then(data => {
                try {
                    var categories = JSON.parse(data);

                    this.setState({ categoriesData: categories });
                }
                catch (exception) {
                    this.setState({ categoriesData: null });
                }
            });

    }

    async getImages() {
        const token = await authService.getAccessToken();

        fetch('orders/get_images/?id=' + this.state.parcel_id,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).
            then(response => response.text())
            .then(data => {
                var images = JSON.parse(data);

                this.setState({ images: images, loading: false });
            });
    }

    async deleteImage(id) {

        const token = await authService.getAccessToken();
        this.setState({ loading: true });

        await fetch('orders/delete_image',
            {
                method: "POST",
                body: JSON.stringify(id),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).
            then(response => response.text())
            .then(data => {
                this.getImages();
            });
    }

    async getParcelDetails() {
        const token = await authService.getAccessToken();
        if (token.length > 0) {

            fetch('orders/parcel_details/?id=' + this.state.parcel_id,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {
                    this.setState({ details: data, loading: true }, function () {

                        const el = document.getElementById("order_name");
                        if (el) {
                            var v = el.getAttribute("defaultvalue");
                            el.setAttribute("value", v);
                        }

                    });

                    this.getImages();
                });
        }
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);

            reader.onerror = (error) => reject(error);
        });

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({ previewVisible: true, previewImage: file.url || file.preview, previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)});
    };

    handleRemove = (file) => {

        if (window.confirm("Удалить " + file.name + "?")) {
            return true;
        }

        return false;
    };

    handleChange(fileList) {

        if (fileList.file != null && fileList.file.status == "removed") {

            this.deleteImage(fileList.file.uid);

            return;
        }

        this.setState({ images: fileList.fileList }, function () {
            if (fileList.file != null && fileList.file.status == "done") {
                this.getImages();
            }
        });
    }

    handleCancel() {
        this.setState({ previewVisible: false, selectCategoryVisible: false });
    }

    onCategoryChange = (newValue, label, extra) => {

        var that = this;
        this.setState({ selectedCategory: newValue, selectCategoryVisible: false }, function () {
            that.setCategory();
        });
    };

    render() {

        const details = Parser(this.state.details);
        const fileList = this.state.images;
        const token = this.state.token;
        const bearer = `Bearer ${token}`;
        const action = `orders/upload_images?parcel_id=${this.state.parcel_id}`;
        var showLeafIcon = true;
        var treeLine = true;

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div
                    style={{
                        marginTop: 8,
                    }}
                >
                    Загрузить
                </div>
            </div>
        );

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <div id='details_content' onClick={this.clickHandler} onChange={this.changeHandler} >

                        {details}

                    </div>
                </Spin>

                <DndProvider backend={HTML5Backend}>
                    <Dragger
                        maxCount={8}
                        multiple
                        action={action}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        onRemove={this.handleRemove}
                        headers={{ Authorization: bearer }}
                        itemRender={(originNode, file, currFileList) => (
                            <DragableUploadListItem
                                originNode={originNode}
                                file={file}
                                fileList={currFileList}
                                moveRow={this.moveRow}
                            />
                        )}
                    >
                        {fileList.length >= 8 ? null : uploadButton}
                    </Dragger>
                </DndProvider>
                <Modal visible={this.state.selectCategoryVisible} title="Выберите категорию" footer={null} onCancel={this.handleCancel}>
                    <div>
                        <TreeSelect
                            listHeight="600px"
                            style={{
                                width: '300px',
                            }}
                            treeLine={
                                treeLine && {
                                    showLeafIcon,
                                }
                            }
                            value={this.state.selectedCategory}
                            dropdownStyle={{
                                maxHeight: "600px",
                                overflow: 'auto',
                            }}
                            treeData={this.state.categoriesData}
                            placeholder="Выберите категорию"
                            onChange={this.onCategoryChange}
                        />
                    </div>
                </Modal>

                <Modal visible={this.state.previewVisible} title={this.state.previewTitle} footer={null} onCancel={this.handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={this.state.previewImage}
                    />
                </Modal>

                <Modal
                    title="Прием оплаты за посылку"
                    visible={this.state.payParcelVisible}
                    footer={[
                        <Button key="ok" onClick={e => { this.payParcel(e) }} type="primary">OK</Button>,
                        <Button key="back" onClick={e => { this.setState({ payParcelVisible: false }) }}>Отмена</Button>
                    ]}
                >
                    <Form layout="vertical">
                        <Form.Item label="Введите сумму полученную от покупателя:">
                            <Input style={{ width: "100px" }} type="text" value={this.state.amountToPay} onChange={e => { this.handleAmount(e) }} ></Input>
                        </Form.Item>
                        <Form.Item label="Итого к оплате при получении:">
                            <Label style={{ fontWeight: "bold", fontSize: "1.5em" }}>{this.state.amountToPayLeft}</Label>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="Изменение суммы"
                    visible={this.state.changeAmountVisible}
                    footer={[
                        <Button key="ok" onClick={e => { this.changeAmount(e) }} type="primary">OK</Button>,
                        <Button key="back" onClick={e => { this.setState({ changeAmountVisible: false }) }}>Отмена</Button>
                    ]}
                >
                    <Form layout="vertical">
                        <Form.Item label="Введите сумму:">
                            <Input style={{ width: "100px" }} type="text" value={this.state.newAmount} onChange={e => { this.handleNewAmount(e) }} ></Input>
                        </Form.Item>
                        <Form.Item label="Укажите причину изменения стоимости:">
                            <Input style={{ width: "100%" }} type="text" value={this.state.newAmountComment} onChange={e => { this.handleNewAmountComment(e) }} ></Input>
                        </Form.Item>
                    </Form>
                </Modal>


                <Bootbox show={this.state.showAlert}
                    successLabel={"OK"}
                    type={"alert"}
                    message={this.state.alertText}
                    onClose={(e) => { this.setState({ showAlert: false }) }}
                />

            </div>
        );
    }
}


export default ParcelDetails;
