import React, { Component } from 'react';
import { Drawer, BackTop, Table, Row, Col, Button, Modal, Form, Input, Select, Carousel, Spin, Space } from 'antd'

import { LeftOutlined, PhoneOutlined, RightOutlined, DoubleRightOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { Label } from 'reactstrap';
import reqwest from 'reqwest'

const { TextArea } = Input;
const { Option } = Select;

export class PVZBonus extends Component {
    static displayName = PVZBonus.name;

    constructor(props) {
        super(props);

        this.orders_columns = [
            {
                title: '№',
                dataIndex: 'no',
            },
            {
                title: '№ посылки',
                dataIndex: 'id',
                align: 'center',
            },
            {
                title: 'Дата регистрации',
                dataIndex: 'time',
            },
        ];

        this.winners_columns = [
            {
                title: '№',
                dataIndex: 'no',
            },
            {
                title: '№ посылки',
                dataIndex: 'id',
                align: 'center',
            },
            {
                title: 'Выигрыш',
                dataIndex: 'win',
            },
        ];

        var rules_visible = false;
        if (this.props.location.pathname.indexOf("/rules") > 0) {
            rules_visible = true;
        }

        this.state = {
            loading: false,
            phone: "",
            orders: [],
            winners: [],
            ptcount: null,
            wncount: null,
            rules_visible: rules_visible,
            drawNumber: ".",
            currentDrawNumber: "."
        };

        this.searchOrders = this.searchOrders.bind(this);

        this.prevDraw = this.prevDraw.bind(this);
        this.nextDraw = this.nextDraw.bind(this);
        this.rulesClosed = this.rulesClosed.bind(this);

    }

    async getParticipantsCount() {

        var that = this;

        await fetch('pvzbonus/ptcount',
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                }
            }).
            then(response => response.text())
            .then(data => {
                var cnt = data;

                that.setState({ ptcount: "В тираже участвует посылок: " + cnt});
            });
    }

    async getDrawNumber() {

        var that = this;

        await fetch('pvzbonus/draw',
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                }
            }).
            then(response => response.text())
            .then(data => {
                var draw = data;
                that.setState({ drawNumber: draw, currentDrawNumber: draw });
            });
    }

    async getWinners() {
        var that = this;
        if (this.state.drawNumber != this.state.currentDrawNumber) {

            const phone = this.state.phone;
            var search = "";
            if (this.validatePhone(phone)) {
                if (phone.length == 10) {
                    search = phone;
                }
            }

            this.setState({ loading: true, winners: [] });
            var dn = this.state.drawNumber;
            reqwest({
                url: 'pvzbonus/winners',
                method: 'get',
                type: 'json',
                data: { draw: dn, search: search },
                headers: {
                },
                error: function (err) {
                    that.setState({ loading: false });
                }
            }).then(data => {

                if (data != null) {
                    that.setState({ loading: false, winners: data.results });
                }
                else {
                    that.setState({ loading: false, winners: [] });
                }
            });
        }
    }

    async searchOrders() {

        var that = this;

        if (this.state.drawNumber == this.state.currentDrawNumber) {
            const phone = this.state.phone;
            if (this.validatePhone(phone)) {
                if (phone.length == 10) {
                    this.setState({ loading: true, orders: [] });

                    reqwest({
                        url: 'pvzbonus/search',
                        method: 'get',
                        type: 'json',
                        data: { search: phone },
                        headers: {
                        },
                        error: function (err) {
                            that.setState({ loading: false });
                        }
                    }).then(data => {

                        if (data != null) {
                            that.setState({ loading: false, orders: data.results });

                            that.getParticipantsCount();
                        }
                        else {
                            that.setState({ loading: false, orders: [] });
                        }
                    });
                }
            }
        }
        else {
            this.getWinners();
        }
    }

    validatePhone(v) {

        if (v.length > 0) {
            for (var i = 0; i < v.length; i++) {
                if (v[i] < '0' || v[i] > '9') {
                    return false;
                }
            }

            return true;
        }
        else {
            return true;
        }
    }

    handlePhoneChange(e) {

        var v = e.target.value;

        if (v.length == 1) {
            if (v == '8' || v == '7') {
                v = "";
                this.setState({ phone: v });
                return;
            }
        }

        if (this.validatePhone(v)) {
            if (v.length <= 10) {
                this.setState({ phone: v });
            }
        }
    }

    rulesClosed() {

        if (this.props.location.pathname.indexOf("/rules") > 0) {
            this.props.history.push("/hl/alizon");
        }

        this.setState({ rules_visible: false });
    }

    componentDidMount() {
        this.getParticipantsCount();
        this.getDrawNumber();
    }

    nextDraw() {
        var dn = parseInt(this.state.drawNumber);
        var cdn = parseInt(this.state.currentDrawNumber);
        if (!isNaN(dn)) {
            dn = dn + 1;
            if (dn >= cdn) {
                dn = cdn;
            }

            this.setState({ drawNumber: dn }, function () {
                if (dn != cdn) {
                    this.getWinners();
                }
            });
        }
    }

    prevDraw() {
        var dn = parseInt(this.state.drawNumber);
        var cdn = parseInt(this.state.currentDrawNumber);

        if (!isNaN(dn)) {
            dn = dn - 1;
            if (dn < 1) {
                dn = 1;
            }

            this.setState({ drawNumber: dn }, function () {
                if (dn != cdn) {
                    this.getWinners();
                }
            });
        }

    }

    /*
                    <Spin spinning={this.state.loading}>
                </Spin>

*/

    render() {

        const orders_cols = this.orders_columns;
        const orders = this.state.orders;
        const winners_cols = this.winners_columns;
        const winners = this.state.winners;
        const loading = this.state.loading;
        const ptcount = this.state.ptcount;
        const rules_visible = this.state.rules_visible;
        const drawNumber = this.state.drawNumber;
        const wncount = this.state.wncount;

        var b_draw = this.state.drawNumber == this.state.currentDrawNumber;

        return (
            <div style={{ paddingTop: "10px" }}>
                <Row>
                    <Col xs={0} lg={6} xl={6} md={6}>
                    </Col>

                    <Col xs={24} lg={12} xl={12} md={12}>
                        <img style={{ width: "100%" }} src='/images/alizon.png' />
                        <Form layout="inline">
                            <Form.Item>
                                <Label style={{ fontWeight: "bold" }} >Мои посылки</Label>
                            </Form.Item>
                        </Form>
                        <Form layout="inline">
                            <Form.Item>
                                <Input prefix={<span><PhoneOutlined />+7</span>} value={this.state.phone} style={{ width: "150px" }} onChange={e => this.handlePhoneChange(e)} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="default" icon={<SearchOutlined />} onClick={() => this.searchOrders()} ></Button>
                            </Form.Item>
                            <Form.Item>
                                <Form layout="vertical" size="small" style={{ marginTop: "-10px" }} >
                                    <Form.Item>
                                        <table cellPadding="0" cellSpacing="0">
                                            <tr>
                                                <td colspan="3" style={{ textAlign: "center", padding: "0" }}>Тираж</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "center", padding: "0" }}><Button icon={<LeftOutlined />} type="text" onClick={() => this.prevDraw()} ></Button></td>
                                                <td style={{ textAlign: "center", padding: "0" }}>{ drawNumber }</td>
                                                <td style={{ textAlign: "center", padding: "0" }}><Button icon={<RightOutlined />} type="text" onClick={() => this.nextDraw()} ></Button></td>
                                            </tr>
                                        </table>
                                    </Form.Item>
                                </Form>
                            </Form.Item>
                            <Form.Item align="right" style={{ width: "100%" }} >
                                <Button style={{ float: 'right', transform: "rotate(-90deg)", marginTop: "-55px" }} type="text" onClick={() => this.setState({ rules_visible: true })}>Правила<br /> <DownOutlined /></Button>
                            </Form.Item>
                        </Form>
                        <Form layout="inline" style={{ marginTop: "-30px" }}>
                            <Form.Item>
                                <Label>Введите номер телефона,<br />чтобы увидеть свои посылки</Label>
                            </Form.Item>
                        </Form>

                        <div style={{ maxWidth: "320px", display: b_draw == true ? "block" : "none" }}>
                            <Label>{ptcount}</Label>
                            <Table
                                scroll={{ x: 'max-content' }}
                                size={'small'}
                                locale={{ emptyText: '' }}
                                columns={orders_cols}
                                loading={loading}
                                dataSource={orders}
                                rowKey={record => record.id}
                                pagination={{ pageSize: 1000, disabled: true, style: { display: "none" } }}
                            />
                        </div>
                        <div style={{ maxWidth: "320px", display: b_draw == false ? "block" : "none" }}>
                            <Label>{wncount}</Label>
                            <Label>Главный приз не разыгран и будет перенесен в следующий тираж</Label>
                            <Table
                                scroll={{ x: 'max-content' }}
                                size={'small'}
                                locale={{ emptyText: '' }}
                                columns={winners_cols}
                                loading={loading}
                                dataSource={winners}
                                rowKey={record => record.id}
                                pagination={{ pageSize: 1000, disabled: true, style: { display: "none" } }}
                            />
                        </div>
                    </Col>

                    <Col xs={0} lg={6} xl={6} md={6}>
                    </Col>
                </Row>
                <Drawer
                    size="large"
                    width="100%"
                    title="Правила"
                    placement="right"
                    visible={rules_visible}
                    onClose={() => this.rulesClosed()}
                    closeIcon={<Button style={{ transform: "rotate(-90deg)" }} type="text" >Игра<br /> <DownOutlined /></Button> }
                >

                    1.	В розыгрыше участвуют получатели посылок, подписавшиеся на получение уведомлений от нашего пункта выдачи, получившие приглашение на участие и принявшие его.<br />
                    2.	Розыгрыш производится ежемесячно. В тираже участвуют номера посылок, полученных в течение текущего календарного месяца. Розыгрыш производится каждого 1го числа следующего месяца.<br />
                    3.	Выигрыш можно получить в течение 3х месяцев после окончания тиража при очередном получении посылки в пункте выдачи. Без выдачи посылки выигрыш не выплачивается.<br />
                    4.	Призовой фонд формируется из средств организатора и составляет:<br />
                    <span style={{ textDecoration: "underline" }} >Белый приз</span><br />
                    10 призов. Каждый дает право на бесплатную доставку курьером всех посылок получателя, находящихся на пункте выдачи на момент заказа такой доставки. *<br />
                    Победители определяются случайным образом из числа участников<br />
                    <span style={{ textDecoration: "underline" }} >Синий приз</span><br />
                    10 призов. 100% возмещение стоимости посылки в том случае, если стоимость известна организатору на момент выдачи посылки. Но не более 1000р. Если стоимость посылки не известна (например, посылки, полученные с Aliexpress через компанию DPD, Pickpoint) приз составляет 500р<br />
                    Победители определяются случайным образом из числа участников<br />
                    <span style={{ textDecoration: "underline" }} >Красный приз</span><br />
                    <b>Главный приз от 5000р</b> – победитель определяется голосованием участников. **<br />
                    5.	Налоги с выигрыша победитель рассчитывает и уплачивает самостоятельно.<br />
                    6.	Организатор отправляет победителям уведомление о факте выигрыша на WhatsApp, но не может гарантировать его доставку. Тиражная таблица доступна в течение года после окончания розыгрыша по адресу: <a href='https://stellazhi.net/hl/alizon' >stellazhi.net/HL/Alizon</a> Обязанность победителя самостоятельно узнавать результат розыгрыша.<br />
                    7.	Правила проведения розыгрыша могут быть изменены организатором в одностороннем порядке. Измененные правила будут опубликованы на этой странице.<br />
                    <br />
                    * - Доставка осуществляется в черте г. Владивостока, не далее остановки транспорта Фабрика Заря и без выезда на острова. Для заказа доставки необходимо связаться с оператором по ссылке <a target='_blank' href='https://wa.me/79024830700'>WhatsApp</a>. При заказе назвать номер, выигравший бесплатную доставку. Передача Белого приза иному лицу не допускается.<br />
                    <br />
                    ** - Каждый участник указывает 7 цифр. Цифры, набравшие максимальное количество голосов, составляют выигрышный номер. При получении нескольких комбинация выигрышных номеров выигрыш делится между такими номерами. Если выпавший номер отсутствует среди участников – доля приза, приходящаяся на него, добавляется к главному призу следующего тиража. Организатор к каждому следующему тиражу добавляет, как минимум, 3000р, но так, чтобы величина главного приза получилась не менее 5000р.<br />
                </Drawer>
            </div>
        );
    }
}

export default PVZBonus;
