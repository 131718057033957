import React, { Component } from 'react';
import Bootbox from 'bootbox-react';
import { Drawer, BackTop, Table, Row, Col, Button, Modal, Form, Input, Select, Carousel, Spin, Space, Upload, message, Alert } from 'antd'

export class WAShell extends Component {
    static displayName = WAShell.name;

    constructor(props) {
        super(props);

        this.handleBankCharge = this.handleBankCharge.bind(this);
        this.handleSBOnlineCharge = this.handleSBOnlineCharge.bind(this);
        this.handleReturn = this.handleReturn.bind(this);

        var id = this.props.match.params.id;

        if (id == undefined) {
            if (this.props.location.search != null) {
                var id1 = this.props.location.search.toString().toLowerCase();
                if (id1.length > 0 && id1.indexOf("id=") >= 0) {
                    id = id1.replace("id=", "").replace("?", "");
                }
            }
        }

        this.state = {
            phone: id,
            charge: true,
            sbonline: false,
            bank: false,
            sbonlineid: "",
            rub: 0,
            kop: 0,
            accountHtml: "",
            chargeAmount: 100
        };

    }

    handleReturn() {
        window.location.href = "https://web.whatsapp.com";
    }

    handleChargeAmountChange(e) {

        this.setState({ chargeAmount: e.target.value });
    }

    handleBankCharge() {

        this.generateBankAccount();
    }

    handleSBOnlineCharge() {

        this.generateSBOnlineAccount();
    }

    getBankAccount(id) {
        this.setState({ loading: true });

        fetch('dpddocs/bankaccountblank/?id=' + id + '&mode=2',
            {
                method: "GET",
            }).
            then(response => response.text())
            .then(data => {
                var html = data;
                this.setState({ loading: false, accountHtml: html, bank: true, charge: false, sbonline: false });
            });
    }

    getSBOnlineAccount(id) {
        this.setState({ loading: true });

        fetch('dpddocs/sbonlineaccountblank/?id=' + id + '&mode=2',
            {
                method: "GET",
            }).
            then(response => response.text())
            .then(data => {
                var sb = JSON.parse(data);
                var ks = "";
                var k = parseFloat(sb.kop);
                if (k < 10) {
                    ks = "0" + k;
                }
                else {
                    ks = k;
                }
                this.setState({ sbonlineid: id, loading: false, rub: sb.rub, kop: ks, sbonline: true, bank: false, charge: false, upload: false });
            });
    }

    async generateBankAccount() {
        var phone = this.state.phone;
        var that = this;

        var amt = parseFloat(this.state.chargeAmount);

        if (!isNaN(amt)) {
            this.setState({ loading: true });

            var req = { "phone": phone, "amount": amt, mode: 2 };

            fetch('dpddocs/bankaccount',
                {
                    method: "POST",
                    body: JSON.stringify(req),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).
                then(response => response.text())
                .then(data => {
                    this.setState({ loading: false });

                    var d = JSON.parse(data);

                    if (d.success == true) {
                        this.getBankAccount(d.id);
                    }
                    else {
                        message.success(`Ошибка создания счета: ${d.error}`);
                    }
                });
        }
    }

    async generateSBOnlineAccount() {
        var phone = this.state.phone;
        var that = this;

        var amt = parseFloat(this.state.chargeAmount);
        if (!isNaN(amt)) {
            this.setState({ loading: true });

            var req = { "phone": phone, "amount": amt, mode: 2 };

            fetch('dpddocs/sbonlineaccount',
                {
                    method: "POST",
                    body: JSON.stringify(req),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).
                then(response => response.text())
                .then(data => {
                    this.setState({ loading: false });

                    var d = JSON.parse(data);

                    if (d.success == true) {
                        this.getSBOnlineAccount(d.id);
                    }
                    else {
                        message.success(`Ошибка создания счета: ${d.error}`);
                    }
                });
        }
    }

    render() {

        return (
            <div>
                <br />
                <br />
                <div style={{ display: this.state.charge == true ? "block" : "none" }}>
                    &nbsp;Сумма пополнения:&nbsp;<Input onChange={(e) => this.handleChargeAmountChange(e)} value={this.state.chargeAmount} style={{ width: "100px" }} />&nbsp;р.
                    <br />
                    <br />
                    <Button onClick={() => this.handleBankCharge()}>Пополнить через банк</Button>
                    <br />
                    <br />
                    <Button onClick={() => this.handleSBOnlineCharge()}>Пополнить через Сбербанк Онл@йн</Button>
                    <br />
                    <br />
                    <Button onClick={() => this.handleReturn()}>Вернуться</Button>
                </div>

                <div style={{ display: this.state.bank == true ? "block" : "none" }} >
                    <div dangerouslySetInnerHTML={{ __html: this.state.accountHtml }}>
                    </div>
                    <br />
                    <Button onClick={() => { this.setState({ bank: false, charge: true, sbonline: false }) }} >Вернуться</Button>
                </div>

                <div style={{ display: this.state.sbonline == true ? "block" : "none" }} >
                    <div>
                        <h4>Заявка принята</h4>
                        <div style={{ float: "right", border: "1px dashed #cccccc", padding: "10px" }}>
                            Внимание! При переводе суммы отличной от указанной в счете с точностью до копеек,<br />
                            поступление средств на Л/С не гарантируется.<br />
                            Перевод должен быть осуществлен не позднее трех суток от момента подачи заявки.<br /><br />
                            <a target='_blank' href='https://www.stellazhi.net/contacts'>По вопросам зачисления</a>
                        </div>
                        Счет № {this.state.sbonlineid}<br />
                        Сумма пополнения {this.state.rub} руб {this.state.kop} коп
                        <br /><br />
                        Для автоматического пополнения счета переведите<br /><b>{this.state.rub} руб.</b>&nbsp;<b style={{ color: 'red' }}>{this.state.kop} коп.</b> с вашей карты по указанным ниже реквизитам.
                        <br /><br />
                        Получатель: Сергей Борисович
                        № карты
                        2202 2001 1653 5327
                        <br /><br />
                        <Input type="text" value="2202200116535327" style={{ width: "180px" }} />&nbsp;Копировать здесь
                        <br /><br />
                        Ожидайте поступления средств на свой Л/С в течение 8-ми рабочих часов.
                    </div>
                    <br />
                    <Button onClick={() => { this.setState({ bank: false, sbonline: false, charge: true }) }} >Вернуться</Button>
                </div>
            </div>
        );
    }
}

export default WAShell;
