import React, { Component, PropTypes, useState } from 'react';
import authService from './api-authorization/AuthorizeService'
import { Table, Input, Button, Modal, Form, Select, Alert, Popconfirm, Badge, Checkbox, Typography, InputNumber } from 'antd'
import reqwest from 'reqwest'
import Bootbox from 'bootbox-react';
import { DeleteOutlined, ReconciliationFilled, RightCircleFilled } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { FileAddOutlined } from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Redirect } from 'react-router-dom';
import { InputGroup, InputGroupAddon, Label } from 'reactstrap';
const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;


export class ManageShelfs extends Component {
    displayName = "Управление полками";

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            shelfs: [],
            storages: [],
            selectedShelfID: 0,
            selectedStorageID: null,
            addShelfShow: false,
            addShelfHeader: "Новая полка",
            shelfName: "",
            shelfWidth: 0,
            shelfHeight: 0,
            shelfLength: 0,
            rentPrice: 0,
            showAlert: false,
            alertText: ""
        };

        this.handleAddShelf = this.handleAddShelf.bind(this);
        this.setAddShelfShowToggle = this.setAddShelfShowToggle.bind(this);
        this.saveShelf = this.saveShelf.bind(this);
        this.handleAlertClose = this.handleAlertClose.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])
        this.setState({
            isAuthenticated: isAuthenticated
        });

        if (isAuthenticated) {

            this.getStorages();
        }
        else {
            this.redirectToLogin();
        }
    }

    async getStorages() {

        this.setState({ loading: true });

        var that = this;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'storage/storages',
                method: 'get',
                type: 'json',
                data: {},
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {

                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                var st_id = -1;
                if (data != null && data.length > 0) {
                    st_id = data[0].id;
                }

                that.setState({
                    selectedStorageID: st_id,
                    loading: false,
                    storages: data,
                }, function () {
                    that.fetchData();
                });
            });
        }

    }

    async fetchData() {
        this.setState({ loading: true });

        var that = this;

        var params = { "storage_id": this.state.selectedStorageID };

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'storage/shelfs',
                method: 'get',
                type: 'json',
                data: params,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {

                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                that.setState({
                    loading: false,
                    shelfs: data.results,
                });
            });
        }
    }

    redirectToLogin() {

        this.props.history.push("/authentication/login");
    }

    handleShelfDelete = (key, e) => {
        e.preventDefault();
        this.setState({ selectedShelfID: key }, function () {
            this.deleteShelf();
        });
    };

    handleEditShelf = (record, e) => {
        e.preventDefault();
        this.setState({ addShelfHeader: "Изменение полки", addShelfShow: true, selectedShelfID: record.nShelfID, shelfWidth: record.nWidth, shelfHeight: record.nHeight, shelfLength: record.nLength, shelfName: record.szShelf, rentPrice: record.nRentPricePerMonth }, function () {

        });
    };


    handleAddShelf(e) {
        e.preventDefault();
        this.setState({ addShelfHeader: "Новая полка", addShelfShow: true, selectedShelfID: 0 });
    }

    setAddShelfShowToggle() {
        this.setState({ addShelfShow: !this.state.addShelfShow });
    }

    async saveShelf() {
        const token = await authService.getAccessToken();
        var that = this;
        if (token.length > 0) {

            this.setState({ loading: true, addShelfShow: false });

            var shelf_data = {
                nShelfID: this.state.selectedShelfID,
                szShelf: this.state.shelfName,
                nWidth: this.state.shelfWidth,
                nHeight: this.state.shelfHeight,
                nLength: this.state.shelfLength,
                nRentPricePerMonth: this.state.rentPrice,
                nStorageID: 0
            };

            await fetch('storage/save_shelf',
                {
                    method: "POST",
                    body: JSON.stringify(shelf_data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    error: function (err) {

                        if (err.status == 401) {
                            that.redirectToLogin();
                        }
                        else {
                        }
                    }
                }).
                then(response => response.text())
                .then(data => {

                    try {
                        var shelf = JSON.parse(data);

                        if (shelf.status != null) {
                            if (shelf.status == 400) {
                                that.setState({ showAlert: true, alertText: "Произошла ошибка: " + shelf.title, loading: false });
                            }
                        }
                        else {
                            that.setState({ loading: false, addShelfShow: false }, function () {

                                if (shelf == null || shelf.nShelfID <= 0) {
                                    that.setState({ showAlert: true, alertText: "Произошла неизвестная ошибка", loading: false });
                                }
                                else {
                                    this.fetchData();
                                }
                            });
                        }
                    }
                    catch (exception) {

                        if (data.length == 0) {

                            that.setState({ showAlert: true, alertText: "Произошла ошибка: " + exception, loading: false });
                        }
                        else {
                            that.setState({ showAlert: true, alertText: "Произошла ошибка: " + data, loading: false });
                        }
                    }
                });
        }
    }

    async deleteShelf() {

        this.setState({ loading: true });

        const token = await authService.getAccessToken();

        if (token.length > 0) {
            var id = this.state.selectedShelfID;
            await fetch('storage/delete_shelf',
                {
                    method: "DELETE",
                    body: JSON.stringify(id),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }).
                then(response => response.text())
                .then(data => {
                    this.setState({ loading: false }, function () {
                        this.fetchData();
                    });
                });
        }
    }

    handleFieldChange(event) {

        if (event.target.name == "shelfName") {
            this.setState({ shelfName: event.target.value });
        }

        if (event.target.name == "shelfWidth") {
            this.setState({ shelfWidth: event.target.value });
        }

        if (event.target.name == "shelfHeight") {
            this.setState({ shelfHeight: event.target.value });
        }

        if (event.target.name == "shelfLength") {
            this.setState({ shelfLength: event.target.value });
        }

        if (event.target.name == "rentPrice") {
            this.setState({ rentPrice: event.target.value });
        }
    }

    handleAlertClose() {
        this.setState({ showAlert: false });
    }

    handleStorageChange(e) {

        this.setState({ selectedStorageID: e }, function () {
            this.fetchData();
        });
    }

    render() {

        this.columns = [
            {
                title: 'Полка',
                dataIndex: 'szShelf',
                sorter: false,
            },
            {
                title: 'Ш',
                dataIndex: 'nWidth',
                fixed: 'right',
                editable: true,
                align: 'right'
            },
            {
                title: 'В',
                dataIndex: 'nHeight',
                fixed: 'right',
                editable: true,
                align: 'right'
            },
            {
                title: 'Г',
                dataIndex: 'nLength',
                fixed: 'right',
                editable: true,
                align: 'right'
            },
            {
                title: 'Объем (М^3)',
                dataIndex: 'nRealVolume',
                fixed: 'right',
            },
            {
                title: 'Цена',
                dataIndex: 'nRentPricePerMonth',
                fixed: 'right',
                editable: true,
                align: 'right'
            },
            {
                title: '',
                dataIndex: '',
                key: 'x',
                render: (text, record) => (<Popconfirm title="Удалить полку?" okText="Да" cancelText="Нет" onConfirm={(e) => this.handleShelfDelete(record.nShelfID, e)}><DeleteOutlined title="Удалить полку" style={{ color: "red" }} /></Popconfirm>),
                width: '10px',
                fixed: 'right',
            },
            {
                title: '',
                dataIndex: '',
                key: 'e',
                render: (_, record) => (<Button type="text"><EditOutlined title="Изменить" style={{ color: "green" }} onClick = {(e) => this.handleEditShelf(record, e)} /></Button>)
            },
        ];

        const cols = this.columns;

        return (
            <div>
                <h2>{this.displayName}</h2>
                <Button type="primary" title="Добавить полку" className="float-right" onClick={this.handleAddShelf} >Добавить полку</Button>

                <Select id="storages" name="storages" value={this.state.selectedStorageID} style={{ minWidth: "200px" }} onChange={e => { this.handleStorageChange(e) }}>
                    {
                        this.state.storages.map((e, key) => {
                            return <Option value={e.id}>{e.szName}</Option>;
                        })
                    }
                </Select>

                    <Table
                    style={{ marginTop: 10 }}
                    locale={{ emptyText: 'Нет данных' }}
                    columns={cols}
                    loading={this.state.loading}
                    dataSource={this.state.shelfs}
                    rowKey={record => record.nShelfID}
                />

                <Modal
                    title={this.state.addShelfHeader}
                    visible={this.state.addShelfShow}
                    onCancel={this.setAddShelfShowToggle}
                    footer={[
                        <Button key="back" onClick={this.setAddShelfShowToggle}>
                            Закрыть
                        </Button>,
                        <Button key="ok" onClick={this.saveShelf} type="primary">
                            Сохранить
                        </Button>
                    ]}
                >
                    <Form layout="vertical">
                        <Form.Item label="Название:">
                            <Input type="text" name="shelfName" id="shelfName" value={this.state.shelfName} onChange={(e) => { this.handleFieldChange(e) }}></Input>
                        </Form.Item>
                        <Form.Item label="Размеры (см):">
                            <Input type="text" name="shelfWidth" id="shelfWidth" value={this.state.shelfWidth} style={{ width: "50px" }} onChange={(e) => { this.handleFieldChange(e) }} ></Input>
                            &nbsp;X&nbsp;<Input name="shelfHeight" id="shelfHeight" type="text" value={this.state.shelfHeight} style={{ width: "50px" }} onChange={(e) => { this.handleFieldChange(e) }}></Input>
                            &nbsp;X&nbsp;<Input name="shelfLength" id="shelfLength" type="text" value={this.state.shelfLength} style={{ width: "50px" }} onChange={(e) => { this.handleFieldChange(e) }}></Input>
                        </Form.Item>
                        <Form.Item label="Стоимость аренды в месяц:">
                            <Input type="text" name="rentPrice" id="rentPrice" value={this.state.rentPrice} style={{ width: "70px" }} onChange={(e) => { this.handleFieldChange(e) }}></Input>
                        </Form.Item>
                    </Form>
                </Modal>

                <Bootbox show={this.state.showAlert}
                    successLabel={"OK"}
                    type={"alert"}
                    message={this.state.alertText}
                    onClose={this.handleAlertClose}
                />

            </div>
            )
    }
}

export default ManageShelfs;
