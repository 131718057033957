import React, { Component } from 'react';
import { Drawer, BackTop, Table, Row, Col, Button, Modal, Form, Input, Select, Carousel, Spin, Space, Upload, message, Alert} from 'antd'
import Bootbox from 'bootbox-react';
import authService from './api-authorization/AuthorizeService'

import { DownloadOutlined, UploadOutlined, ReloadOutlined, LeftOutlined, PhoneOutlined, RightOutlined, DoubleRightOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { Label } from 'reactstrap';
import reqwest from 'reqwest'
import { Link } from 'react-router-dom';

const { TextArea } = Input;
const { Option } = Select;

export class DPDDocs extends Component {
    static displayName = DPDDocs.name;

    constructor(props) {
        super(props);

        this.columns = [
            {
                title: 'Файл',
                dataIndex: 'name',
                render: (text, record) => (<span style={{ color: text.indexOf("Недостаточно") >= 0 ? "red" : "black" }}>{text}</span>)
            },
            {
                title: '',
                render: (text, record) => (<Button title="Скачать" type="text" onClick={(e) => this.handleDownload(record, e)}><DownloadOutlined style={{ color: "green" }} /></Button>),
            },
        ];

        this.log_columns = [
            {
                title: 'Дата',
                dataIndex: 'date',
            },
            {
                title: 'Приход',
                dataIndex: 'income',
            },
            {
                title: 'Расход',
                dataIndex: 'outcome',
            },
            {
                title: 'Баланс',
                dataIndex: 'balance',
            },
            {
                title: 'Описание',
                dataIndex: 'notes',
            },
        ];

        this.accounts_columns = [
            {
                title: 'ИНН',
                dataIndex: 'inn',
                render: (text, record) => (<a target="_blank" href={`/dpd-docs/${record.inn}`} >{record.inn}</a>)
            },
            {
                title: '',
                dataIndex: 'tp',
                align: 'center',
            },
            {
                title: 'Имя',
                dataIndex: 'name',
            },
            {
                title: 'КПП',
                dataIndex: 'kpp',
            },
            {
                title: 'ОГРН',
                dataIndex: 'ogrn',
            },
            {
                title: 'Директор',
                dataIndex: 'director',
            },
            {
                title: 'Баланс',
                dataIndex: 'balance',
                align: 'right',
            },
        ];

        var id = this.props.match.params.id;
        var uid = "";

        if (this.props.location.search != null) {
            var uid1 = this.props.location.search.toString().toLowerCase();
            if (uid1.length > 0 && uid1.indexOf("u=") >= 0) {
                uid = uid1.replace("u=", "").replace("?", "");
            }
        }

        this.handleLog = this.handleLog.bind(this);
        this.handleCharge = this.handleCharge.bind(this);
        this.handleChargeHelp = this.handleChargeHelp.bind(this);
        this.handleBankCharge = this.handleBankCharge.bind(this);
        this.handleContacts = this.handleContacts.bind(this);
        this.handleSBOnlineCharge = this.handleSBOnlineCharge.bind(this);

        var bank = false;
        var upload = true;
        var charge = false;

        this.state = {
            uid: uid,
            bank: bank,
            upload: upload,
            charge: charge,
            sbonline: false,
            showlog: false,
            showaccounts: false,
            files: [],
            log: [],
            accounts: [],
            loading: false,
            inn: "",
            inn_id: id,
            ip: "",
            balance: "",
            credit_limit: false,
            inn_visible: false,
            showAlert: false,
            alertText: "",
            accountHtml: "",
            chargeAmount: 100,
            rub: 0,
            kop: 0,
            sbonlineid: 0,
            logtext: "Журнал списаний и пополнений"
        };
    }

    handleDownload(record, e) {

        const link = document.createElement('a');
        link.href = '/data/DPDDocs/' + record.id + "/" + record.name;
        link.setAttribute(
            'download',
            record.name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

        this.saveUid();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated] = await Promise.all([authService.isAuthenticated()])

        if (isAuthenticated && this.state.inn_id != undefined) {
            this.setState({
                isAuthenticated: isAuthenticated,
                upload: true,
                showlog: true,
            }, function () {

                this.getLog(this.state.inn_id);
            });

        }
        else {
            this.setState({
                isAuthenticated: isAuthenticated
            });
        }

        this.getAccounts();
    }

    async getFiles(g) {

        var that = this;

        var url = 'dpddocs/files';

        that.setState({ loading: true });

        reqwest({
            url: url,
            method: 'get',
            type: 'json',
            data: { g: g },
            headers: {
            },
            error: function (err) {
                that.setState({ loading: false });
            }
        }).then(data => {

            if (data != null) {
                that.setState({ loading: false, files: data.results, inn_visible: true, inn: data.inn, ip: data.ip, balance: data.balance, credit_limit: data.credit_limit });
            }
            else {
                that.setState({ loading: false, files: [], inn: "", ip: "", balance: "" });
            }
        });

    }

    handleChargeHelp() {

        var txt = "Данный сервис предназначен для упрощения подготовки документов для бухгалтерии DPD. Предварительно необходимо на странице <a href='https://pn-invoice.dpd.ru'>https://pn-invoice.dpd.ru</a> получить отчет в формате pdf. Далее загрузить отчет здесь <a href='https://www.stellazhi.net/dpd-docs' >https://www.stellazhi.net/dpd-docs</a>. После обработки файла вы получите 3 или 4 файла:<br/>" +
            "1.	Счет № хх_хххххххх от хх.хх.ххх.pdf<br/>" +
            "2.	Отчет к счету № хх_хххххххх от хх.хх.ххх.pdf<br/>" +
            "3.	Акт № хх_хххххххх от хх.хх.ххх.xml<br/>" +
            "4.	Счет - фактура № хх_хххххххх от хх.хх.ххх.xml (будет отсутствовать, если вы не плательщик НДС)<br/>" +
            "Загрузить файлы к себе на компьютер. Сохранить в удобном месте. Загрузить их в Diadoc.<br/>" +
            "   Примечание: При загрузке файла Акт.xml тип файла автоматически выставляется «УПД» необходимо изменить на «Акт».<br/><br/>" +

            "Стоимость сервиса 20р за один загруженный и обработанный отчет.<br/>" +
            "Повторно загруженный отчет будет обработан без оплаты.<br/>" +
            "Перед началом работы предоставляется кредит в размере 100р. При достижении баланса минус 100р сервис будет недоступен. Необходимо пополнить баланс.<br/>" +
            "Для пополнения баланса необходимо авторизоваться. Для авторизации необходимо загрузить любой (желательно ранее уже обработанный) отчет.<br/><br/>" + 
            "Сервис предоставляется как есть. Ответственность за корректность данных в файлах лежит на пользователе. Проверяйте файлы перед отправкой контрагенту.";

        this.setState({ showAlert: true, alertText: txt });
    }

    handleChargeAmountChange(e) {

        this.setState({ chargeAmount: e.target.value });
    }

    handleLog() {
        if (this.state.showlog == false) {
            this.setState({ charge: false, bank: false, upload: true, sbonline: false, showlog: true, logtext: "К загрузке" });
            this.getLog(this.state.inn);
        }
        else {
            this.setState({ charge: false, bank: false, upload: true, sbonline: false, showlog: false, logtext: "Журнал списаний и пополнений" });
        }
    }

    handleCharge() {

        this.setState({ charge: true, bank: false, upload: false, sbonline: false });
    }

    handleContacts() {
        this.props.history.push("/contacts");
    }

    handleBankCharge() {

        this.generateBankAccount();
    }

    handleSBOnlineCharge() {

        this.generateSBOnlineAccount();
    }

    handleAlertClose = () => {

        this.setState({ showAlert: false, alertText: "" }, function () {
        });
    };

    getLog(inn) {
        var that = this;

        this.setState({ loading: true });

        reqwest({
            url: 'dpddocs/log',
            method: 'get',
            type: 'json',
            data: { inn: inn },
            headers: {
            },
            error: function (err) {
                that.setState({ loading: false });
            }
        }).then(data => {

            if (data != null) {
                that.setState({ loading: false, log: data.results, balance: data.balance, credit_limit: data.credit_limit });
            }
            else {
                that.setState({ loading: false, log: [] });
            }
        });

    }

    getBankAccount(id) {
        this.setState({ loading: true });

        fetch('dpddocs/bankaccountblank/?id=' + id + '&mode=1',
            {
                method: "GET",
            }).
            then(response => response.text())
            .then(data => {
                var html = data;
                this.setState({ loading: false, accountHtml: html, bank: true, charge: false, upload: false, sbonline: false });
            });
    }

    getSBOnlineAccount(id) {
        this.setState({ loading: true });

        fetch('dpddocs/sbonlineaccountblank/?id=' + id + '&mode=1',
            {
                method: "GET",
            }).
            then(response => response.text())
            .then(data => {
                var sb = JSON.parse(data);
                var ks = "";
                var k = parseFloat(sb.kop);
                if (k < 10) {
                    ks = "0" + k;
                }
                else {
                    ks = k;
                }
                this.setState({ sbonlineid: id, loading: false, rub: sb.rub, kop: ks, sbonline: true, bank: false, charge: false, upload: false });
            });
    }

    async saveUid() {
        var uid = this.state.uid;
        var that = this;
        if (uid.length > 0) {
            var req = { "uid": uid };

            fetch('dpddocs/uid',
                {
                    method: "POST",
                    body: JSON.stringify(req),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).
                then(response => response.text())
                .then(data => {
                });
        }
    }

    async generateSBOnlineAccount() {
        var inn = this.state.inn;
        var that = this;

        var amt = parseFloat(this.state.chargeAmount);
        if (!isNaN(amt)) {
            this.setState({ loading: true });

            var req = { "inn": inn, "amount": amt, mode: 1 };

            fetch('dpddocs/sbonlineaccount',
                {
                    method: "POST",
                    body: JSON.stringify(req),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).
                then(response => response.text())
                .then(data => {
                    this.setState({ loading: false });

                    var d = JSON.parse(data);

                    if (d.success == true) {
                        this.getSBOnlineAccount(d.id);
                    }
                    else {
                        message.success(`Ошибка создания счета: ${d.error}`);
                    }
                });
        }
    }

    async getAccounts() {
        var that = this;

        try {
            const token = await authService.getAccessToken();
            if (token.length > 0) {
                reqwest({
                    url: 'dpddocs/accounts',
                    method: 'get',
                    type: 'json',
                    data: {},
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    error: function (err) {
                    }
                }).then(data => {
                    that.setState({
                        accounts: data.results,
                        showaccounts: true
                    }, function () {
                    });
                });
            }
        }
        catch (exception) {

        }
    }


    async generateBankAccount() {
        var inn = this.state.inn;
        var that = this;

        var amt = parseFloat(this.state.chargeAmount);

        if (!isNaN(amt)) {
            this.setState({ loading: true });

            var req = { "inn": inn, "amount": amt, mode: 1 };

            fetch('dpddocs/bankaccount',
                {
                    method: "POST",
                    body: JSON.stringify(req),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).
                then(response => response.text())
                .then(data => {
                    this.setState({ loading: false });

                    var d = JSON.parse(data);

                    if (d.success == true) {
                        this.getBankAccount(d.id);
                    }
                    else {
                        message.success(`Ошибка создания счета: ${d.error}`);
                    }
                });
        }
    }

    render() {
        var that = this;
        const loading = this.state.loading;
        const files = this.state.files;
        const cols = this.columns;
        const log = this.state.log;
        const log_cols = this.log_columns;
        const accounts_cols = this.accounts_columns;
        const accounts = this.state.accounts;

        const props = {
            name: 'file',
            action: "dpddocs/upload",
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    //console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    if (info.file.response == undefined) {
                        info.file.response = "";
                    }
                    if (info.file.response.indexOf("OK:") == 0) {
                        var g = info.file.response.replace("OK:", "");
                        that.setState({ loading: false, files: [] });
                        that.getFiles(g);
                        message.success(`${info.file.name} Файлы подготовлены`);
                    }
                    else {
                        message.error(`${info.file.name} ошибка загрузки файла: ${info.file.response}`);

                        that.setState({ loading: false, files: [], showlog: false, log: [], logtext:"Журнал списаний и пополнений" });
                    }

                    //message.success(`${JSON.stringify(info)} файл успешно загружен`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} ошибка загрузки файла: ${info.file.response}`);
                }
            }
        };

        return (
            <div style={{ paddingTop: "10px" }}>
                <Spin spinning={this.state.loading}>
                    <div style={{ display: this.state.upload == true ? "block" : "none" }}>
                    <div>
                            &nbsp;&nbsp;<Button onClick={() => this.handleChargeHelp()} >Как это работает?</Button>
                            &nbsp;&nbsp;<Button onClick={() => this.handleContacts()} >Есть вопросы?</Button>
                            &nbsp;&nbsp;<span style={{ color: "red", display: (this.state.credit_limit == true && this.state.balance.length > 0 && this.state.balance.indexOf("-") >= 0) ? "inline" : "none" }}>ВНИМАНИЕ! Если оплата была произведена, а баланс не изменился - обратитесь по контактам по кнопке "Есть вопросы?".</span>
                    </div>
                    <Upload {...props}>
                        <Button style={{ marginTop: "10px" }} icon={<UploadOutlined />}>Выберите файл pdf</Button>
                    </Upload>
                    <div style={{ paddingTop: "10px", display: this.state.inn_visible ? "inline" : "none" }}>
                            <b>ИНН:</b> {this.state.inn}, <b>Юр лицо:</b> {this.state.ip}, <b>Баланс:</b> {this.state.balance}
                            &nbsp;&nbsp;<Button onClick={() => this.handleCharge()} >Пополнить</Button>&nbsp;&nbsp;<Button onClick={() => this.handleLog()} >{this.state.logtext}</Button>
                            &nbsp;&nbsp;<Button style={{ display: this.state.showlog == true ? "inline" : "none" }} onClick={() => this.getLog(this.state.inn_id)}><ReloadOutlined /></Button>
                        </div>
                        <div style={{ marginTop: "10px", display: this.state.showlog == true ? "none" : "block" }}>
                            <Table
                            scroll={{ x: 'max-content' }}
                            size={'small'}
                            locale={{ emptyText: '' }}
                            columns={cols}
                            loading={loading}
                            dataSource={files}
                            rowKey={record => record.name}
                            pagination={{ pageSize: 1000, disabled: true, style: { display: "none" } }}
                        />
                        </div>
                        <div style={{ marginTop: "10px", display: this.state.showlog == false ? "none" : "block" }}>
                            <Table
                                scroll={{ x: 'max-content' }}
                                size={'small'}
                                locale={{ emptyText: '' }}
                                columns={log_cols}
                                loading={loading}
                                dataSource={log}
                                rowKey={record => record.id}
                                pagination={{ pageSize: 1000, disabled: true, style: { display: "none" } }}
                            />
                        </div>
                        <div style={{ marginTop: "10px", display: this.state.showaccounts == false ? "none" : "block" }}>
                            <Table
                                scroll={{ x: 'max-content' }}
                                size={'small'}
                                locale={{ emptyText: '' }}
                                columns={accounts_cols}
                                loading={loading}
                                dataSource={accounts}
                                rowKey={record => record.id}
                                pagination={{ pageSize: 1000, disabled: true, style: { display: "none" } }}
                            />
                        </div>
                    </div>

                    <div style={{ display: this.state.bank == true ? "block" : "none" }} >
                        <div dangerouslySetInnerHTML={{ __html: this.state.accountHtml }}>
                        </div>
                        <br />
                        <Button onClick={() => { this.setState({ bank: false, upload: true, charge: false, sbonline: false }) }} >Вернуться</Button>
                    </div>

                    <div style={{ display: this.state.sbonline == true ? "block" : "none" }} >
                        <div>
                            <h4>Заявка принята</h4>
                            <div style={{ float: "right", border: "1px dashed #cccccc", padding:"10px" }}>
                                Внимание! При переводе суммы отличной от указанной в счете с точностью до копеек,<br/>
                                поступление средств на Л/С не гарантируется.<br />
                                Перевод должен быть осуществлен не позднее трех суток от момента подачи заявки.<br /><br />
                                <a target='_blank' href='https://www.stellazhi.net/contacts'>По вопросам зачисления</a>
                            </div>
                            Счет № {this.state.sbonlineid}<br />
                            Сумма пополнения {this.state.rub} руб {this.state.kop} коп
                            <br /><br />
                            Для автоматического пополнения счета переведите<br/><b>{this.state.rub} руб.</b>&nbsp;<b style={{ color: 'red' }}>{this.state.kop} коп.</b> с вашей карты по указанным ниже реквизитам.
                            <br /><br />
                            Получатель: Сергей Борисович
                            № карты
                            2202 2001 1653 5327
                            <br /><br />
                            <Input type="text" value="2202200116535327" style={{width: "180px"} } />&nbsp;Копировать здесь
                            <br /><br />
                            Ожидайте поступления средств на свой Л/С в течение 8-ми рабочих часов.
                        </div>
                        <br />
                        <Button onClick={() => { this.setState({ bank: false, upload: true, charge: false, sbonline: false }) }} >Вернуться</Button>
                    </div>

                    <div style={{ display: this.state.charge == true ? "block" : "none" }}>
                        &nbsp;Сумма пополнения:&nbsp;<Input onChange={(e) => this.handleChargeAmountChange(e)} value={this.state.chargeAmount} style={{ width: "100px" }} />&nbsp;р.
                        <br/>
                        <br />
                        <Button onClick={() => this.handleBankCharge()}>Пополнить через банк</Button>
                        <br />
                        <br />
                        <Button onClick={() => this.handleSBOnlineCharge()}>Пополнить через Сбербанк Онл@йн</Button>
                        <br />
                        <br />
                        <Button onClick={() => { this.setState({ bank: false, upload: true, charge: false, sbonline: false }) }} >Вернуться</Button>
                    </div>

                    <Bootbox show={this.state.showAlert}
                        successLabel={"OK"}
                        type={"alert"}
                        message={this.state.alertText}
                        onClose={this.handleAlertClose}
                    />
                </Spin>
            </div>
        );
    }
}

export default DPDDocs;
