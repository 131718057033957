import React, { Component, PropTypes } from 'react';
import authService from './api-authorization/AuthorizeService'
import { Table, Input, Button, Modal, Form, Select, Alert, Popconfirm, Badge, Checkbox } from 'antd'
import reqwest from 'reqwest'
import Bootbox from 'bootbox-react';
import { DeleteOutlined, RightCircleFilled } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { FileAddOutlined } from "@ant-design/icons";
import { TrademarkOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Redirect } from 'react-router-dom';
import { InputGroup, InputGroupAddon, Label } from 'reactstrap';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

export class ShelfsRent extends Component {
    displayName = "Аренда полок";

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            storages: [],
            selectedStorageID: null,
            selectedShelfID: -1,
            userBalance: null,
            myShelfs: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

        var username = "";
        if (isAuthenticated) {
            username = user.name;
        }

        this.setState({
            isAuthenticated: isAuthenticated,
            username: username
        });

        if (isAuthenticated) {

            this.getStorages();
            this.getBalance();
        }
        else {
            this.redirectToLogin();
        }
    }

    redirectToLogin() {

        this.props.history.push("/authentication/login");
    }

    handleStorageChange(e) {

        this.setState({ selectedStorageID: e }, function () {
            this.fetchData();
        });
    }

    async getBalance() {

        var that = this;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'storage/get_balance',
                method: 'get',
                type: 'json',
                data: {},
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {

                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                if (data != null) {
                    var bl = parseFloat(data);
                    if (!isNaN(bl)) {
                        that.setState({
                            userBalance: bl,
                        }, function () {
                        });
                    }
                }
            });
        }
    }

    async getStorages() {

        this.setState({ loading: true });

        var that = this;

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'storage/storages_for_rent',
                method: 'get',
                type: 'json',
                data: {},
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {

                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                var st_id = -1;
                if (data != null && data.length > 0) {
                    st_id = data[0].id;
                }

                that.setState({
                    selectedStorageID: st_id,
                    loading: false,
                    storages: data,
                }, function () {
                    that.fetchData();
                });
            });
        }

    }

    async fetchData() {
        this.setState({ loading: true });

        var that = this;

        var params = { "storage_id": this.state.selectedStorageID, "my_shelfs": this.state.myShelfs };

        const token = await authService.getAccessToken();
        if (token.length > 0) {
            reqwest({
                url: 'storage/shelfs_for_rent',
                method: 'get',
                type: 'json',
                data: params,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                error: function (err) {

                    if (err.status == 401) {
                        that.redirectToLogin();
                    }
                }
            }).then(data => {

                that.setState({
                    loading: false,
                    shelfs: data.results,
                });
            });
        }
    }

    handleCancelRentShelf(record, e) {
        e.preventDefault();

        this.setState({ selectedShelfID: record.nShelfID }, function () {
            this.cancelRentShelf();
        });
    }

    handleRentShelf(record, e) {
        e.preventDefault();

        this.setState({ selectedShelfID: record.nShelfID }, function () {
            this.rentShelf();
        });
    }

    async rentShelf() {
        this.setState({ loading: true });
        var that = this;
        const token = await authService.getAccessToken();

        if (token.length > 0) {
            var id = this.state.selectedShelfID;
            await fetch('storage/rent_shelf',
                {
                    method: "POST",
                    body: JSON.stringify(id),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    error(err) {
                        if (err.status == 401) {
                            that.redirectToLogin();
                        }
                        else {
                            alert(err);
                        }
                    }
                }).
                then(response => response.text())
                .then(data => {

                    if (data == id) {
                        this.setState({ loading: false }, function () {
                            this.getBalance();
                            this.fetchData();
                        });
                    }
                    else {
                        this.setState({ loading: false }, function () {
                        });
                        alert(data);
                    }
                });
        }
    }

    async cancelRentShelf() {
        this.setState({ loading: true });
        var that = this;
        const token = await authService.getAccessToken();

        if (token.length > 0) {
            var id = this.state.selectedShelfID;
            await fetch('storage/cancel_rent_shelf',
                {
                    method: "POST",
                    body: JSON.stringify(id),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    error(err) {
                        if (err.status == 401) {
                            that.redirectToLogin();
                        }
                        else {
                            alert(err);
                        }
                    }
                }).
                then(response => response.text())
                .then(data => {

                    if (data == id) {
                        this.setState({ loading: false }, function () {
                            this.getBalance();
                            this.fetchData();
                        });
                    }
                    else {
                        this.setState({ loading: false }, function () {
                        });
                        alert(data);
                    }
                });
        }
    }


    render() {

        this.columns = [
            {
                title: 'Полка',
                dataIndex: 'szShelf',
                sorter: false,
            },
            {
                title: 'Ш',
                dataIndex: 'nWidth',
                fixed: 'right',
                align: 'right',
                sorter: {
                    compare: (a, b) => a.nWidth - b.nWidth,
                    multiple: 1
                }
            },
            {
                title: 'В',
                dataIndex: 'nHeight',
                fixed: 'right',
                align: 'right',
                sorter: {
                    compare: (a, b) => a.nHeight - b.nHeight,
                    multiple: 2
                }
            },
            {
                title: 'Г',
                dataIndex: 'nLength',
                fixed: 'right',
                align: 'right',
                sorter: {
                    compare: (a, b) => a.nLength - b.nLength,
                    multiple: 3
                }
            },
            {
                title: 'Объем (М^3)',
                dataIndex: 'nRealVolume',
                fixed: 'right',
                align: 'right',
                sorter: {
                    compare: (a, b) => a.nRealVolume - b.nRealVolume,
                    multiple: 4
                }
            },
            {
                title: 'Цена',
                dataIndex: 'nRentPricePerMonth',
                fixed: 'right',
                align: 'right'
            },
            {
                title: 'Занято до',
                dataIndex: 'dtRentTill',
                fixed: 'right',
                render: (text, record) => record.dtRentTill == null ? ("") : (<span>{record.dtRentTill.toString().substring(0, 10)}</span>)
            },
            {
                title: () => (<span><Input value={this.state.myShelfs} onChange={(e) => { this.setState({ myShelfs: e.target.checked }, function () { this.fetchData() }) }} type="checkbox" />Только мои полки</span>),
                dataIndex: '',
                key: 'e',
                render: (text, record) => record.bRent == true ? (<span>{record.rentBy}</span>) : (<Popconfirm title={<span>Аренда полки на 30 суток.<br/>Стоимость аренды будет списана с лицевого счета.<br/>Арендовать полку?</span>} okText="Да" cancelText="Нет" onConfirm={(e) => this.handleRentShelf(record, e)}><TrademarkOutlined title="Арендовать полку" style={{ color: "green" }} /></Popconfirm >),
            },
            {
                title: '',
                dataIndex: '',
                key: 'c',
                render: (text, record) => (record.shopName == this.state.username && record.itemsCount == 0) ? (<Popconfirm title="Отменить аренду?" okText="Да" cancelText="Нет" onConfirm={(e) => this.handleCancelRentShelf(record, e)}><CloseCircleOutlined title="Отмена аренды полки" style={{ color: "red" }} /></Popconfirm >) : (<span>{record.shopName == this.state.username ?  record.itemsCount : ""}</span>)
            }
        ];

        const cols = this.columns;

        return (
            <div>
            <h2>{this.displayName}</h2>

                <Select id="storages" name="storages" value={this.state.selectedStorageID} style={{ minWidth: "200px" }} onChange={e => { this.handleStorageChange(e) }}>
                    {
                        this.state.storages.map((e, key) => {
                            return <Option value={e.id}>{e.szName}</Option>;
                        })
                    }
                </Select>

                &nbsp;<span>Баланс Л/C:&nbsp;{this.state.userBalance}</span>

                <Table
                    style={{ marginTop: 10 }}
                    locale={{ emptyText: 'Нет данных' }}
                    columns={cols}
                    loading={this.state.loading}
                    dataSource={this.state.shelfs}
                    rowKey={record => record.nShelfID}
                />

            </div>
        )
    }
}

export default ShelfsRent;
