import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { HeadlessLayout } from './components/HeadlessLayout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { BrowserRouter, Switch } from "react-router-dom";

import './custom.css'
import ManageClients from './components/ManageClients';
import ManageOrders from './components/ManageOrders';
import ParcelDetails from './components/ParcelDetails';
import Accounting from './components/Accounting';
import ManageShelfs from './components/ManageShelfs';
import ManageStorage from './components/ManageStorage';
import ShelfsRent from './components/ShelfsRent';
import Cabinet from './components/Cabinet';
import ManageStickers from './components/ManageStickers';
import ManageIntercity from './components/ManageIntercity';
import PVZPicker from './components/PVZPicker';
import AddOrder from './components/AddOrder';
import PVZBonus from './components/PVZBonus';
import DPDDocs from './components/DPDDocs';
import CategoriesEditor from './components/CategoriesEditor';
import WAShell from './components/WAShell';

export default class App extends Component {
    static displayName = "Стеллажи";//App.name;

    render() {
        return (
            <div>
                <Switch>
                    <Route path='/headless'>
                        <HeadlessLayout>
                            <Route path='/headless/pvz-picker' component={PVZPicker} />
                            <Route path='/headless/wa-shell/:id?' component={WAShell} />
                        </HeadlessLayout>
                    </Route>
                    <Route path='/hl'>
                        <HeadlessLayout>
                            <Route path='/hl/Alizon' component={PVZBonus} />
                        </HeadlessLayout>
                    </Route>
                    <Route path='/'>
                        <Layout>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/contacts' component={Home} />
                            <Route path='/dpd-docs/:id?' component={DPDDocs} />
                            <AuthorizeRoute path='/fetch-data' component={FetchData} />
                            <AuthorizeRoute path='/add-clients' component={ManageClients} />
                            <AuthorizeRoute path='/manage-orders/parcels' component={ManageOrders} />
                            <AuthorizeRoute path='/manage-orders/sale' component={ManageOrders} />
                            <AuthorizeRoute path='/manage-orders/storage' component={ManageOrders} />
                            <AuthorizeRoute path='/shelfs' component={ManageShelfs} />
                            <AuthorizeRoute path='/shelfs-rent' component={ShelfsRent} />
                            <AuthorizeRoute path='/manage-accounting' component={Accounting} />
                            <AuthorizeRoute path='/cabinet' component={Cabinet} />
                            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                            <AuthorizeRoute path='/manage-orders/parceldetails/:id' component={ParcelDetails} />
                            <AuthorizeRoute path='/manage-stickers' component={ManageStickers} />
                            <AuthorizeRoute path='/manage-intercity' component={ManageIntercity} />
                            <AuthorizeRoute path='/add-order/:id' component={AddOrder} />
                            <AuthorizeRoute path='/manage-categories' component={CategoriesEditor} />
                        </Layout>
                    </Route>
                </Switch>
            </div>
        );
    }
}
